const products = {
  list: {
    searchPlaceholder: "Produkte finden...",
    allProductText: "Alle Produktfamilien ({{ items }} Produkte)",
    selectProductFamily: "Produktfamilie auswählen",
    allButton: "Alles auswählen",
    productGroup: "Produktgruppe",
  },
  details: {
    printButton: "Drucken",
    productAvailabilityStatus: "Verfügbarkeit {{ status }}",
    productGroup: "Produktgruppe",
    vk1: "VK 1",
    vk2: "VK 2",
    priceNowSection: {
      title: "Abgabepreis",
      vk1: "VK1",
      vk2: "VK2",
      warningMessage: "Achtung abweichender Preis",
      errorMessage: "Preisangabe nicht möglich",
    },
    linePriceInfo: {
      drawerTitle: "Preisinformation",
      supplierIln: "Lieferant ILN",
      priceNow: "Abgabepreis",
      vk2: "VK2",
      vk1: "VK1",
      warningMessage: "Warnhinweis",
      errorMessage: "Fehlerhinweis",
    },
    overwrittenPrice: "Ersetzter Preis",
    priceInOtherChannel: "Preis in anderen Kanälen",
    generalTab: "Allgemeine Informationen",
    itemsTab: "Artikel",
    attributesTab: "Attribute",
    mediaTab: "Medien",
    inventoryTab: "Lagerbestand",
    availabilityStatusTab: "Verfügbarkeit",
    printHistoryTab: "Abgeschlossene Drucke",
    salesPersonCommissionTab: "Sales person commission DE",
    // General tab
    aboutProductTitle: "Über das Produkt",
    aboutProductText: "Einige Beschreibungen über dieses Produkt",
    moreInfo: "Mehr Infos",
    articleNumber: "Artikelnummer",
    supplier: "Lieferant",
    supplierArticleNumber: "Lieferanten-Artikelnummer",
    productFamily: "Produktfamilie",
    program: "Programm",
    // Items tab
    itemsTitle: "Artikel",
    itemsLength: "{{ length }} Artikel",
    headerProducts: "Kopfartikel",
    headerQuantity: "Anzahl",
    headerItemPrice: "Item Preis",
    headerPrice: "Preis",
    headerAction: "Aktion",
    lineSupplier: "Lieferant",
    lineProgram: "Programm",
    lineProductFamily: "Warengruppe",
    lineVk2: "VK2",
    lineVk1: "VK1",
    lineOverwritten: "Überschriebener Preis",
    lineDetailsButton: "Details",
    // Media tab
    viewMediaBtn: "Ansehen",
    // Inventory tab
    inventoryNotAvailable: "Nicht verfügbar",
    // Availability status Tab
    availabilityStatus: {
      // List
      title: "Verfügbarkeit",
      isDefault: "(Default)",
      statusDate: "Von{{ fromDate }} bis {{ toDate }}",
      addButton: "Hinzufügen",
      // Form
      addTitle: "Verfügbarkeit hinzufügen",
      editTitle: "Verfügbarkeit anpassen",
      status: "Status",
      validationPeriod: "Gültigkeit",
      fromDate: "Von (Datum)",
      toDate: "Bis (Datum)",
      conflictErrorTitle: "Daten nicht valide",
      conflictErrorText: "Datum erzeugt Konflikt",
      cancelButton: "Abbrechen",
      addStatusButton: "Hinzufügen",
      updateButton: "Update",
      addMessage: "Status erfolgreich geändert",
      updateMessage: "Status erfolgreich geändert",
      // Remove Confirm
      removeStatusTitle: "Status aufheben",
      removeStatusText:
        "Diese Aktion kann nicht rückgängig gemacht werden! Trotzdem löschen? ",
      cancelRemoveButton: "Löschen",
      removeStatusButton: "Status aufheben",
      removeSuccessMessage: "Der Status wurde erfolgreich aufgehoben",
    },
    pricePerChannel: {
      title: "Preis in anderem Kanal",
      myChannel: "Mein Kanal",
      otherChannels: "weitere Kanäle",
      ilnNumber: "ILN #{{ number }}",
      priceInChannel: "Preis in gewähltem Kanal",
    },
    printHistory: {
      items: "Artikel",
      status: "Status",
      price: "Preis",
      action: "Aktion",
      downloadButton: "Download",
      cancelButton: "Prozess abbrechen",
      detailsButton: "Details",
    },
    printHistoryDetails: {
      drawerTitle: "Druckanforderung Details",
      info: "Info Informationen",
      title: "Titel",
      requestDate: "Anfrage Datum",
      vk2: "VK2",
      vk1: "VK1",
      language: "Sprache",
      status: "Status",
      errorNote: "Fehlernote",
      closeButton: "schliessen",
    },
    removeConfirm: {
      title: "Entferne {{ title }}",
      text: "Sollen diese Items wirklich entfrent werden?",
      cancelButton: "Abbrechen",
      removeButton: "Item entfernen",
      removeMessage: "Druckhistorie erfolgreich entfernt",
    },
  },
  editPrice: {
    title: "Preis bearbeiten",
    articleNumber: "Artikelnummer",
    supplierName: "Lieferant",
    programName: "Programm",
    vk1: "VK1",
    cancelButton: "Löschen",
    submitButton: "Bestätigen",
    successMessage: "Der Preis wurde geändert",
  },
  printTemplate: {
    title: "Druckvorlage",
    cancelButton: "Abbrechen",
    submitButton: "Drucken",
    submitMessage: "Druckauftrag erfolgreich übermittelt",
  },
  salesPersonCommission: {
    title: "Sales Person Commission DE",
    amount: "Amount DE",
    type: "Type DE",
    cancelButton: "Cancel DE",
    submitButton: "Save changes DE",
    successMessage: "Product has been updated successfully. DE",
  },
  filter: {
    filterTitle: "Filter",
    resetFilterBtn: "Filter zurücksetzen",
    productFamily: "Produktfamilie",
    brand: "Marke",
    supplier: "Lieferant",
    program: "Programm",
    priceRange: "Preisspanne",
    priceFrom: "Von",
    priceTo: "Bis",
    cancelBtn: "Abbrechen",
    submit: "Filter anwenden",

    selectProductFamily: "Produktfamilie auswählen",
    allProductFamilies: "Alle Produkte",
    productCount: "{{ count }} Produkte",
    chooseProductFamily: "Auswählen",
  },
};
export default products;
