import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { ConfigProductContext } from ".";
import { OfferDetailsContext } from "../..";
import {
  Button,
  CheckBox,
  Drawer,
  Iframe,
  Loading,
  Text,
} from "../../../../../components";
import { useAxios, useToggle } from "../../../../../hooks";
import { OrderDetailsContext } from "../../../../Orders/Details";

export default function Iwofurn() {
  const { axios, loading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const isOffer = !!offer;
  const isOrder = !!order;

  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;

  const { supplier, program, toggle, onSubmitted, parentId } =
    useContext(ConfigProductContext);
  const [url, setUrl] = useState("");
  const [deleteAfterImport, toggleDeleteAfterImport] = useToggle(false);
  const importLoading = [
    !!url && loading.post,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  const iwofurnLoading = !url && loading.post;
  const internalTag = useMemo(() => {
    const time = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 10 ** 5);
    return [randomNumber, time].join("");
  }, []);
  const getUrl = () => {
    const url = "/salesservice/api/iwofurnconfigurations/iwofurn-url";
    const body = {
      supplierCode: supplier?.code,
      programCode: program?.code,
    };
    axios.post(url, body).then(({ data }) => {
      const url = new URL(data);
      url.searchParams.set("transaction", internalTag);
      setUrl(url.toString());
    });
  };
  const submit = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      "import-from-iwofurn",
    ]
      .filter(Boolean)
      .join("/");
    const body = {
      parentId: parentId,
      offerId: offer?.id,
      orderId: order?.id,
      shippingMethodCode: null,
      internalTag: internalTag,
      deleteAfterImport: deleteAfterImport,
    };
    axios.post(url, body).then(() => {
      (updateOffer ?? updateOrder)?.().then(() => {
        toast.success("toast.success.importIwofurnProduct");
        toggle();
        onSubmitted();
      });
    });
  };
  useEffect(getUrl, []);
  return (
    <Fragment>
      <Drawer.Header className="flex items-center gap-4 border-b-0">
        <CheckBox
          label="formControls.removeBasketAfterImport"
          value={deleteAfterImport}
          setValue={toggleDeleteAfterImport}
          className="w-auto flex-1"
        />
        <Button
          loading={importLoading}
          type="button"
          variant="success"
          onClick={submit}
        >
          <Text>button.importProducts</Text>
        </Button>
      </Drawer.Header>
      <Drawer.Body>
        {iwofurnLoading ? (
          <Loading.Inline />
        ) : (
          <Iframe
            title="iwofurn"
            src={url}
            className="block w-full h-full rounded shadow"
          />
        )}
      </Drawer.Body>
    </Fragment>
  );
}
