import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export const useUpdateEffect = (
  fn: EffectCallback,
  deps?: DependencyList | undefined
) => {
  const mounted = useRef(false);
  return useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    return fn && fn();
  }, deps);
};
