import currentLanguage from "./_currentLanguage";

export default function checkPriceAvailability(_price: string) {
  let price = parseInt(_price);
  if (price) {
    if (price > 0) {
      return CurrencyFormatter(price.toFixed(2));
    } else {
      return '--';
    }
  } else {
    return '--';
  }
};

export const getCultureName = () => {
  let culture = currentLanguage();;
  let result = culture + '-' + culture.toUpperCase();
  return result;
};

export const DecimalFormatter = (item: any) => {
  const culture = getCultureName();
  return new Intl.NumberFormat(culture, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(item);
};

export const CurrencyFormatter = (item: any) => {
  const culture = getCultureName();
  return new Intl.NumberFormat(culture, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(item);
};


