import { PulsFileType } from "../enums";
import { fileDataType } from "../types";
import useAxios from "./useAxios";

type FileType = Blob | string;
type UploadFileType = (file: FileType) => Promise<fileDataType>;

export default function useUploadFile(): [UploadFileType, boolean] {
  const { axios, loading } = useAxios();
  const uploadFile: UploadFileType = (file) => {
    return new Promise((resolve, reject) => {
      const isFile = typeof file === "object";
      if (!isFile) return resolve({ url: file } as fileDataType);
      const url = `/assetservice/api/assets/${PulsFileType.OfferDocs}`;
      const body = new FormData();
      const config = { timeout: 10 * 60 * 1000 };
      body.set("formFile", file);
      axios
        .post(url, body, config)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  return [uploadFile, loading.post];
}
