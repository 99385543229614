import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { ProductDetailsContext } from ".";
import {
  Button,
  Drawer,
  Form,
  Image,
  InputGroup,
  Text,
} from "../../../components";
import { useAxios } from "../../../hooks";
import { togglePropsType } from "../../../types";

type Props = togglePropsType;

export default function EditPriceMenu({ isOpen, toggle }: Props) {
  const { product, getProduct } = useContext(ProductDetailsContext);
  const { axios, loading } = useAxios();
  const hasLoading = loading.update;
  const [price, setPrice] = useState(String(product.vk1Price?.amount ?? ""));
  const submit = () => {
    if (!product.vk1Price) return;
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/overwrite-price`;
    const body = { vk1Price: cloneDeep(product.vk1Price) };
    body.vk1Price.amount = Number(price);
    axios.put(url, body).then(() => {
      toast.success("products.editPrice.successMessage");
      toggle();
      getProduct();
    });
  };

  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-dark text-base font-medium">
            <Text>products.editPrice.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header className="flex items-center gap-4 border-b border-dashed text-start pb-4">
          <Image
            src={product.avatar?.url}
            alt={product.originalName}
            className="w-32 h-20 object-cover rounded shadow"
            isDynamic
          />
          <div className="flex-1 space-y-1 [&>p]:text-xs [&>p]:text-secondary [&>p>span]:text-dark">
            <p>
              <Text>products.editPrice.articleNumber</Text>:{" "}
              <span>#{product.articleNumber}</span>
            </p>
            <p>
              <Text>products.editPrice.supplierName</Text>:{" "}
              <span>{product.supplier?.name ?? "--"}</span>
            </p>
            <p>
              <Text>products.editPrice.programName</Text>:{" "}
              <span>{product.supplier?.program?.name ?? "--"}</span>
            </p>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <InputGroup
            label="products.editPrice.vk1"
            type="price"
            value={price}
            setValue={setPrice}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="danger"
            onClick={toggle}
            disabled={hasLoading}
          >
            <Text>products.editPrice.cancelButton</Text>
          </Button>
          <Button type="submit" variant="primary" loading={hasLoading}>
            <Text>products.editPrice.submitButton</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
