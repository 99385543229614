import { cloneDeep } from "lodash";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import {
  Button,
  CheckBox,
  Form,
  Icon,
  Modal,
  Select,
  Text,
} from "../components";
import { now, rules } from "../constants";
import { useAxios, useToggle } from "../hooks";
import {
  calendarWeekToTime,
  getDaysOfYear,
  getMothFromWeek,
  getWeekNumber,
} from "../methods";
import { OfferDetailsContext } from "../pages/Offers/Details";
import { LineItemsContext } from "../pages/Offers/Details/LineItems";
import { LogisticContext } from "../pages/Offers/Details/Logistic";
import { OrderDetailsContext } from "../pages/Orders/Details";
import { LineItem, togglePropsType } from "../types";

type InitData = {
  lineItem?: LineItem.Item | null;
};

type SetDesireDeliveryDateProps = togglePropsType & {
  lineItem?: LineItem.Item | null;
  onSubmitted?: (data: InitData) => void;
  onApplyForLineItems?: () => void;
};

export default function SetDesireDeliveryDate({
  isOpen,
  toggle,
  lineItem,
  onSubmitted,
  onApplyForLineItems,
}: SetDesireDeliveryDateProps) {
  const { axios, loading } = useAxios();
  const logisticContext = useContext(LogisticContext);
  const lineItemsContext = useContext(LineItemsContext);
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const lineItems = logisticContext.lineItems;
  const setLineItems: Dispatch<SetStateAction<LineItem.Item[]>> = (e) => {
    logisticContext.setLineItems(e);
    lineItemsContext.setLineItems(e);
  };

  const isLineItem = !!lineItem;
  const isOffer = !!offer && !isLineItem;
  // const isOrder = !!order && !isLineItem;
  const isOfferLineItem = !!offer && isLineItem;
  const isOrderLineItem = !!order && isLineItem;

  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateHeader = updateOffer || updateOrder;

  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;

  const getLineItemsLoading = logisticContext.lineItemsLoading;

  const hasLoading = [
    loading.get,
    loading.post,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);

  const currentYear = useMemo(() => now.getFullYear(), []);
  const currentWeek = useMemo(() => getWeekNumber(now), []);

  const lineYear = lineItem?.desiredDeliveryCalendarWeek?.year ?? null;
  const minLineYear =
    lineItem?.minimumDesiredDeliveryCalendarWeek?.year ?? null;
  const offerYear = offer?.desiredDeliveryCalendarWeek?.year ?? null;
  const orderYear = order?.desiredDeliveryCalendarWeek?.year ?? null;
  const parentYear = offerYear ?? orderYear;

  const lineWeek =
    lineItem?.desiredDeliveryCalendarWeek?.calendarWeekNumber ?? null;
  const minLineWeek =
    lineItem?.minimumDesiredDeliveryCalendarWeek?.calendarWeekNumber ?? null;
  const offerWeek =
    offer?.desiredDeliveryCalendarWeek?.calendarWeekNumber ?? null;
  const orderWeek =
    order?.desiredDeliveryCalendarWeek?.calendarWeekNumber ?? null;
  const parentWeek = offerWeek ?? orderWeek;

  const [maxLineYear, setMaxLineYear] = useState<number | null>(null);
  const [maxLineWeek, setMaxLineWeek] = useState<number | null>(null);
  const [year, setYear] = useState<number | null>(null);
  const [week, setWeek] = useState<number | null>(null);
  const [asLines, toggleAsLines, diActiveAsLines] = useToggle(false);

  const hasYear = year !== null;
  const hasWeek = week !== null;
  const hasCalendarWeek = hasYear && hasWeek;

  const initYearItems = [...Array(5).keys()]
    .map((e) => currentYear + e)
    .map((e) => ({ name: e.toString(), id: e }));
  const initWeekItems = useMemo(() => {
    if (!hasYear) return [];
    const daysOfYear = getDaysOfYear(year);
    const initWeekItemsLength = Math.ceil(daysOfYear / 7);
    return [...Array(initWeekItemsLength).keys()].map((e) => {
      const weekNumber = e + 1;
      const monthNames = getMothFromWeek(weekNumber, year).join(" - ");
      return {
        name: `Week ${weekNumber} (${monthNames})`,
        id: weekNumber,
      };
    });
  }, [year, hasYear]);

  const parentYearItems = useMemo(() => {
    if (!maxLineYear) return initYearItems;
    return initYearItems.filter((e) => e.id >= maxLineYear);
  }, [maxLineYear, initYearItems]);

  const lineYearItems = useMemo(() => {
    const minYear = minLineYear ?? 0;
    const maxYear = parentYear ?? Infinity;
    return initYearItems.filter((e) => minYear <= e.id && e.id <= maxYear);
  }, [parentYear, initYearItems, minLineYear]);

  const parentWeekItems = useMemo(() => {
    const currentTime = calendarWeekToTime(currentYear, currentWeek);
    const maxLineTime = calendarWeekToTime(maxLineYear, maxLineWeek);
    const minTime = Math.max(currentTime ?? 0, maxLineTime ?? 0);
    return initWeekItems.filter((e) => {
      const time = calendarWeekToTime(year, e.id);
      if (!time) return false;
      return time >= minTime;
    });
  }, [year, initWeekItems, currentYear, currentWeek, maxLineYear, maxLineWeek]);

  const lineWeekItems = useMemo(() => {
    const currentTime = calendarWeekToTime(currentYear, currentWeek);
    const minLineTime = calendarWeekToTime(minLineYear, minLineWeek);
    const maxParentTime = calendarWeekToTime(parentYear, parentWeek);
    const minTime = Math.max(currentTime ?? 0, minLineTime ?? 0);
    const maxTime = maxParentTime ?? Infinity;
    return initWeekItems.filter((e) => {
      const time = calendarWeekToTime(year, e.id);
      if (!time) return false;
      return time >= minTime && time <= maxTime;
    });
  }, [
    year,
    initWeekItems,
    currentYear,
    currentWeek,
    minLineYear,
    minLineWeek,
    parentYear,
    parentWeek,
  ]);

  const yearItems = isLineItem ? lineYearItems : parentYearItems;
  const weekItems = isLineItem ? lineWeekItems : parentWeekItems;

  const handleSetBaseData = () => {
    if (!isOpen) return;
    const year = isLineItem ? lineYear : parentYear;
    const minYear = parentYearItems[0]?.id;
    setYear(year ?? minYear ?? null);
    setWeek(isLineItem ? lineWeek : parentWeek);
    diActiveAsLines();
  };

  const getMaxDateLineItem = () => {
    if (!isOpen || isLineItem || !lineItems.length) return;
    const data = lineItems;
    const dates = data.map((e) => {
      const year = e.desiredDeliveryCalendarWeek?.year;
      const week = e.desiredDeliveryCalendarWeek?.calendarWeekNumber;
      return {
        year: year ?? null,
        week: week ?? null,
        time: calendarWeekToTime(year, week) ?? 0,
      };
    });
    const minDates = data.map((e) => {
      const year = e.minimumDesiredDeliveryCalendarWeek?.year;
      const week = e.minimumDesiredDeliveryCalendarWeek?.calendarWeekNumber;
      return {
        year: year ?? null,
        week: week ?? null,
        time: calendarWeekToTime(year, week) ?? 0,
      };
    });

    const sortedDates = dates.sort((a, b) => a.time - b.time);
    const sortedMinDates = minDates.sort((a, b) => a.time - b.time);

    const date = sortedDates.at(-1);
    const minDate = sortedMinDates.at(-1);

    const allDates = [date, minDate].sort(
      (a, b) => (a?.time ?? 0) - (b?.time ?? 0)
    );

    const result = allDates.at(-1);
    if (!result) return;
    setMaxLineYear(result.year);
    setMaxLineWeek(result.week);
  };
  const getLineItem = async () => {
    if (!isLineItem) return;
    const url = [
      "/salesservice",
      "api",
      !!isOfferLineItem && "offerlineitem",
      !!isOrderLineItem && "orderlineitems",
      lineItem.id,
    ]
      .filter(Boolean)
      .join("/");
    return await axios
      .get(url)
      .then((res) => res.data as LineItem.Item)
      .catch(() => null);
  };
  const submitHeaderDate = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer ? "offer" : "orders",
      offer?.id || order?.id,
      "desire-delivery-date",
    ].join("/");
    const body = {
      desiredDeliveryDate: null,
      desiredDeliveryCalendarWeek: { year: year!, calendarWeekNumber: week! },
      setAsLineItemsDefault: asLines,
    };
    axios.post(url, body).then(() => {
      !!body.setAsLineItemsDefault && onApplyForLineItems?.();
      updateHeader?.().then(() => {
        toast.success("toast.success.offerDesiredDeliveryDate");
        toggle();
      });
    });
  };
  const submitLineItemDate = () => {
    const url = !!offer
      ? "/salesservice/api/offerlineitem/desired-delivery-date"
      : "/salesservice/api/orderlineitems/desired-deliveri-date";
    const body = {
      desiredDeliveryDate: null,
      [!!offer ? "offerLineItemIds" : "orderLineItemIds"]: [lineItem?.id],
      desiredDeliveryCalendarWeek: { year: year, calendarWeekNumber: week },
    };
    axios.post(url, body).then(() => {
      getLineItem().then((lineItem) => {
        toast.success("toast.success.lineItemDesiredDeliveryDate");
        setLineItems?.((p) => {
          const lineItems = cloneDeep(p);
          if (!lineItem) return lineItems;
          const index = lineItems.findIndex((e) => e.id === lineItem?.id);
          lineItems[index] = lineItem;
          lineItems.forEach((e, i) => {
            const needChange = e.parentId === lineItem?.id;
            if (needChange) {
              lineItems[i].desiredDeliveryCalendarWeek =
                lineItem.desiredDeliveryCalendarWeek;
              lineItems[i].desiredDeliveryDate = lineItem.desiredDeliveryDate;
            }
          });
          return lineItems;
        });
        onSubmitted?.({ lineItem });
        toggle();
      });
    });
  };
  useEffect(handleSetBaseData, [isOpen]);
  useEffect(getMaxDateLineItem, [isOpen, isLineItem, lineItems]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-[31]">
      <Form onSubmit={isLineItem ? submitLineItemDate : submitHeaderDate}>
        <Modal.Header>
          <h6 className="text-dark text-base">
            <Text>modalTitles.desireDeliveryDate</Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <section className="grid grid-cols-2 gap-x-4 gap-y-8">
            <Select
              label="formControls.desireDeliveryDateYear"
              value={year}
              setValue={setYear}
              items={yearItems}
              rules={rules.required}
              loading={getLineItemsLoading}
            />
            <Select
              label="formControls.desireDeliveryDateWeek"
              value={week}
              setValue={setWeek}
              items={weekItems}
              rules={rules.required}
              loading={getLineItemsLoading}
              disabled={!hasYear}
            />
            {hasCalendarWeek && (
              <div className="col-span-full flex items-center gap-2">
                <Icon.Wrapper variant="primary" rounded>
                  <Icon name="CalendarTick" variant="Bold" size="1.5rem" />
                </Icon.Wrapper>
                <div className="flex-1">
                  <p className="text-base text-dark">
                    <Text>global.calendarWeek</Text> {week}
                  </p>
                </div>
              </div>
            )}
          </section>
        </Modal.Body>
        {!isLineItem && (
          <Modal.Footer>
            <CheckBox
              label="formControls.applyDesireDeliveryDateToAllLineItemCheckBox"
              value={asLines}
              setValue={toggleAsLines}
            />
          </Modal.Footer>
        )}
        <Modal.Footer className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="danger"
            disabled={hasLoading}
            onClick={toggle}
          >
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" variant="primary" loading={hasLoading}>
            <Text>button.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
