import { Fragment, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { OfferDetailsContext } from "..";
import {
  Button,
  Counter,
  Drawer,
  Form,
  Icon,
  Image,
  InputGroup,
  Loading,
  Text,
  Types,
} from "../../../../components";
import { rules, types } from "../../../../constants";
import { AddOptionalDiscounts } from "../../../../containers";
import { CalculationOperationType, LineItemType } from "../../../../enums";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import { cleanText, convertOptionalDiscounts } from "../../../../methods";
import { LineItem, togglePropsType } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";
import { noteSuggestion } from "./notes";

type EditLineItemProps = togglePropsType & {
  lineItem: LineItem.Item;
  isSubLineItem: boolean;
  onSubmitted: () => void;
  isTitle: boolean;
  isPrice: boolean;
  isDescription: boolean;
  isLineItemNote: boolean;
  isCustomerNote: boolean;
  isSupplierNote: boolean;
  isConfigurationText: boolean;
  isQuantity: boolean;
};

export default function EditLineItem({
  isOpen,
  toggle,
  lineItem,
  isSubLineItem,
  onSubmitted,
  isTitle,
  isPrice,
  isDescription,
  isLineItemNote,
  isCustomerNote,
  isSupplierNote,
  isConfigurationText,
  isQuantity,
}: EditLineItemProps) {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const isCatalogue = lineItem.lineItemType === LineItemType.ManualProduct;

  const { axios, loading } = useAxios();
  const { convertAmount } = useConverters();
  const isOffer = !!offerContext;
  const isOrder = !!orderContext;
  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;

  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const getLoading = loading.get;
  const submitLoading = [
    loading.update,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);

  const initData: LineItem.EditData = {
    title: lineItem.title,
    // productTitle: lineItem.product?.title ?? "",
    price: lineItem.originalPrice,
    description: lineItem.description,
    [isOffer ? "offerLineItemId" : "orderLineItemId"]: lineItem.id,
    lineItemNote: lineItem.lineItemNote,
    supplierNote: lineItem.supplierNote,
    customerNote: lineItem.customerNote,
    ean: lineItem.ean,
    quantity: lineItem.quantity,
    shippingMethodCode: lineItem.shippingMethodCode,
    optionalDiscounts: convertOptionalDiscounts(lineItem.discounts),
    configurationText: cleanText(lineItem.configurationText),
  };
  const [data, setData] = useState(initData);
  const [showOptionalDiscounts, toggleOptionalDiscounts] = useToggle(false);
  const handleSetBasicData = () => {
    if (!isOpen) return;
    setData(initData);
  };
  const handleSetValue = (key: keyof typeof data) => {
    return (value: unknown) => {
      setData((p) => ({ ...p, [key]: value }));
    };
  };
  const submitOfferLineItemDiscount = () => {
    const url = [
      "/salesservice",
      "api",
      "offerlineitem",
      lineItem.id,
      // isSubLineItem
      //   ? "apply-sub-line-item-discount"
      //   :
      isCatalogue ? "apply-manual-product-discount" : "apply-discount",
    ]
      .filter(Boolean)
      .join("/");
    const lineItemBody: LineItem.ApplyOfferLineItemDiscount = {
      offerLineItemId: lineItem?.id,
      offerId: lineItem.offerId ?? "",
      quantity: data.quantity,
      optionalDiscounts: convertOptionalDiscounts(lineItem.discounts),
    };
    const catalogueBody: LineItem.ApplyOfferLineItemManualProductDiscount = {
      offerLineItemId: lineItem?.id,
      offerId: lineItem.offerId ?? "",
      quantity: data.quantity,
      salesPrice: data.price,
      optionalDiscounts: convertOptionalDiscounts(lineItem.discounts),
    };
    const subLineItemBody: LineItem.ApplyOfferSubLineItemDiscount = {
      offerLineItemId: lineItem?.id,
      offerId: lineItem.offerId ?? "",
      parentId: lineItem.parentId,
      quantity: data.quantity,
      salesPrice: data.price,
      optionalDiscounts: convertOptionalDiscounts(lineItem.discounts),
    };
    const body =
      // isSubLineItem
      //   ? subLineItemBody
      //   :
      isCatalogue ? catalogueBody : lineItemBody;
    axios.put(url, body).then(() => {
      updateOffer?.().then(() => {
        const message = isSubLineItem
          ? "toast.success.editSubLineItem"
          : "toast.success.editLineItem";
        toast.success(message);
        onSubmitted();
        toggle();
      });
    });
  };
  const submitOfferLineItem = () => {
    const url = [
      "/salesservice",
      "api",
      "offerlineitem",
      lineItem.id,
      isCatalogue && "manual-product",
    ]
      .filter(Boolean)
      .join("/");
    const defaultBody: LineItem.Edit = {
      offerLineItemId: lineItem.id,
      orderLineItemId: lineItem.id,
      offerId: lineItem.offerId,
      orderId: lineItem.orderId,
      title: data.title,
      lineItemNote: data.lineItemNote,
      supplierNote: data.supplierNote,
      customerNote: data.customerNote,
      ean: data.ean,
      description: data.description,
      shippingMethodCode: data.shippingMethodCode,
      configurationText: data.configurationText,
      lineItemType: lineItem.lineItemType,
    };
    const catalogueBody: LineItem.EditManual = {
      offerLineItemId: lineItem.id,
      orderLineItemId: lineItem.id,
      offerId: lineItem.offerId,
      orderId: lineItem.orderId,
      title: data.title,
      lineItemNote: data.lineItemNote,
      supplierNote: data.supplierNote,
      customerNote: data.customerNote,
      ean: data.ean,
      shippingMethodCode: data.shippingMethodCode,
      lineItemDescription: data.description,
      configurationText: data.configurationText,
    };
    const body = isCatalogue ? catalogueBody : defaultBody;
    axios.put(url, body).then(() => {
      updateOffer?.().then(() => {
        const message = isSubLineItem
          ? "toast.success.editSubLineItem"
          : "toast.success.editLineItem";
        toast.success(message);
        onSubmitted();
        toggle();
      });
    });
  };
  const submitOrderLienItem = () => {
    const url = [
      "/salesservice",
      "api",
      "orderlineitems",
      lineItem.id,
      isSubLineItem
        ? "apply-sub-line-item-discount"
        : isCatalogue
        ? "apply-manual-product-discount"
        : "apply-discount",
    ]
      .filter(Boolean)
      .join("/");
    const lineItemBody: LineItem.ApplyOrderLineItemDiscount = {
      orderLineItemId: lineItem?.id,
      orderId: lineItem.orderId ?? "",
      quantity: data.quantity,
      optionalDiscounts: convertOptionalDiscounts(lineItem.discounts),
    };
    const catalogueBody: LineItem.ApplyOrderLineItemManualProductDiscount = {
      orderLineItemId: lineItem?.id,
      orderId: lineItem.orderId ?? "",
      quantity: data.quantity,
      salesPrice: data.price,
      optionalDiscounts: convertOptionalDiscounts(lineItem.discounts),
    };
    const subLineItemBody: LineItem.ApplyOrderSubLineItemDiscount = {
      orderLineItemId: lineItem?.id,
      orderId: lineItem.orderId ?? "",
      parentId: lineItem.parentId,
      quantity: data.quantity,
      salesPrice: data.price,
      optionalDiscounts: convertOptionalDiscounts(lineItem.discounts),
    };

    const body = isSubLineItem
      ? subLineItemBody
      : isCatalogue
      ? catalogueBody
      : lineItemBody;
    axios.put(url, body).then(() => {
      updateOrder?.().then(() => {
        const message = isSubLineItem
          ? "toast.success.editSubLineItem"
          : "toast.success.editLineItem";
        toast.success(message);
        onSubmitted();
        toggle();
      });
    });
  };
  const submit = () => {
    const updateDiscounts = isQuantity || isPrice;
    if (isOffer) {
      return updateDiscounts
        ? submitOfferLineItemDiscount()
        : submitOfferLineItem();
    }
    isOrder && submitOrderLienItem();
  };
  useEffect(handleSetBasicData, [lineItem, isOpen]);
  return (
    <Fragment>
      <Drawer as={Form} isOpen={isOpen} toggle={toggle} onSubmit={submit}>
        <Drawer.Menu>
          <Drawer.Header>
            <h6 className="text-dark text-base">
              <Text>drawerTitles.editLineItem</Text>
            </h6>
          </Drawer.Header>
          {getLoading ? (
            <Drawer.Body>
              <Loading.Inline />
            </Drawer.Body>
          ) : (
            <Drawer.Body className="space-y-5">
              <section className="flex items-center gap-4 border-b border-dashed pb-4">
                <Image
                  alt={lineItem.title}
                  className="w-32 h-20 object-cover rounded shadow"
                  // isDynamic
                />
                <div className="flex-1 space-y-1 [&>p]:text-xs [&>p]:text-secondary [&>p>span]:text-dark">
                  <p>
                    <Text>global.articleNumber</Text>:{" "}
                    <span>#{lineItem.product?.articleNumber}</span>
                  </p>
                  <p>
                    <Text>global.supplierName</Text>:{" "}
                    <span>{lineItem.supplier?.name ?? "--"}</span>
                  </p>
                  <p>
                    <Text>global.programName</Text>:{" "}
                    <span>{lineItem.supplierProgram?.name ?? "--"}</span>
                  </p>
                  <Types.LineItem id={lineItem.lineItemType} />
                </div>
              </section>
              {/* {isTitle && isCatalogue && (
                <InputGroup
                  label="formControls.lineItemCatalogueProductTitle"
                  value={data?.productTitle}
                  setValue={handleSetValue("productTitle")}
                  rules={rules.required}
                  className="col-span-full"
                />
              )} */}
              {isTitle && (
                <InputGroup
                  label="formControls.lineItemTitle"
                  value={data.title}
                  setValue={handleSetValue("title")}
                  rules={rules.required}
                />
              )}
              {isQuantity && (
                <Counter
                  label="formControls.quantity"
                  value={data?.quantity}
                  setValue={handleSetValue("quantity")}
                  type="decimal"
                  min={0}
                />
              )}
              {isPrice && isCatalogue && (
                <InputGroup
                  label="formControls.lineItemCatalogueSalesPrice"
                  value={data?.price}
                  setValue={handleSetValue("price")}
                  className="col-span-2"
                  type="price"
                />
              )}
              {isPrice && (
                <Fragment>
                  <section className="space-y-2 [&_div]:bg-gray-50 [&_div]:rounded [&_div]:py-2 [&_div]:px-4 [&_div]:flex [&_div]:items-center [&_div]:gap-2 [&_div_h6]:flex-1 [&_div_h6]:text-sm [&_div_h6]:text-dark [&_div_p]:text-sm [&_div_p]:text-secondary">
                    <h6 className="text-base text-dark leading-8">
                      <Text>global.price</Text>
                    </h6>
                    <div>
                      <h6>
                        <Text>global.originalPrice</Text>:
                      </h6>
                      <p>{convertAmount(lineItem.originalPrice)}</p>
                    </div>
                    <div>
                      <h6>
                        <Text>global.productPrice</Text>:
                      </h6>
                      <p>{convertAmount(lineItem.unitPrice)}</p>
                    </div>
                    <div className="!p-0">
                      <table className="w-full [&_td]:py-2 [&_td]:px-4 text-dark">
                        <tbody>
                          {lineItem.discounts
                            ?.sort((a, b) => a.order - b.order)
                            .map((e) => {
                              const isIncrement =
                                e.operationType ===
                                CalculationOperationType.Increment;
                              const isDecrement =
                                e.operationType ===
                                CalculationOperationType.Decrement;
                              const calculationValue =
                                types.calculationValue.find(
                                  ({ id }) => id === e.valueType
                                );
                              const amountSymbols = {
                                [CalculationOperationType.Increment]: "+",
                                [CalculationOperationType.Decrement]: "-",
                              };
                              const amountSymbol =
                                amountSymbols?.[e.operationType];
                              return (
                                <tr
                                  key={e.discountIdentifier}
                                  className="text-sm"
                                >
                                  <td className="text-start">{e.title}</td>
                                  <td
                                    className={twMerge(
                                      "text-start",
                                      isIncrement && "text-success",
                                      isDecrement && "text-danger"
                                    )}
                                  >
                                    {amountSymbol}
                                    {e.itemValue} {calculationValue?.symbol}
                                  </td>
                                  <td
                                    className={twMerge(
                                      "text-end",
                                      isIncrement && "text-success",
                                      isDecrement && "text-danger"
                                    )}
                                  >
                                    {amountSymbol}
                                    {convertAmount(e.discountAmount)}
                                  </td>
                                </tr>
                              );
                            })}
                          <tr>
                            <td colSpan={3}>
                              <Button
                                type="button"
                                onClick={toggleOptionalDiscounts}
                                className="p-2 text-sm"
                              >
                                <Icon name="Add" />{" "}
                                <Text>button.addLineItemDiscount</Text>
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <h6>
                        <Text>global.finalPrice</Text>:
                      </h6>
                      <p>{convertAmount(lineItem.unitPriceWithDiscount)}</p>
                    </div>
                  </section>
                </Fragment>
              )}
              {isDescription && (
                <InputGroup
                  as="textarea"
                  label="formControls.description"
                  value={data.description}
                  setValue={handleSetValue("description")}
                />
              )}
              {isLineItemNote && (
                <InputGroup
                  snippet
                  suggestionData={noteSuggestion}
                  as="textarea"
                  label="formControls.lineItemNote"
                  value={data.lineItemNote}
                  setValue={handleSetValue("lineItemNote")}
                />
              )}
              {isCustomerNote && (
                <InputGroup
                  snippet
                  suggestionData={noteSuggestion}
                  as="textarea"
                  label="formControls.lineItemCustomerNote"
                  value={data.customerNote}
                  setValue={handleSetValue("customerNote")}
                />
              )}
              {isSupplierNote && (
                <InputGroup
                  snippet
                  suggestionData={noteSuggestion}
                  as="textarea"
                  label="formControls.lineItemSupplierNote"
                  value={data.supplierNote}
                  setValue={handleSetValue("supplierNote")}
                />
              )}
              {isConfigurationText && (
                <InputGroup
                  as="textarea"
                  label="formControls.lineItemConfigurationText"
                  value={data.configurationText}
                  setValue={handleSetValue("configurationText")}
                />
              )}
            </Drawer.Body>
          )}
          {(!isPrice || isCatalogue) && (
            <Drawer.Footer className="flex items-center justify-end gap-4">
              <Button type="button" variant="danger" onClick={toggle}>
                <Text>button.cancel</Text>
              </Button>
              <Button type="submit" variant="primary" loading={submitLoading}>
                <Text>button.submitChanges</Text>
              </Button>
            </Drawer.Footer>
          )}
        </Drawer.Menu>
      </Drawer>
      {/* {isOpen && isPrice && !isCatalogue && ( */}
      {isOpen && isPrice && (
        <AddOptionalDiscounts
          isOpen={showOptionalDiscounts}
          toggle={toggleOptionalDiscounts}
          lineItem={lineItem}
          isSubLineItem={isSubLineItem}
        />
      )}
    </Fragment>
  );
}
