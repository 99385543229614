const task = {
  list: {
    createNewButton: "Create new",
    assignedToMe: "Assigned to me",
    assignedToDepartment: "Assigned to department",
    createdAt: "Created at",
    dueDate: "Due date",
    detailsButton: "Details",
  },
  filter: {
    title: "Filter",
    resetFilterBtn: "Reset filter",
    taskStatus: "Task status",
    cancelBtn: "Cancel",
    submitBtn: "Submit filter",
  },
  create: {
    title: "New task",
    cancelButton: "Cancel",
    submitButton: "Submit task",
    subject: "Task subject",
    subjectPlaceholder: "Enter subject",
    description: "Task description",
    descriptionPlaceholder: "Add a note about this ",
    priority: "Priority",
    duration: "Duration",
    dueDate: "Due date",
    assignEmployee: "Assigned employee",
    successMessage: "Task has been created.",
  },
  details: {
    cancelButton: "Cancel task",
    completeButton: "Complete task",
    generalTab: "General",
    activitiesTab: "Notes",
    taskCreatedAt: "Created at",
    taskDuration: "Duration",
    taskDueDate: "Due date",
    general: {
      targetTitle: "Target",
      submitText: "Please submit your changes",
      submitButton: "Submit Changes",
      subject: "Task subject",
      subjectPlaceholder: "Enter subject",
      description: "Task description",
      descriptionPlaceholder: "Add a note about this ",
      priority: "Priority",
      duration: "Duration",
      dueDate: "Due date",
      assignEmployee: "Assigned employee",
      successMessage: "Task has been updated.",
    },
    activities: {
      addNoteButton: "Add activity note",
    },
    createNote: {
      drawerTitle: "Create new activity note",
      description: "Description",
      descriptionPlaceholder: "Add a note about this topic",
      cancelButton: "Cancel",
      submitButton: "Add activity note",
      successMessage: "Note created successfully.",
    },
  },
  changeStatus: {
    title: "Task status",
    cancelButton: "Cancel",
    completedButton: "Completed",
    discardButton: "Discard and close",
    canceledButton: "Canceled",
    successMessage: "Status has been changed.",
  },
  durationItems: {
    "1m": "1 minute",
    "15m": "15 minutes",
    "30m": "30 minutes",
    "45m": "45 minutes",
    "1h": "1 hour",
    "1.5h": "1.5 hours",
    "2h": "2 hours",
    "2.5h": "2.5 hours",
    "3h": "3 hours",
    "3.5h": "3.5 hours",
    "4h": "4 hours",
    "4.5h": "4.5 hours",
    "5h": "5 hours",
    "5.5h": "5.5 hours",
    "6h": "6 hours",
    "6.5h": "6.5 hours",
    "7h": "7 hours",
    "7.5h": "7.5 hours",
    "8h": "8 hours",
    "1d": "1 day",
    "2d": "2 days",
    "3d": "3 days",
  },
};
export default task;
