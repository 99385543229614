import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  BackButton,
  Button,
  Icon,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { CustomerCard, OfferForm, RejectOfferMenu } from "../../../containers";
import { CustomerType, OfferStatus } from "../../../enums";
import { withPermission } from "../../../hoc";
import {
  useAxios,
  useConverters,
  usePermission,
  useToggle,
} from "../../../hooks";
import {
  Company,
  Customer,
  LineItem,
  Offer,
  iconNameType,
} from "../../../types";
import AcceptOffer from "./AcceptOffer";
import Billing from "./Billing";
import CompletedInfo from "./CompletedInfo";
import Documents from "./Documents";
import LineItems from "./LineItems";
import Logistic from "./Logistic";
import Notes from "./Notes";

type MoreInfoItem = {
  icon: iconNameType;
  label: string;
  value: string | null | undefined;
};
type OfferData = Offer.Details;
type OfferDetailsContextType = {
  offer: OfferData;
  setOffer: Dispatch<SetStateAction<OfferData | null>>;
  updateOffer: () => Promise<OfferData | null>;
  updateOfferLoading: boolean;
  customer: Customer.Details | null;
  isEnabled: boolean;
  isCompany: boolean;
  setUnsignedDocuments: Dispatch<SetStateAction<boolean>>;
  setLineItemsHasShippingMethod: Dispatch<SetStateAction<boolean>>;
  setLineItemsHasShippingDate: Dispatch<SetStateAction<boolean>>;
};
export const OfferDetailsContext = createContext<
  undefined | OfferDetailsContextType
>(undefined);

function OfferDetails() {
  const canEditOffer = usePermission(
    "SC_SaleServiceOfferFullAccess",
    "SC_UpdateSaleServiceOffer"
  );
  const canAcceptOffer = usePermission(
    "SC_SaleServiceOfferFullAccess",
    "SC_MakeSaleServiceOrderFromSaleServiceOffer"
  );
  const { offerId } = useParams();
  const { axios, loading } = useAxios();
  const { axios: offerAxios, loading: offerLoading } = useAxios();
  const { convertDate, convertAmount } = useConverters();
  const [offer, setOffer] = useState<OfferData | null>(null);
  const [customer, setCustomer] = useState<
    (Customer.Details & Company.Details) | null
  >(null);
  const [unsignedDocuments, setUnsignedDocuments] = useState(false);
  const [lineItemsHasShippingMethod, setLineItemsHasShippingMethod] =
    useState(false);
  const [lineItemsHasShippingDate, setLineItemsHasShippingDate] =
    useState(false);
  const [showEditOffer, toggleEditOffer] = useToggle(false);
  const [showRejectOffer, toggleRejectOffer] = useToggle(false);
  const [showAcceptOffer, toggleAcceptOffer] = useToggle(false);
  const isCompany = offer?.customer?.customerType === CustomerType.Company;

  const hasData = !!offer;
  const isEnabled = offer?.status === OfferStatus.Active;
  const moreInfo: MoreInfoItem[] = [
    {
      icon: "Calendar",
      label: "global.lastUpdate",
      value: convertDate(offer?.lastUpdatedAt || offer?.createdAt),
    },
    // {
    //   icon: "LocationTick",
    //   label: "global.storeName",
    //   value: store?.title,
    // },
  ];
  const tabs = [
    {
      label: "tabs.lineItems",
      id: "0",
      component: LineItems,
    },
    {
      label: "tabs.notes",
      id: "4",
      component: Notes,
    },
    {
      label: "tabs.logistic",
      id: "1",
      component: Logistic,
    },
    {
      label: "tabs.billing",
      id: "2",
      component: Billing,
    },
    {
      label: "tabs.offerDocuments",
      id: "3",
      component: Documents,
    },
  ];
  const updateOffer = async () => {
    // setOfferLoading(true);
    const url = `/salesservice/api/offer/${offerId}`;
    // await sleep(1500);
    return offerAxios
      .get(url)
      .then(({ data }) => {
        setOffer(data);
        return data as OfferData;
      })
      .catch(() => null);
    // .finally(() => {
    //   setOfferLoading(false);
    // });
  };
  const getOffer = () => {
    const url = `/salesservice/api/offer/${offerId}`;
    axios.get(url).then(({ data }) => {
      const isCompany = data.customer?.customerType === CustomerType.Company;
      setOffer(data);
      getUnsignedDocument(data.id);
      getCustomer(data?.customer?.customerId, isCompany);
      getLineItems();
    });
  };
  const getCustomer = (id: string, isCompany: boolean) => {
    if (!id) return;
    const url = isCompany
      ? `/accountservice/api/customers/${id}/as-company`
      : `/accountservice/api/customers/${id}`;
    axios.get(url).then(({ data }) => {
      setCustomer(data);
    });
  };

  const getUnsignedDocument = (id: string) => {
    const url = `/salesservice/api/offerdocuments/${id}/unsigned-mandatory-document`;
    axios.get(url).then(({ data }) => {
      setUnsignedDocuments(!!data);
    });
  };
  const getLineItems = () => {
    const url = "/salesservice/api/offerlineitem";
    const config = { params: { offerId: offerId } };
    axios.get(url, config).then(({ data }) => {
      const result: LineItem.Item[] = data || [];
      const hasLineItems = !!result.length;
      const hasShipping = result.every((e) => Boolean(e.shippingMethodCode));
      const lineItemsHasShippingMethod = !hasLineItems || hasShipping;
      setLineItemsHasShippingMethod(lineItemsHasShippingMethod);
      const hasShippingDate = result.every((e) =>
        Boolean(e.desiredDeliveryDate)
      );
      const lineItemsHasShippingDate = !hasLineItems || hasShippingDate;
      setLineItemsHasShippingDate(lineItemsHasShippingDate);
    });
  };
  useEffect(getOffer, [offerId]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <BackButton to="/offers" className="mr-auto" />
          {isEnabled && !loading.get && (
            <Fragment>
              {canEditOffer && (
                <Button variant="danger" onClick={toggleRejectOffer}>
                  <Text>button.rejectOffer</Text>
                </Button>
              )}
              {canAcceptOffer && (
                <Button variant="success" onClick={toggleAcceptOffer}>
                  <Text>button.acceptOffer</Text>
                </Button>
              )}
            </Fragment>
          )}
        </Layout.Header>
        <Layout.Body className="grid grid-cols-1 lg:grid-cols-5 gap-3 [&>*]:col-span-full">
          {loading.get ? (
            <Loading.Header />
          ) : (
            <Fragment>
              <Wrapper className="lg:!col-span-3">
                <Wrapper.Body className="flex flex-wrap items-start gap-4">
                  <div className="flex-1 flex flex-col gap-3">
                    <section className="flex items-center gap-2">
                      <Icon.Wrapper rounded>
                        <Icon name="DiscountShape" variant="Bold" />
                      </Icon.Wrapper>
                      <button
                        type="button"
                        className="text-start"
                        onClick={toggleEditOffer}
                      >
                        <h1 className="text-base text-dark">
                          {offer?.title}{" "}
                          {canEditOffer && (
                            <Icon
                              name="Edit2"
                              size="1em"
                              className="text-primary"
                            />
                          )}
                        </h1>
                        <p className="text-sm text-secondary">
                          #{offer?.number}
                        </p>
                      </button>
                    </section>
                    <section className="flex flex-wrap items-center gap-5">
                      {moreInfo.map((e) => (
                        <p key={e.label} className="text-sm">
                          <Icon
                            name={e.icon}
                            variant="Bulk"
                            className="text-primary"
                          />{" "}
                          <span className="text-placeholder">
                            <Text>{e.label}</Text>
                          </span>
                          {": "}
                          <span className="text-secondary ">{e.value}</span>
                        </p>
                      ))}
                    </section>
                    <section className="flex items-center flex-wrap gap-3 mt-auto">
                      <span className="bg-gray-50 px-2 py-1 rounded-md">
                        <Icon
                          name="DollarSquare"
                          variant="Bold"
                          size={22}
                          className="text-dark"
                        />{" "}
                        <Text>global.totalPrice</Text>:{" "}
                        {convertAmount(offer?.totalAmount)}
                      </span>
                      <Status.Offer id={offer?.status} />
                    </section>
                  </div>
                  {hasData && isEnabled && (
                    <CompletedInfo
                      offer={offer}
                      signedDocuments={!unsignedDocuments}
                      lineItemsHasShippingMethod={lineItemsHasShippingMethod}
                      lineItemsHasShippingDate={lineItemsHasShippingDate}
                    />
                  )}
                </Wrapper.Body>
              </Wrapper>
              <CustomerCard customer={customer} className="lg:!col-span-2" />
              <Tabs activeKey={tabs[0].id}>
                <Wrapper className="col-span-full">
                  <Wrapper.Body className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Body>
                </Wrapper>
                {hasData && (
                  <Fragment>
                    <OfferDetailsContext.Provider
                      value={{
                        offer,
                        setOffer,
                        updateOffer,
                        updateOfferLoading: offerLoading.get,
                        customer,
                        isEnabled: isEnabled && canEditOffer,
                        isCompany,
                        setUnsignedDocuments,
                        setLineItemsHasShippingMethod,
                        setLineItemsHasShippingDate,
                      }}
                    >
                      {tabs.map((e) => (
                        <Tabs.Item key={e.id} eventKey={e.id}>
                          {createElement(e.component)}
                        </Tabs.Item>
                      ))}
                      {canAcceptOffer && (
                        <AcceptOffer
                          isOpen={showAcceptOffer}
                          toggle={toggleAcceptOffer}
                          signedDocuments={!unsignedDocuments}
                          lineItemsHasShippingMethod={
                            lineItemsHasShippingMethod
                          }
                          lineItemsHasShippingDate={lineItemsHasShippingDate}
                        />
                      )}
                    </OfferDetailsContext.Provider>
                    {canEditOffer && (
                      <RejectOfferMenu
                        isOpen={showRejectOffer}
                        toggle={toggleRejectOffer}
                        offerData={offer}
                        onSubmitted={getOffer}
                      />
                    )}
                  </Fragment>
                )}
              </Tabs>
            </Fragment>
          )}
        </Layout.Body>
      </Layout>
      {!loading.get && hasData && canEditOffer && (
        <Fragment>
          {isCompany ? (
            <OfferForm
              isOpen={showEditOffer}
              toggle={toggleEditOffer}
              offer={offer}
              company={customer as Company.Details}
              onSubmitted={getOffer}
            />
          ) : (
            <OfferForm
              isOpen={showEditOffer}
              toggle={toggleEditOffer}
              offer={offer}
              customer={customer as Customer.Details}
              onSubmitted={getOffer}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
export default withPermission(OfferDetails, [
  "SC_SaleServiceOfferFullAccess",
  "SC_GetSaleServiceOffer",
]);
