import {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import { twMerge } from "tailwind-merge";
import {
  Alert,
  BackButton,
  Button,
  Icon,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { CustomerCard, ErpStatusHistory } from "../../../containers";
import { CustomerType, OrderInternalStatus } from "../../../enums";
import { withPermission } from "../../../hoc";
import {
  useAxios,
  useConverters,
  usePermission,
  useToggle,
} from "../../../hooks";
import {
  Company,
  Customer,
  LineItem,
  Order,
  iconNameType,
} from "../../../types";
import Billing from "../../Offers/Details/Billing";
import Documents from "../../Offers/Details/Documents";
import LineItems from "../../Offers/Details/LineItems";
import Logistic from "../../Offers/Details/Logistic";
import Notes from "../../Offers/Details/Notes";
import ChangeOrderStatus from "./ChangeOrderStatus";
import EditOrder from "./EditOrder";
import Incidents from "./Incidents";
import PublishOrder from "./PublishOrder";
import SalesInfo from "./SalesInfo";

type MoreInfoItem = {
  icon: iconNameType;
  label: string;
  value: ReactNode;
  fullWidth?: boolean;
};
type OrderData = Order.Details;
type OrderDetailsContextType = {
  order: OrderData;
  setOrder: Dispatch<SetStateAction<OrderData | null>>;
  updateOrder: () => Promise<Order.Details | null>;
  updateOrderLoading: boolean;
  customer: Customer.Details | null;
  isEnabled: boolean;
  isPublished: boolean;
  setUnsignedDocuments: Dispatch<SetStateAction<boolean>>;
  setLineItemsHasShippingMethod: Dispatch<SetStateAction<boolean>>;
};
export const OrderDetailsContext = createContext<
  undefined | OrderDetailsContextType
>(undefined);

function OrderDetails() {
  const canEditOrder = usePermission("SC_UpdateSaleServiceOrder");
  const canPublishOrder = usePermission("SC_PublishSaleServiceOrder");
  const incidentActive = usePermission(
    "CMS_IncidentFullAccess",
    "CMS_GetIncident"
  );
  const statusHistoryVariantRef = useRef<
    | "salesStatuses"
    | "salesDocumentStatuses"
    | "purchaseStatuses"
    | "purchaseDocumentStatuses"
  >("salesStatuses");
  const { orderId } = useParams();
  const { axios, loading } = useAxios();
  const { axios: orderAxios, loading: orderLoading } = useAxios();
  const { convertDate, convertAmount } = useConverters();
  const [order, setOrder] = useState<OrderData | null>(null);
  const [customer, setCustomer] = useState<
    (Customer.Details & Company.Details) | null
  >(null);
  const [unsignedDocuments, setUnsignedDocuments] = useState(false);
  const [lineItemsHasShippingMethod, setLineItemsHasShippingMethod] =
    useState(false);
  const [showEditOrder, toggleEditOrder] = useToggle(false);
  const [showPublishOrder, togglePublishOrder] = useToggle(false);
  const [showChangeOrderStatus, toggleChangeOrderStatus] = useToggle(false);
  const [showStatusHistory, toggleStatusHistory] = useToggle(false);
  const hasData = !!order;
  const isEnabled = true;
  const isPublished = !!order?.lastPublishedVersion;
  const isLastVersion = order?.lastPublishedVersion === order?.version;
  const moreInfo: MoreInfoItem[] = [
    {
      icon: "Calendar",
      label: "global.lastUpdate",
      value: convertDate(order?.createdAt),
    },
    {
      icon: "Hierarchy2",
      label: "global.orderVersion",
      value: order?.version,
    },
    // {
    //   icon: "Calendar",
    //   label: "global.orderDeadline",
    //   fullWidth: true,
    //   value: (
    //     <>
    //       {convertDate(order?.customerConfirmationDeadline) ?? "--"}{" "}
    //       {order?.pendingCustomerConfirmation ? (
    //         <b className="font-semibold text-warning">
    //           <Text>global.orderPendingCustomerConfirmation</Text>{" "}
    //           <Icon name="InfoCircle" variant="Bulk" />
    //         </b>
    //       ) : (
    //         <b className="font-semibold text-success">
    //           <Text>global.orderConfirmedCustomerConfirmation</Text>{" "}
    //           <Icon name="TickCircle" variant="Bulk" />
    //         </b>
    //       )}
    //     </>
    //   ),
    // },
  ];
  const publishedItems: MoreInfoItem[] = [
    {
      icon: "Calendar",
      label: "global.orderLastPublishDate",
      value: convertDate(order?.lastPublishedDate) ?? "--",
    },
    {
      icon: "Hierarchy2",
      label: "global.orderLastPublishedVersion",
      value: order?.lastPublishedVersion ?? "--",
    },
    {
      icon: "User",
      label: "global.orderLastPublishedBy",
      value: order?.lastPublishedByName || "--",
    },
  ];
  const initTabs = [
    {
      label: "tabs.lineItems",
      id: "0",
      component: LineItems,
      active: true,
    },
    {
      label: "tabs.notes",
      id: "4",
      component: Notes,
      active: true,
    },
    {
      label: "tabs.logistic",
      id: "1",
      component: Logistic,
      active: true,
    },
    {
      label: "tabs.billing",
      id: "2",
      component: Billing,
      active: true,
    },
    {
      label: "tabs.orderDocuments",
      id: "3",
      component: Documents,
      active: true,
    },
    {
      label: "tabs.orderIncidents",
      id: "5",
      component: Incidents,
      active: incidentActive,
    },
    {
      label: "tabs.orderSalesInfo",
      id: "6",
      component: SalesInfo,
      active: true,
    },
  ];
  const tabs = initTabs.filter((e) => e.active);
  const handleToggleHistory = (key: typeof statusHistoryVariantRef.current) => {
    if (!isPublished) return undefined;
    return () => {
      statusHistoryVariantRef.current = key;
      toggleStatusHistory();
    };
  };
  const updateOrder = async () => {
    // setOrderLoading(true);
    const url = `/salesservice/api/orders/${orderId}`;
    // await sleep(1500);
    return await orderAxios
      .get(url)
      .then(({ data }) => {
        setOrder(data);
        return data as Order.Details;
      })
      .catch(() => null);
    // .finally(() => {
    //   setOrderLoading(false);
    // });
  };
  const getOrder = () => {
    const url = `/salesservice/api/orders/${orderId}`;
    axios.get(url).then(({ data }) => {
      const isCompany = data.customer?.customerType === CustomerType.Company;
      setOrder(data);
      getCustomer(data?.customer?.customerId, isCompany);
      getUnsignedDocument(data.id);
      getLineItems(data.id);
    });
  };
  const getCustomer = (id: string, isCompany: boolean) => {
    if (!id) return;
    const url = isCompany
      ? `/accountservice/api/customers/${id}/as-company`
      : `/accountservice/api/customers/${id}`;
    axios.get(url).then(({ data }) => {
      setCustomer(data);
    });
  };
  const getUnsignedDocument = (id: string) => {
    const url = `/salesservice/api/orderdocuments/${id}/unsigned-mandatory-document`;
    axios.get(url).then(({ data }) => {
      setUnsignedDocuments(!!data);
    });
  };
  const getLineItems = (id: string) => {
    const url = "/salesservice/api/orderlineitems";
    const config = { params: { orderId: id } };
    axios.get(url, config).then(({ data }) => {
      const result: LineItem.Item[] = data || [];
      const hasLineItems = !!result.length;
      const hasShipping = result
        .filter((e) => !e.isCanceled)
        .every((e) => Boolean(e.shippingMethodCode));
      const lineItemsHasShippingMethod = !hasLineItems || hasShipping;
      setLineItemsHasShippingMethod(lineItemsHasShippingMethod);
    });
  };
  useEffect(getOrder, [orderId]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <BackButton to="/orders" className="mr-auto" />
          {!loading.get && canEditOrder && (
            <Button
              type="button"
              variant="primary"
              light
              onClick={toggleChangeOrderStatus}
              className="text-sm flex items-center gap-1 px-2"
            >
              <Icon name="Chart" variant="Outline" size={18} />
              <Text>button.status</Text>
            </Button>
          )}
          {!loading.get && canPublishOrder && (
            <Button
              type="button"
              variant="success"
              onClick={togglePublishOrder}
            >
              <Text>
                {isPublished ? "button.republishOrder" : "button.publishOrder"}
              </Text>
            </Button>
          )}
        </Layout.Header>
        <Layout.Body className="grid grid-cols-1 lg:grid-cols-5 gap-3 [&>*]:col-span-full">
          {loading.get ? (
            <Loading.Header />
          ) : (
            <Fragment>
              {order?.internalState.internalStatus ===
                OrderInternalStatus.Blocked && (
                <Wrapper className="bg-danger bg-opacity-10">
                  <Wrapper.Body className="flex flex-wrap items-center gap-4 py-3">
                    <div className="min-w-fit flex-1 flex flex-wrap items-center gap-4">
                      <p className="text-sm font-semibold text-danger flex items-center gap-1">
                        <Icon name="Chart" variant="Bulk" size={24} />{" "}
                        <Text>alertTitles.orderStatus</Text>:{" "}
                        <Text>alertTitles.blockedOrder</Text> (
                        {order.internalState?.reason ?? "--"})
                      </p>
                      <p className="text-sm font-semibold text-danger flex items-center gap-1">
                        <Icon name="CalendarRemove" variant="Bulk" size={24} />{" "}
                        <Text>alertTitles.orderExpireAt</Text>:{" "}
                        {convertDate(order.internalState?.expireAt)}
                      </p>
                    </div>
                    {canEditOrder && (
                      <Button
                        type="button"
                        variant="dark"
                        outline
                        onClick={toggleChangeOrderStatus}
                        className="flex items-center justify-center gap-2 text-sm"
                      >
                        <Icon name="Refresh" size={19} />
                        <Text>button.change</Text>
                      </Button>
                    )}
                  </Wrapper.Body>
                </Wrapper>
              )}
              <Wrapper>
                <Wrapper.Body className="flex flex-wrap items-center gap-3">
                  {publishedItems.map((e) => (
                    <p key={e.label} className="text-sm text-secondary">
                      <Icon
                        name={e.icon}
                        variant="Bulk"
                        className="text-primary"
                      />{" "}
                      <Text>{e.label}</Text>: {e.value}
                    </p>
                  ))}
                  {isPublished && !isLastVersion && (
                    <Fragment>
                      <span className="flex-1" />
                      <Alert
                        variant="warning"
                        light
                        className="w-fit border-none rounded-full py-2"
                        IconProps={{ className: "size-4", variant: "Bulk" }}
                      >
                        <Alert.Text className="text-xs text-warning">
                          <Text>toast.warning.informedOrderVersion</Text>
                        </Alert.Text>
                      </Alert>
                    </Fragment>
                  )}
                  <div className="flex items-center flex-wrap gap-3">
                    <Status.Sales
                      statuses={order?.salesStatuses}
                      title="page.orders.statusTitle.sales"
                      // className="text-[#E4A427] bg-gray-50 bg-opacity-100"
                      isOrderPublished={isPublished}
                      className={isPublished ? "cursor-pointer" : undefined}
                      onClick={handleToggleHistory("salesStatuses")}
                    />
                    {isPublished && (
                      <Fragment>
                        <Status.Document
                          statuses={order?.salesDocumentStatuses}
                          title="page.orders.statusTitle.salesDocument"
                          className={isPublished ? "cursor-pointer" : undefined}
                          onClick={handleToggleHistory("salesDocumentStatuses")}
                          // className="text-[#B578B6] bg-gray-50 bg-opacity-100"
                        />
                        <Status.Purchase
                          statuses={order?.purchaseStatuses}
                          title="page.orders.statusTitle.purchase"
                          className={isPublished ? "cursor-pointer" : undefined}
                          onClick={handleToggleHistory("purchaseStatuses")}
                          // className="text-[#3F72F5] bg-gray-50 bg-opacity-100"
                        />
                        <Status.Document
                          statuses={order?.purchaseDocumentStatuses}
                          title="page.orders.statusTitle.purchaseDocument"
                          className={isPublished ? "cursor-pointer" : undefined}
                          onClick={handleToggleHistory(
                            "purchaseDocumentStatuses"
                          )}
                          // className="text-[#B578B6] bg-gray-50 bg-opacity-100"
                        />
                      </Fragment>
                    )}
                  </div>
                </Wrapper.Body>
              </Wrapper>
              <Wrapper className="lg:!col-span-3">
                <Wrapper.Body className="flex flex-wrap items-start gap-4">
                  <div className="flex-1 flex flex-col gap-3">
                    <section className="flex items-center gap-2">
                      <Icon.Wrapper rounded>
                        <Icon name="ReceiptText" variant="Bold" />
                      </Icon.Wrapper>
                      <button
                        type="button"
                        className="text-start"
                        onClick={toggleEditOrder}
                      >
                        <h1 className="text-base text-dark">
                          {order?.title}{" "}
                          {canEditOrder && (
                            <Icon
                              name="Edit2"
                              size="1em"
                              className="text-primary"
                            />
                          )}
                        </h1>
                        <p className="text-sm text-secondary">
                          #{order?.number}
                        </p>
                      </button>
                    </section>
                    <section className="flex flex-wrap items-center gap-x-3 gap-y-2">
                      {moreInfo.map((e) => (
                        <p
                          key={e.label}
                          className={twMerge(
                            "text-sm",

                            !!e.fullWidth && "basis-full"
                          )}
                        >
                          <Icon
                            name={e.icon}
                            variant="Bulk"
                            className="text-primary"
                          />{" "}
                          <span className="text-placeholder">
                            <Text>{e.label}</Text>:
                          </span>{" "}
                          <span className="text-secondary">{e.value}</span>
                        </p>
                      ))}
                    </section>
                    <section className="flex items-center gap-3 mt-auto">
                      <span className="bg-gray-50 px-2 py-1 rounded-md text-base md:me-auto">
                        <Icon
                          name="DollarSquare"
                          variant="Bold"
                          size={22}
                          className="text-dark"
                        />{" "}
                        <Text>global.totalPrice</Text>:{" "}
                        {convertAmount(order?.totalAmount)}
                      </span>
                    </section>
                  </div>
                </Wrapper.Body>
              </Wrapper>
              <CustomerCard customer={customer} className="lg:!col-span-2" />
              <Tabs activeKey={tabs[0].id}>
                <Wrapper className="col-span-full">
                  <Wrapper.Body className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Body>
                </Wrapper>
                {hasData && (
                  <Fragment>
                    <OrderDetailsContext.Provider
                      value={{
                        order,
                        setOrder,
                        updateOrder,
                        updateOrderLoading: orderLoading.get,
                        customer,
                        setUnsignedDocuments,
                        setLineItemsHasShippingMethod,
                        isEnabled: isEnabled && canEditOrder,
                        isPublished,
                      }}
                    >
                      {tabs.map((e) => (
                        <Tabs.Item key={e.id} eventKey={e.id}>
                          {createElement(e.component)}
                        </Tabs.Item>
                      ))}
                      {canEditOrder && (
                        <EditOrder
                          isOpen={showEditOrder}
                          toggle={toggleEditOrder}
                        />
                      )}
                      {canPublishOrder && (
                        <PublishOrder
                          isOpen={showPublishOrder}
                          toggle={togglePublishOrder}
                          signedDocuments={!unsignedDocuments}
                          lineItemsHasShippingMethod={
                            lineItemsHasShippingMethod
                          }
                        />
                      )}
                      {canEditOrder && (
                        <ChangeOrderStatus
                          isOpen={showChangeOrderStatus}
                          toggle={toggleChangeOrderStatus}
                          onSubmitted={getOrder}
                        />
                      )}
                      <ErpStatusHistory
                        isOpen={showStatusHistory}
                        toggle={toggleStatusHistory}
                        variant={statusHistoryVariantRef.current}
                        order={order}
                      />
                    </OrderDetailsContext.Provider>
                  </Fragment>
                )}
              </Tabs>
            </Fragment>
          )}
        </Layout.Body>
      </Layout>
    </Fragment>
  );
}
export default withPermission(OrderDetails, ["SC_GetSaleServiceOrder"]);
