import { ComponentProps, Fragment, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { Address } from "../types";
import Icon from "./_Icon";

type AddressViewerProps = {
  address?: Address | null;
  withIcon?: boolean;
  //   type?: "full" | "short";
};
export default function AddressViewer({
  address,
  className,
  withIcon = false,
  ...props
}: AddressViewerProps & Omit<ComponentProps<"p">, keyof AddressViewerProps>) {
  const value = useMemo(() => {
    if (!address) return "";
    const { name, street, number, postalCode, city, state, country } = address;
    return [
      [name, street, number],
      [postalCode, city],
      [state, country],
    ]
      .map((e) => e.filter(Boolean).join(", "))
      .filter((e) => !!e.length)
      .join("\n");
  }, [address]);
  if (!address) return null;
  return (
    <p
      className={twMerge("leading-6 whitespace-pre-wrap", className)}
      {...props}
    >
      {withIcon && (
        <Fragment>
          <Icon name="LocationTick" className="text-danger" size={20} />{" "}
        </Fragment>
      )}
      {value}
    </p>
  );
}
