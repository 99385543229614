import { createContext, useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Text, Wrapper } from "../../../../components";
import { useAxios, usePermission } from "../../../../hooks";
import { SellOffProductDetailsContext } from "..";
import CurrentPhase from "./currentPhase";
import PreparationSalesTemplate from "./preparaationSalesTemplate";
import PriceTemplate from "./priceTemplate";
import SalesStatus from "./salesStatus";
import SaleProccessTimeline from "./saleProccessTimeline";

export const saleProccessContext = createContext<any>({});

const SaleProccessTab = () => {
  const phasePermission = usePermission("PS_GetPhase");
  const [timeLine, setTimeLine] = useState([]);
  const { axios } = useAxios();

  const { data, setChangePhase, setStartPrePhase, setClosePhase } = useContext(
    SellOffProductDetailsContext,
  );

  useEffect(() => {
    if (!isEmpty(data)) {
      const url = `productservice/api/selloffproducttimelines/${data.sellOffProductId}`;
      axios.get(url).then(({ data }) => {
        setTimeLine(data.sellOffProductTimelineItems);
      });
    }
  }, [data]);

  const isSold = !!data?.isSold;
  const active = !!data?.currentPhaseDay;

  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-2 space-y-6">
        {isSold || active ? (
          <saleProccessContext.Provider
            value={{ data, setChangePhase, setStartPrePhase, setClosePhase }}
          >
            <CurrentPhase />
            <PriceTemplate />
            <SalesStatus />
          </saleProccessContext.Provider>
        ) : (
          <PreparationSalesTemplate data={data} />
        )}
      </div>
      <div className="col-span-1">
        {phasePermission && (
          <Wrapper>
            <Wrapper.Header className="text-base font-semibold">
              <Text>
                sellOffProducts.salesTimeline
              </Text>
            </Wrapper.Header>
            <Wrapper.Body>
              <SaleProccessTimeline timeLine={timeLine} />
            </Wrapper.Body>
          </Wrapper>
        )}
      </div>
    </div >
  );
};

export default SaleProccessTab;
