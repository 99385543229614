import {
  createContext,
  ReactNode,
  TransitionEvent,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import Icon from "./_Icon";
type eventKeyType = string | null;
type accordionProps = {
  children: ReactNode;
  defaultActiveKey?: eventKeyType;
};
type accordionItemProps = {
  className?: string;
  eventKey?: eventKeyType;
  children?: ReactNode;
};
type accordionToggleProps = {
  id?: string;
  className?: string;
  children?: ReactNode;
};
type accordionBodyProps = {
  className?: string;
  children?: ReactNode;
};
type accordionContextType = {
  activeKey: eventKeyType;
  setActiveKey: (val: eventKeyType) => void;
};
type accordionItemContextType = {
  eventKey: eventKeyType;
  isActive: boolean;
};
const AccordionContext = createContext({} as accordionContextType);
const AccordionItemContext = createContext({} as accordionItemContextType);

function Accordion({
  children = null,
  defaultActiveKey = null,
}: accordionProps) {
  const [activeKey, setActiveKey] = useState<eventKeyType>(defaultActiveKey);
  return (
    <AccordionContext.Provider value={{ activeKey, setActiveKey }}>
      {children}
    </AccordionContext.Provider>
  );
}
function AccordionItem({
  className = "",
  eventKey = null,
  children,
}: accordionItemProps) {
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const key = eventKey ?? id;
  const { activeKey } = useContext(AccordionContext);
  const isActive: boolean = activeKey === key;
  useEffect(() => {
    ref.current?.classList.toggle("active", isActive);
  }, [isActive]);
  return (
    <AccordionItemContext.Provider value={{ eventKey: key, isActive }}>
      <div
        ref={ref}
        className={`accordion-item w-full border-b border-gray-100 ${className}`}
      >
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
}
function AccordionToggle({
  id,
  className = "",
  children = null,
}: accordionToggleProps) {
  const { setActiveKey } = useContext(AccordionContext);
  const { eventKey, isActive } = useContext(AccordionItemContext);
  const handleSetActiveKey = () => {
    if (isActive) return setActiveKey(null);
    setActiveKey(eventKey);
  };
  return (
    <button
      id={id}
      type="button"
      data-active={isActive}
      onClick={handleSetActiveKey}
      className={`w-full flex items-center gap-4 py-5 font-bold overflow-hidden ${className}`}
    >
      {children}
      <Icon
        name="ArrowDown2"
        data-active={isActive}
        className="transition-transform ml-auto data-active:rotate-180"
      />
    </button>
  );
}
function AccordionBody({
  className = "",
  children = null,
}: accordionBodyProps) {
  const collapseRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const { isActive } = useContext(AccordionItemContext);
  const handleTransition = ({
    currentTarget,
  }: TransitionEvent<HTMLDivElement>) => {
    currentTarget.style.height = isActive ? "auto" : "0";
  };
  useEffect(() => {
    const collapse = collapseRef.current;
    const body = bodyRef.current;
    if (!body || !collapse) return;
    const rect = body.getBoundingClientRect();
    const height = rect.height;
    collapse.style.height = `${height}px`;
    if (!isActive) {
      setTimeout(() => {
        collapse.style.height = "0px";
      }, 0);
    }
  }, [isActive]);
  return (
    <div
      ref={collapseRef}
      onTransitionEnd={handleTransition}
      data-active={isActive}
      className={`w-full h-0 data-active:h-auto overflow-hidden transition-[height] ${className}`}
    >
      <div ref={bodyRef} className="h-fit py-2">
        {children}
      </div>
    </div>
  );
}

Accordion.Item = AccordionItem;
Accordion.Toggle = AccordionToggle;
Accordion.Body = AccordionBody;

export default Accordion;
