import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { LineItemsContext } from "..";
import { OfferDetailsContext } from "../..";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Icon,
  Loading,
  Table,
  Text,
  Wrapper,
} from "../../../../../components";
import { useAxios, useDefaultSaleChannel } from "../../../../../hooks";
import { LineItem, Product } from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";

type ProductInventoryProps = {
  force: boolean;
  lineItem: LineItem.Item;
};

type Data = Product.InventoryItem[];

export default function ProductInventory({
  force,
  lineItem,
}: ProductInventoryProps) {
  const { i18n } = useTranslation();
  const { axios, loading } = useAxios();
  const salesChannel = useDefaultSaleChannel();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const { setLineItems } = useContext(LineItemsContext);

  const [data, setData] = useState<Data>([]);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const hasErrorCode = !!errorCode;
  const errorMessage = `errorCodes.${errorCode}`;
  const hasErrorMessage = i18n.exists(errorMessage);
  const isOffer = !!offer;
  const isOrder = !!order;

  const hasArticleNumber = !!lineItem.product?.articleNumber;
  const hasLegalEntityCode = !!salesChannel?.legalEntity?.code;
  const isInventoryAvailable = hasArticleNumber && hasLegalEntityCode;
  const isReserved = !!lineItem.reservationId;
  const hasInventory = !!data.length;

  const inventories = useMemo(() => {
    if (isReserved)
      return data.filter(
        (e) => e.dimensions.locationid === lineItem.reservationLocationId
      );
    return data;
  }, [data, isReserved, lineItem.reservationLocationId]);

  const getData = () => {
    if (!force || !isInventoryAvailable) return;
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      lineItem.id,
      "product-inventory",
    ]
      .filter(Boolean)
      .join("/");
    const config = { error: false };
    axios
      .get(url, config)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        const code =
          err?.response?.data?.detail || err?.response?.data?.title || "14316";
        setErrorCode(code);
      });
  };

  const reserve = (locationId: string) => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      lineItem.id,
      "product-inventory",
      "reserve",
    ]
      .filter(Boolean)
      .join("/");
    const config = { params: { locationId } };
    const body = { id: lineItem.id };
    axios.post(url, body, config).then(({ data }) => {
      const result = data as { locationId: string; reservationId: string };
      toast.success("toast.success.lineItemReserve");
      setLineItems((p) => {
        const lineItems = cloneDeep(p);
        const index = lineItems.findIndex((e) => e.id === lineItem.id);
        const has = index !== -1;
        if (has) {
          lineItems[index].reservationId = result.reservationId;
          lineItems[index].reservationLocationId = result.locationId;
        }
        return lineItems;
      });
      getData();
    });
  };
  const unreserve = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      lineItem.id,
      "product-inventory",
      "unreserve",
    ]
      .filter(Boolean)
      .join("/");
    axios.post(url).then(() => {
      toast.success("toast.success.lineItemUnreserve");
      setLineItems((p) => {
        const lineItems = cloneDeep(p);
        const index = lineItems.findIndex((e) => e.id === lineItem.id);
        const has = index !== -1;
        if (has) {
          lineItems[index].reservationId = null;
          lineItems[index].reservationLocationId = null;
        }
        return lineItems;
      });
      getData();
    });
  };

  useEffect(getData, [
    force,
    hasArticleNumber,
    hasLegalEntityCode,
    isInventoryAvailable,
    isOffer,
    isOrder,
    lineItem.id,
  ]);
  return (
    <Wrapper>
      <Accordion defaultActiveKey="">
        <Accordion.Item>
          <div className="flex items-center">
            <Accordion.Toggle className="flex-row-reverse">
              <h6 className="flex-1 text-sm text-dark text-start">
                <Text>global.lineItemProductInventoryTitle</Text>:
              </h6>
            </Accordion.Toggle>
            {isInventoryAvailable && !loading.get && (
              <Button
                type="button"
                variant="primary"
                light
                className="p-1"
                onClick={getData}
              >
                <Icon name="RefreshSquare" variant="Bold" />
              </Button>
            )}
          </div>
          <Accordion.Body>
            {loading.get ? (
              <Loading.ProductInventory />
            ) : (
              <Fragment>
                {hasErrorCode ? (
                  <Wrapper.Body>
                    <Alert variant="danger" light>
                      <Alert.Text>
                        <Text>errorCodes.lineItemInventory</Text>.
                      </Alert.Text>
                      {hasErrorMessage && (
                        <Alert.Text>
                          <Text>{errorMessage}</Text>
                        </Alert.Text>
                      )}
                    </Alert>
                  </Wrapper.Body>
                ) : (
                  <Fragment>
                    {isInventoryAvailable && hasInventory ? (
                      <Fragment>
                        <Wrapper.Body className="p-0">
                          <Table>
                            <tbody className="w-full text-sm text-secondary [&_td]:!px-0">
                              {inventories.map((e, i) => {
                                const quantity = lineItem.quantity;
                                const availabletoreserve =
                                  e.quantities?.iv?.availabletoreserve ?? 0;
                                const isAvailable = !!availabletoreserve;
                                const isMoreThanAvailable =
                                  quantity > availabletoreserve;
                                return (
                                  <tr key={i}>
                                    <td className="uppercase">
                                      <Icon name="Hashtag" variant="Bold" />{" "}
                                      {e.dimensions.locationid}
                                    </td>
                                    <td
                                      className={
                                        isAvailable ? "to-dark" : "text-danger"
                                      }
                                    >
                                      {!isReserved
                                        ? `x${availabletoreserve}`
                                        : null}
                                    </td>
                                    <td>
                                      {isReserved ? (
                                        <Badge
                                          variant="success"
                                          className="px-2 py-1"
                                        >
                                          <Icon name="TickCircle" />{" "}
                                          <Text>global.lineItemIsReserved</Text>
                                        </Badge>
                                      ) : (
                                        <Button
                                          type="button"
                                          variant="primary"
                                          light
                                          onClick={() =>
                                            reserve(e.dimensions.locationid)
                                          }
                                          loading={loading.post}
                                          disabled={isMoreThanAvailable}
                                          className="px-2 py-1 disabled:bg-[#E1E1E7] disabled:text-placeholder"
                                        >
                                          <Text>
                                            global.lineItemProductInventoryReserve
                                          </Text>
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                              {isReserved && (
                                <tr>
                                  <td>
                                    <Icon name="Reserve" variant="Bold" />{" "}
                                    <Text>
                                      global.lineItemReservationNumber
                                    </Text>
                                  </td>
                                  <td></td>
                                  <td>#{lineItem.reservationId}</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </Wrapper.Body>
                        {isReserved && (
                          <Wrapper.Footer className="flex-center">
                            <Button
                              type="button"
                              variant="danger"
                              light
                              onClick={unreserve}
                              loading={loading.post}
                              className="bg-transparent border-transparent"
                            >
                              <Icon name="CloseCircle" />{" "}
                              <Text>
                                global.lineItemProductInventoryUnreserve
                              </Text>
                            </Button>
                          </Wrapper.Footer>
                        )}
                      </Fragment>
                    ) : (
                      <Wrapper.Body>
                        <Alert variant="warning" light>
                          <Alert.Text>
                            <Text>
                              global.lineItemProductInventoryNoAvailable
                            </Text>
                          </Alert.Text>
                        </Alert>
                      </Wrapper.Body>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Wrapper>
  );
}
