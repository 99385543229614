import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import checkIcon from "../../../assets/icons/check.svg";
import { Button, Form, Icon, Layout, Text, Wrapper } from "../../../components";
import { types } from "../../../constants";
import { CountryCode } from "../../../enums";
import { withPermission } from "../../../hoc";
import {
  useAxios,
  useHasAddress,
  useToggle,
  useValidateAddress,
} from "../../../hooks";
import { Company } from "../../../types";
import BasicInfo from "./BasicInfo";
import Completed from "./Completed";
import ContactInfo from "./ContactInfo";
import Location from "./Location";
import TermsConditions from "./TermsConditions";
type ContextType = {
  data: Company.Create;
  setData: Dispatch<SetStateAction<Company.Create>>;
  hasAddress: boolean;
  hasPhoneNumber: boolean;
};
export const AddCompanyContext = createContext({} as ContextType);
function AddNewCustomer() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const [showTermModal, toggleTermModal] = useToggle(false);
  const [validateAddress, validateLoading] = useValidateAddress();
  const steps = [
    {
      title: "company.create.tab.basicInfo",
      text: "company.create.tab.basicInfoDescription",
      component: BasicInfo,
    },
    {
      title: "company.create.tab.contactInfo",
      text: "company.create.tab.contactInfoDescription",
      component: ContactInfo,
    },
    {
      title: "company.create.tab.location",
      text: "company.create.tab.locationDescription",
      component: Location,
    },
    {
      title: "company.create.tab.completed",
      text: "company.create.tab.completedDescription",
      component: Completed,
    },
  ].map((e, id) => ({ ...e, id }));
  const initData: Company.Create = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobileNumber: { countryCode: CountryCode.De, number: NaN },
    phoneNumber: { countryCode: CountryCode.De, number: NaN },
    position: "",
    salutation: types.salutation[0].id,
    salutationTitle: null,
    gender: types.gender[0].id,
    birthdate: null,
    address: {
      city: null,
      location: null,
      name: null,
      number: null,
      postalCode: null,
      state: null,
      street: null,
      country: null,
      countryCode: null,
      countryCodeISO3: null,
      countrySubdivision: null,
      countrySubdivisionName: null,
    },
    taxId: "",
    companyName: "",
    companyRegistrationNumber: "",
    gdprAccepted: false,
    eligibleForTaxDeduction: false,
    companyRegistrationNumberApproved: false,
    personPowerOfAttorneyApproved: false,
  };

  const [data, setData] = useState(initData);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const ActiveStep = steps[activeStepIndex].component;
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === steps.length - 1;
  const isLocationStep = activeStepIndex === 2;
  const hasPhoneNumber = !!data.phoneNumber?.number;
  const hasAddress = useHasAddress(data.address);
  const goToNextStep = () => {
    setActiveStepIndex((p) => p + 1);
  };
  const goToPrevStep = () => {
    setActiveStepIndex((p) => p - 1);
  };
  const submitNextStep = () => {
    if (!isLocationStep || !hasAddress) return goToNextStep();
    validateAddress(data.address).then(({ isValid, suggested }) => {
      if (isValid) return goToNextStep();
      if (!suggested) return toast.error("errorCodes.inValidAddress");
      toast("toast.warning.suggestedAddress");
      setData((p) => ({ ...p, address: suggested }));
    });
  };

  const submit = () => {
    const url = "/accountservice/api/customers/as-company";
    const body = {
      ...data,
      ...(!hasAddress && { address: null }),
      ...(!hasPhoneNumber && { phoneNumber: null }),
    };
    axios.post(url, body).then(({ data: id }) => {
      const message = "company.create.successMessage";
      toast.success(message);
      navigate(`/companies/${id}`, { replace: true });
    });
  };

  return (
    <Fragment>
      <Layout
        as={Form}
        onSubmit={isLastStep ? toggleTermModal : submitNextStep}
      >
        <Layout.Header>
          <h6 className="mr-auto">
            <Text>company.create.title</Text>
          </h6>
          {isFirstStep ? (
            <Link to="..">
              <Button variant="danger">
                <Text>company.create.cancelBtn</Text>
              </Button>
            </Link>
          ) : (
            <Button light variant="primary" onClick={goToPrevStep}>
              <Text>company.create.backBtn</Text>
            </Button>
          )}
          {isLastStep ? (
            <Button type="submit" variant="success">
              <Text>company.create.submitBtn</Text>
            </Button>
          ) : (
            <Button type="submit" loading={validateLoading}>
              <Text>company.create.nextBtn</Text>
            </Button>
          )}
        </Layout.Header>
        <Layout.Body className="space-y-4">
          <Wrapper>
            <Wrapper.Body className="flex-center gap-4 py-10">
              {steps.map((e) => (
                <Fragment key={e.id}>
                  <div
                    data-active={e.id === activeStepIndex}
                    data-prev={e.id < activeStepIndex || undefined}
                    className="group flex-center gap-4"
                    onClick={() => {
                      e.id < activeStepIndex && setActiveStepIndex(e.id);
                      // setActiveStepIndex(e.id);
                    }}
                  >
                    <div className="flex-center text-xl bg-primary/10 text-primary w-10 h-10 rounded transition-colors group-data-active:bg-primary group-data-active:text-white">
                      <img
                        src={checkIcon}
                        alt={e.title}
                        className="hidden group-data-[prev]:inline-block w-4 h-4"
                      />
                      <span className="inline-flex group-data-[prev]:hidden">
                        {e.id + 1}
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="truncate">
                        <Text>{e.title}</Text>
                      </h6>
                      <span className="truncate block text-secondary text-sm">
                        <Text>{e.text}</Text>
                      </span>
                    </div>
                  </div>
                  <div
                    data-active={e.id <= activeStepIndex}
                    className="group w-7 h-7 [&>*]:w-full [&>*]:h-full [&>*]:object-contain last:hidden"
                  >
                    <Icon
                      name="ArrowRight"
                      variant="TwoTone"
                      className="text-placeholder group-data-active:text-primary"
                      size={28}
                    />
                  </div>
                </Fragment>
              ))}
            </Wrapper.Body>
          </Wrapper>
          <AddCompanyContext.Provider
            value={{
              data,
              setData,
              hasAddress,
              hasPhoneNumber,
            }}
          >
            <ActiveStep />
            <TermsConditions
              isOpen={showTermModal}
              toggle={toggleTermModal}
              loading={loading.post}
              onSubmit={submit}
            />
          </AddCompanyContext.Provider>
        </Layout.Body>
      </Layout>
    </Fragment>
  );
}
export default withPermission(AddNewCustomer, [
  "AS_CustomerFullAccess",
  "AS_CreateCustomer",
]);
