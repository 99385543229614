import { cloneDeep } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { OfferDetailsContext } from ".";
import {
  Button,
  CustomerAddressCard,
  Drawer,
  Form,
  InputGroup,
  Select,
  Text,
} from "../../../components";
import { rules } from "../../../constants";
import { SearchAddress } from "../../../containers";
import { AddressType } from "../../../enums";
import {
  useAxios,
  useCountries,
  useTranslate,
  useValidateAddress,
} from "../../../hooks";
import { Address, togglePropsType } from "../../../types";
import { CustomerAddress } from "../../../types/customer";
import { OrderDetailsContext } from "../../Orders/Details";

type AddressDrawerProps = {
  type: "billing" | "shipping";
} & togglePropsType;

export default function AddressDrawer({
  isOpen,
  toggle,
  type,
}: AddressDrawerProps) {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const updateOffer = offerContext?.updateOffer;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const order = orderContext?.order;
  const updateOrder = orderContext?.updateOrder;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const customer = offerContext?.customer || orderContext?.customer;

  const isOffer = !!offer;
  const isOrder = !!order;

  const shippingAddress = offer?.shippingAddress || order?.shippingAddress;
  const billingAddress = offer?.billingAddress || order?.billingAddress;

  const [countries, countriesLoading] = useCountries(isOpen);
  const [validateAddress, validateLoading] = useValidateAddress();
  const { axios, loading } = useAxios();
  const customerHasAddress = !!customer?.address;
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const translate = useTranslate();
  const isShipTo = type === "shipping";
  const label = isShipTo ? "drawerTitles.shipTo" : "drawerTitles.billTo";
  const hasLoading = [
    loading.post,
    validateLoading,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  const initData = useMemo(() => {
    if (isShipTo) return shippingAddress ?? ({} as Address);
    return billingAddress ?? ({} as Address);
  }, [shippingAddress, billingAddress]);
  const [address, setAddress] = useState<Address>(initData);
  const handleSetValue = (key: keyof Address) => {
    return (value: any) => {
      setAddress((p) => {
        const isCountryCode = key === "countryCode";
        const data = cloneDeep(p);
        data[key] = value;
        if (isCountryCode) {
          const country = countries.find((e) => e.id === data.countryCode);
          data.country = country?.name ?? null;
          data.countryCodeISO3 = country?.countryIso3 ?? null;
        }
        return data;
      });
    };
  };
  const setCustomerAddress = (address: Address) => {
    setAddress(address);
  };

  const submit = () => {
    validateAddress(address).then(({ isValid, suggested }) => {
      if (isValid) return submitAddress();
      if (!suggested) return toast.error("errorCodes.inValidAddress");
      toast("toast.warning.suggestedAddress");
      setAddress(suggested);
    });
  };

  const submitAddress = () => {
    const offerId = offer?.id;
    const orderId = order?.id;
    const url = [
      "/salesservice",
      "api",
      isOffer && "offer",
      isOrder && "orders",
      offerId || orderId,
      isShipTo ? "shiping-address" : "billing-address",
    ]
      .filter(Boolean)
      .join("/");

    const key = isShipTo ? "shippingAddress" : "billingAddress";
    const body = { [key]: address };
    axios.post(url, body).then(() => {
      updateOffer?.().then(() => {
        toast.success("toast.success.editOffer");
        toggle();
      });
      updateOrder?.().then(() => {
        toast.success("toast.success.editOrder");
        toggle();
      });
    });
  };
  const getCustomerAddresses = () => {
    if (!customer?.id || !isOpen) return;
    const url = `/accountservice/api/customers/${customer?.id}/address`;
    const config = {
      params: {
        AddressType: isShipTo ? AddressType.Shipping : AddressType.Billing,
      },
    };
    axios.get(url, config).then(({ data }) => {
      setCustomerAddresses(data);
    });
  };
  useEffect(getCustomerAddresses, [isOpen]);
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="space-y-2">
          <Text>{label}</Text>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          <div className="relative">
            <div className="pb-2 max-h-[450px] overflow-scroll space-y-3">
              {customerHasAddress && (
                <CustomerAddressCard
                  title={translate("global.customerAddress")}
                  address={customer?.address || ({} as Address)}
                  onClick={setCustomerAddress}
                />
              )}
              {customerAddresses.map((addressInfo: CustomerAddress) => (
                <CustomerAddressCard
                  key={addressInfo.id}
                  title={addressInfo.title}
                  address={addressInfo.address}
                  onClick={setCustomerAddress}
                  type={addressInfo.addressType}
                />
              ))}
            </div>
            {(customerAddresses.length > 3 ||
              (customerAddresses.length > 2 && customerHasAddress)) && (
              <div className="absolute left-0 bottom-0 w-full h-16 bg-gradient-to-t from-white border-b border-gray pointer-events-none"></div>
            )}
          </div>
          <SearchAddress
            setValue={(address) =>
              setAddress((p) => ({ ...p, ...address, name: p.name }))
            }
            countries={countries}
            loading={countriesLoading}
          />
          <span className="block w-full text-center text-sm text-secondary !my-6">
            <Text>global.orEnterManual</Text>
          </span>
          <InputGroup
            label="formControls.name"
            placeholder="placeholders.name"
            value={address.name}
            setValue={handleSetValue("name")}
            rules={rules.required}
          />
          <section className="flex gap-4">
            <div className="flex-[2]">
              <InputGroup
                label="formControls.street"
                placeholder="placeholders.street"
                value={address.street}
                setValue={handleSetValue("street")}
              />
            </div>
            <div className="flex-1">
              <InputGroup
                label="formControls.number"
                placeholder="placeholders.number"
                value={address.number}
                setValue={handleSetValue("number")}
                rules={rules.required}
              />
            </div>
          </section>
          <section className="flex items-start gap-4">
            <div className="flex-1">
              <InputGroup
                label="formControls.postalCode"
                placeholder="placeholders.postalCode"
                value={address.postalCode}
                setValue={handleSetValue("postalCode")}
                rules={rules.required}
              />
            </div>
            <div className="flex-1">
              <InputGroup
                label="formControls.city"
                placeholder="placeholders.city"
                value={address.city}
                setValue={handleSetValue("city")}
                rules={rules.required}
              />
            </div>
          </section>
          <section className="flex items-start gap-4">
            <div className="flex-1">
              <Select
                label="formControls.country"
                placeholder="placeholders.country"
                items={countries}
                value={address.countryCode}
                setValue={handleSetValue("countryCode")}
                rules={rules.required}
              />
            </div>
            <div className="flex-1">
              <InputGroup
                label="formControls.state"
                placeholder="placeholders.state"
                value={address.state}
                setValue={handleSetValue("state")}
                rules={rules.required}
              />
            </div>
          </section>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" loading={hasLoading}>
            <Text>button.submitChanges</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
