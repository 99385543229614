import { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ImportBasketContext } from ".";
import { OfferDetailsContext } from "../..";
import {
  Button,
  CheckBox,
  Drawer,
  Icon,
  List,
  Loading,
  Text,
} from "../../../../../components";
import { dateFormats } from "../../../../../constants";
import { useAxios, useConverters, useToggle } from "../../../../../hooks";
import { Basket, iconNameType } from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";
type headerDataItemType = {
  icon: iconNameType;
  label: string;
  value: any;
};
export default function BasketDetails() {
  const { axios, loading, progress } = useAxios();
  const { convertAmount, convertDate, convertNumber } = useConverters();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const { parentId, activeBasket, clearActiveBasketTag, toggle, onSubmitted } =
    useContext(ImportBasketContext);
  const [data, setData] = useState<Basket.LineItem[]>([]);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const isOffer = !!offer;
  const isOrder = !!order;
  const hasParentId = !!parentId;

  const [removeAfterImport, toggleRemoveAfterImport] = useToggle(false);
  const hasData = !!data.length;
  const listLoading = !hasData && loading.post;
  const updateLoading = updateOfferLoading || updateOrderLoading;
  const importLoading = [hasData && loading.post, updateLoading].some(Boolean);
  const handleProgress = updateLoading ? 100 : progress;
  const headerData: headerDataItemType[] = [
    {
      icon: "SidebarRight",
      label: "tableTitles.basketInternalTag",
      value: activeBasket?.internalTag,
    },
    {
      icon: "Document",
      label: "tableTitles.basketTag",
      value: activeBasket?.tag,
    },
    {
      icon: "Calendar2",
      label: "tableTitles.basketCreatedAt",
      value: convertDate(activeBasket?.stamp, dateFormats.fullDate),
    },
    {
      icon: "LocationTick",
      label: "tableTitles.basketSenderName",
      value: `${activeBasket?.senderName} (ILN: ${activeBasket?.iln})`,
    },
    // {
    //   icon: "DollarSquare",
    //   label: "tableTitles.basketPrice",
    //   value: (
    //     <Badge variant="primary">{convertAmount(activeBasket?.price)}</Badge>
    //   ),
    // },
  ];
  const getData = () => {
    const url = `/salesservice/api/iwofurnconfigurations/iwofurn-basket-lines/${activeBasket?.internalTag}`;
    axios.post(url).then(({ data }) => {
      setData(data.lineItems);
    });
  };
  const submitImport = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      !hasParentId && "import-from-iwofurn",
      hasParentId && isOffer && "add-iwofurn-basket-to-offer-manual-bundle",
      hasParentId && isOrder && "add-iwofurn-basket-to-order-manual-bundle",
    ]
      .filter(Boolean)
      .join("/");
    const body = {
      parentId: parentId,
      offerId: offer?.id,
      orderId: order?.id,
      shippingMethodCode: null,
      internalTag: activeBasket?.tag,
      deleteAfterImport: removeAfterImport,
    };
    axios.post(url, body).then(() => {
      (updateOffer ?? updateOrder)?.().then(() => {
        toast.success("toast.success.importBasket");
        onSubmitted();
        toggle();
      });
    });
  };
  useEffect(getData, []);
  return (
    <Fragment>
      {importLoading && <Loading.ImportProduct value={handleProgress} />}
      <Drawer.Header className="space-y-3 w-full text-end">
        {headerData.map((e) => (
          <div
            key={e.label}
            className="flex items-center gap-4 whitespace-nowrap"
          >
            <p className="text-start text-secondary truncate flex-1">
              <Icon name={e.icon} variant="Bold" size={16} />{" "}
              <Text>{e.label}</Text>:
            </p>
            <p className="text-end text-gray-900">{e.value}</p>
          </div>
        ))}
      </Drawer.Header>
      {/* <Drawer.Header className="border-b-0">
        <SearchBox variant="gray" onSubmit={handleSetParams("keyword")} />
      </Drawer.Header> */}
      <Drawer.Body>
        <List loading={listLoading}>
          {data.map((e, i) => {
            const key = [e.lineName, e.program, e.supplier, i].join("-");
            return (
              <List.Item key={key} className="py-4">
                <Icon.Wrapper className="self-start">
                  <Icon name="Box" variant="Bold" />
                </Icon.Wrapper>
                <div className="flex-1 space-y-[2px]">
                  <h6 className="block text-gray-900">{e.lineName}</h6>
                  <p className="text-sm truncate text-secondary">
                    #{e.articleNumber}
                  </p>
                  <p className="text-sm text-secondary">
                    <Icon name="Document" variant="Bold" size={14} />{" "}
                    <Text>tableTitles.lineItemProgramCode</Text>:{" "}
                    <span className="text-gray-900">{e.program}</span>
                  </p>
                  <p className="text-sm text-secondary">
                    <Icon name="Building" variant="Bold" size={14} />{" "}
                    <Text>tableTitles.lineItemSupplier</Text>:{" "}
                    <span className="text-gray-900">{e.supplier}</span>
                  </p>
                  <p className="text-sm text-secondary">
                    <Icon name="Building" variant="Bold" size={14} />{" "}
                    <Text>tableTitles.lineItemSupplierILN</Text>:{" "}
                    <span className="text-gray-900">{e.supplierIln}</span>
                  </p>
                </div>
                <span className="px-4">X{convertNumber(e.quantity)}</span>
                {/* <span>{convertAmount(e.price.amount)}</span> */}
              </List.Item>
            );
          })}
        </List>
      </Drawer.Body>
      <Drawer.Footer className="flex items-center justify-end gap-4">
        <CheckBox
          label="formControls.removeBasketAfterImport"
          value={removeAfterImport}
          setValue={toggleRemoveAfterImport}
          className="w-auto flex-1"
        />
        <Button type="button" variant="danger" onClick={clearActiveBasketTag}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={submitImport}
          disabled={listLoading || importLoading}
        >
          <Text>button.importBasket</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
