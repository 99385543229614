import SellOffProductDetails from "../pages/SellOffProducts/Details";
import SellOffProductsList from "../pages/SellOffProducts/List";

const sellOffProducts = [
  {
    path: "sellOffProducts",
    element: <SellOffProductsList />,
  },
  {
    path: "sellOffProducts/:sellOffProductId",
    element: <SellOffProductDetails />,
  },
];
export default sellOffProducts;
