import { cloneDeep } from "lodash";
import { Fragment, useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  BackButton,
  Button,
  Form,
  Icon,
  InputGroup,
  Layout,
  Loading,
  Text,
  Toggler,
  Wrapper,
} from "../../../components";
import { fileType, types } from "../../../constants";
import { SignatureModal } from "../../../containers";
import {
  useAxios,
  useConverters,
  useDataState,
  useToggle,
} from "../../../hooks";
import { Document, Optional } from "../../../types";
import ConfirmModal from "./ConfirmModal";
import DocumentFile from "./DocumentFile";
import EditMenu from "./EditMenu";
export default function DocumentDetails() {
  const tagSplitSymbol = ",";
  const { axios, loading } = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const { convertDate } = useConverters();
  const { offerId, orderId, documentId } = useParams();
  const [data, setData, setBaseData, isChanged] = useDataState<
    Optional<Document.Details>
  >({});
  const [showEditMenu, toggleEditMenu] = useToggle(false);
  const [showSignModal, toggleSignModal] = useToggle(false);
  const [showConfirm, toggleConfirm] = useToggle(false);
  const isTemplate = !!data.templateCode;
  const isOrderDocument = location.pathname.startsWith("/orders");
  const isWord = data?.documentFileInfo?.mimeType === fileType.word;
  // const isEnabled = !isOrderDocument;
  const isEnabled = true;
  const handleSetTag = (tag: string) => {
    return () => {
      setData((p) => {
        const data = cloneDeep(p);
        const splitTags = data.tags?.split(tagSplitSymbol) || [];
        const index = splitTags.findIndex((e) => e === tag);
        const has = index !== -1;
        has ? splitTags.splice(index, 1) : splitTags.push(tag);
        data.tags = splitTags.join(tagSplitSymbol) || null;
        return data;
      });
    };
  };
  const getData = () => {
    const url = isOrderDocument
      ? `/salesservice/api/orderdocuments/${documentId}/detail`
      : `/salesservice/api/offerdocuments/${documentId}/detail`;
    axios.get(url).then(({ data }) => {
      setBaseData(data);
    });
  };
  const submitSignature = (signUrl: string) => {
    const url = [
      "/salesservice",
      "api",
      isOrderDocument ? "orderdocuments" : "offerdocuments",
      documentId,
    ].join("/");
    const body = cloneDeep({
      ...data,
      offerId: offerId,
      orderId: orderId,
      createdFromTemplate: true,
    });
    body.signUrl = signUrl;
    body.isSigned = true;
    axios.put(url, body).then(() => {
      toast.success("toast.success.editDocument");
      toggleSignModal();
      getData();
    });
  };
  const removeDocument = () => {
    const url = [
      "/salesservice",
      "api",
      isOrderDocument ? "orderdocuments" : "offerdocuments",
      documentId,
    ].join("/");
    axios.delete(url).then(() => {
      toast.success("toast.success.removeDocument");
      const url = isOrderDocument
        ? `/orders/${orderId}?tab=3`
        : `/offers/${offerId}?tab=3`;
      navigate(url, { replace: true });
    });
  };
  const submit = () => {
    const url = [
      "/salesservice",
      "api",
      isOrderDocument ? "orderdocuments" : "offerdocuments",
      documentId,
    ].join("/");
    const body = {
      ...data,
      offerId: offerId,
      orderId: orderId,
      createdFromTemplate: isTemplate,
    };
    axios.put(url, body).then(() => {
      toast.success("toast.success.editDocument");
      setBaseData(data);
    });
  };
  useEffect(getData, [offerId, orderId, documentId, isOrderDocument]);
  return (
    <Fragment>
      <Layout as={Form} onSubmit={submit}>
        <Layout.Header>
          <BackButton
            to={{
              pathname: isOrderDocument
                ? `/orders/${orderId}`
                : `/offers/${offerId}`,
              search: "tab=3",
            }}
            className="mr-auto"
          />
          {isEnabled && (
            <Fragment>
              <Button
                variant="danger"
                onClick={toggleConfirm}
                loading={loading.delete}
              >
                <Icon name="Trash" variant="Bold" size={20} />
              </Button>
              <Button
                type="submit"
                disabled={!isChanged}
                loading={loading.update}
              >
                <Text>button.submitChanges</Text>
              </Button>
            </Fragment>
          )}
        </Layout.Header>
        <Layout.Body className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          {loading.get ? (
            <section className="col-span-full">
              <Loading.Header />
            </section>
          ) : (
            <Fragment>
              <Wrapper className="col-span-full">
                <Wrapper.Body className="flex items-start gap-4">
                  <Icon.Wrapper className="w-24 h-24" rounded>
                    <Icon
                      name="DocumentText"
                      variant="Bold"
                      className="!w-16 !h-16"
                    />
                  </Icon.Wrapper>
                  <section className="flex-1 space-y-3">
                    <h6>
                      {data.title}{" "}
                      {!isTemplate && isEnabled && (
                        <button
                          type="button"
                          className="align-middle text-primary ml-1"
                          onClick={toggleEditMenu}
                        >
                          <Icon name="Edit2" size={20} />
                        </button>
                      )}
                    </h6>
                    <span className="block text-secondary">
                      <Text>
                        {isTemplate
                          ? "types.document.template"
                          : "types.document.personal"}
                      </Text>
                    </span>
                    <span className="block text-secondary uppercase break-words">
                      {data.documentFileInfo?.mimeType?.replace("/", " ")}
                    </span>
                  </section>
                  <section className="space-y-3 text-end">
                    <span className="bloc text-secondary">
                      <Text>global.lastUpdate</Text>:{" "}
                      {convertDate(data.lastUpdatedAt || data.createdAt)}
                    </span>
                    <span className="block text-secondary">
                      <Text>global.by</Text> :{" "}
                      {data.lastUpdatedByName || data.createdByName}
                    </span>
                  </section>
                </Wrapper.Body>
              </Wrapper>
              <div className="space-y-4 lg:col-span-3">
                <Wrapper>
                  <Wrapper.Body>
                    <InputGroup
                      as="textarea"
                      label="formControls.description"
                      value={data.description}
                      setValue={(description) =>
                        setData((p) => ({ ...p, description }))
                      }
                      readOnly={!isEnabled || isWord}
                    />
                  </Wrapper.Body>
                </Wrapper>
                <Wrapper>
                  <Wrapper.Header className="border-b-0 pb-0">
                    <h6 className="text-base text-dark font-medium">
                      <Text>formControls.documentTags</Text>
                    </h6>
                  </Wrapper.Header>
                  <Wrapper.Body className="flex items-center flex-wrap gap-4">
                    {types.documentTags.map((e) => {
                      const tags = data.tags?.split(tagSplitSymbol) ?? [];
                      const active = tags.includes(e.id);
                      return (
                        <Button
                          key={e.id}
                          type="button"
                          data-active={active}
                          className="text-xs font-medium rounded-[0.25rem] bg-gray-50 border-gray-50 text-secondary data-active:bg-primary data-active:border-primary data-active:text-white"
                          onClick={handleSetTag(e.id)}
                        >
                          <Text>{e.name}</Text>
                        </Button>
                      );
                    })}
                  </Wrapper.Body>
                </Wrapper>
                {isTemplate && (
                  <Wrapper>
                    <Wrapper.Body className="flex items-center justify-between">
                      <Toggler
                        disabled
                        label="togglers.requiredSign"
                        value={!!data.signMandatory}
                        setValue={(signMandatory) =>
                          setData((p) => ({ ...p, signMandatory }))
                        }
                      />
                    </Wrapper.Body>
                  </Wrapper>
                )}
                <Wrapper>
                  <Wrapper.Body className="flex items-center justify-between">
                    <Toggler
                      disabled={isTemplate || !isEnabled}
                      label="togglers.erp"
                      value={!!data.needSendToErp}
                      setValue={(needSendToErp) =>
                        setData((p) => ({ ...p, needSendToErp }))
                      }
                    />
                  </Wrapper.Body>
                </Wrapper>
                {!isWord && (
                  <Fragment>
                    {isTemplate && (
                      <Wrapper>
                        <Wrapper.Body className="flex items-center justify-between">
                          <h6>
                            <Text>global.signedByCustomer</Text>:
                          </h6>
                          {!!data.signedAt ? (
                            <span className="text-secondary">
                              {convertDate(data.signedAt)}
                            </span>
                          ) : (
                            <button
                              type="button"
                              className="btn-link"
                              onClick={toggleSignModal}
                              disabled={!isEnabled}
                            >
                              <Text>button.clickToSign</Text>
                            </button>
                          )}
                        </Wrapper.Body>
                      </Wrapper>
                    )}
                    {!isTemplate && (
                      <Wrapper>
                        <Wrapper.Body className="flex items-center justify-between">
                          <Toggler
                            label="togglers.signedByCustomer"
                            value={!!data.isSigned}
                            disabled={!isEnabled}
                            setValue={(isSigned) =>
                              setData((p) => ({ ...p, isSigned }))
                            }
                          />
                        </Wrapper.Body>
                      </Wrapper>
                    )}
                  </Fragment>
                )}
              </div>
              <Wrapper className="lg:col-span-4">
                <Wrapper.Body className="size-full">
                  {!!data.documentFileInfo && (
                    <DocumentFile document={data.documentFileInfo} />
                  )}
                </Wrapper.Body>
              </Wrapper>
            </Fragment>
          )}
        </Layout.Body>
      </Layout>
      {isEnabled && (
        <Fragment>
          <SignatureModal
            isOpen={showSignModal}
            toggle={toggleSignModal}
            onSubmit={submitSignature}
            loading={loading.update}
          />
          <EditMenu
            isOpen={showEditMenu}
            toggle={toggleEditMenu}
            data={data}
            setData={setBaseData}
            isOrderDocument={isOrderDocument}
          />
          <ConfirmModal
            isOpen={showConfirm}
            toggle={toggleConfirm}
            onConfirm={removeDocument}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
