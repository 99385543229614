import { isEmpty } from "lodash";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import {
  BackButton,
  Button,
  //   Breadcrumb,
  //   Button,
  Icon,
  Image,
  Layout,
  Loading,
  Status,
  //   Skeleton,
  //   Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { useAxios, usePermission, useToggle } from "../../../hooks";
// import { ServiceApi, URLS } from "services";
import { twMerge } from "tailwind-merge";
import { SellOffProductOldStatus } from "../../../enums";
import { SellOffProductOld } from "../../../types";
import PriceTemplate from "../List/PriceTemplate";
import Media from "./Media";
import SaleProccessTab from "./SaleProcess";
// import { classNames } from "utils";
// import PriceTemplate from "../List/PriceTemplate";
// import ChangePhaseModal from "../sell-off-drawers/ChangePhase";
// import ClosePhaseModal from "../sell-off-drawers/ClosePhase";
// import EditImageDrawer from "../sell-off-drawers/EditImageDrawer";
// import EditProductDrawer from "../sell-off-drawers/EditProductDrawer";
// import StartPreparationPhaseModal from "../sell-off-drawers/StartPrearationPhase";
// import WithdrawModal from "../sell-off-drawers/Withdraw";
// import DeleteModal from "../sell-off-drawers/delet-modal";
// import SoldModal from "../sell-off-drawers/sold";
// import SaleProccessTab from "./SaleProccess";

type Context = {
  data: SellOffProductOld.Details | null;
  editImageToggle: () => void;
  getData: () => void;
  setChangePhase: () => void;
  setStartPrePhase: () => void;
  setClosePhase: () => void;
};
export const SellOffProductDetailsContext = createContext({} as Context);

const SellOffProductDetails = () => {
  const updatePermission = usePermission("PS_UpdateSellOffProduct");
  const { axios, loading } = useAxios();
  const navigate = useNavigate();
  const { sellOffProductId } = useParams();
  const [editImages, editImageToggle] = useToggle();
  const [editProductOpen, editProductToggle] = useToggle();
  const [data, setData] = useState<SellOffProductOld.Details | null>(null);
  const hasData = !!data;
  const [startPrePhase, setStartPrePhase] = useToggle();
  const [changePhase, setChangePhase] = useToggle();
  const [closePhase, setClosePhase] = useToggle();
  const [soldProduct, setSoldProduct] = useToggle();
  const [withdraw, setWithdraw] = useToggle();
  const [confirmDelet, setConfirmDelete] = useToggle();
  const [deleteLoading, setDeleteLoading] = useToggle();
  const hasLoading = loading.get;

  const tabs = [
    // {
    //   label: "salesManagment.sellOffSales.SellOffProductOld.ld.overview",
    //   component: OverViewTab,
    // },
    {
      label: "products.details.mediaTab",
      id: "2",
      component: Media,
    },
    {
      label: "sellOffProducts.saleProccess",
      component: SaleProccessTab,
    },
  ];

  const getData = () => {
    const url = `productservice/api/selloffproducts/${sellOffProductId}/basic-info`;
    const statusUrl = `productservice/api/selloffproducts/${sellOffProductId}/status-info`;
    Promise.all([url, statusUrl].map((url) => axios.get(url))).then(
      ([infoResponse, statusResponse]) => {
        const data = { ...infoResponse.data, ...statusResponse.data };
        setData(data);
      }
    );
  };

  const getPhaseState = (item: SellOffProductOld.Details | null) => {
    const isSold = item?.isSold;
    const active = item?.currentPhaseDay;

    return (
      <div className="text-base">
        {item?.isClosed ? (
          <Status.SellOff id={SellOffProductOldStatus.Closed} />
        ) : isSold ? (
          <Status.SellOff id={SellOffProductOldStatus.Sold} />
        ) : active ? (
          <>
            <Status.SellOff id={SellOffProductOldStatus.Active} />{" "}
            {item?.changePhaseRequired && (
              <Status.SellOff id={SellOffProductOldStatus.NeedPhaseChange} />
            )}
            {item?.closePhaseRequired && (
              <Status.SellOff id={SellOffProductOldStatus.NeedClose} />
            )}
          </>
        ) : (
          <Status.SellOff id={SellOffProductOldStatus.Preparation} />
        )}
      </div>
    );
  };

  const hiddenClassName = twMerge((data?.isClosed || data?.isSold) && "hidden");

  // const onDelete = (id: string) => {
  //   setDeleteLoading();
  //   const url = URLS.DELETE_PRODUCT_URL(id);
  //   ServiceApi.delete(url)
  //     .then(() => {
  //       toast.success(
  //         "global.toast.delete-msg",
  //         "global.toast.delete-title",
  //       );
  //       setDeleteLoading();
  //       setConfirmDelete();
  //       navigate(-1);
  //     })
  //     .catch(() => setDeleteLoading());
  // };

  useEffect(getData, [sellOffProductId]);
  return (
    <>
      <Tabs activeKey={tabs[0].label}>
        <Layout>
          <Layout.Header>
            <BackButton className="mr-auto" />
            {updatePermission && (
              <Fragment>
                {data?.isSold || data?.isClosed ? (
                  <Button
                    className="text-sm flex items-center gap-1"
                    variant={"danger"}
                    disabled={loading.get}
                    // onClick={() => setConfirmDelete()}
                  >
                    <Icon name={"Trash"} />
                    <Text>sellOffProducts.deleteProduct</Text>
                  </Button>
                ) : !isEmpty(data) && !data?.currentPhaseDay ? (
                  <Button
                    className="text-sm"
                    // onClick={() => setStartPrePhase()}
                  >
                    <Text>sellOffProducts.startProccess</Text>
                  </Button>
                ) : data?.changePhaseRequired ? (
                  <Button
                    className="text-sm"
                    variant={"warning"}
                    // onClick={() => setChangePhase()}
                  >
                    <Text>sellOffProducts.changePhase</Text>
                  </Button>
                ) : data?.closePhaseRequired ? (
                  <Button
                    className="text-sm"
                    variant={"danger"}
                    // onClick={() => setClosePhase()}
                  >
                    <Text>sellOffProducts.closeProccess</Text>
                  </Button>
                ) : null}
              </Fragment>
            )}
          </Layout.Header>
          <Layout.Body className="grid grid-cols-1 lg:grid-cols-5 gap-3 [&>*]:col-span-full">
            <Fragment>
              <Wrapper className="lg:!col-span-3">
                <Wrapper.Body className="flex flex-col md:flex-row md:items-stretch gap-4">
                  <Image
                    //   onClick={() => toggle()}
                    src={
                      data?.assets?.filter(
                        (image: SellOffProductOld.Image) => image.order === 1
                      )[0]?.url
                    }
                    alt={data?.title}
                    className="w-full md:w-48 aspect-image object-contain bg-gray-100 rounded"
                    isDynamic
                  />
                  {!hasLoading && (
                    <div className="flex h-full w-full justify-between ">
                      <div className="flex flex-col gap-y-2 space-y-2 text-body-2 font-medium text-gray-500">
                        <h6 className="flex items-center space-x-2 text-heading-5">
                          {data?.title}
                          {updatePermission && (
                            <Icon
                              name="Edit2"
                              className={`ml-2 cursor-pointer text-primary ${hiddenClassName}`}
                              // onClick={() => {
                              //   editProductToggle();
                              // }}
                            />
                          )}
                          {getPhaseState(data)}
                        </h6>
                        <p>#{data?.articleNumber}</p>
                        <p className="text-sm text-secondary font-medium w-full mt-auto">
                          <Text>sellOffProducts.supplier</Text>:{" "}
                          {data?.supplierName}
                        </p>
                        <p className="text-sm text-secondary font-medium w-full mt-auto">
                          <Text>sellOffProducts.program</Text>: {/* todo */}
                          {/* {data?.programName} */}
                          program name!
                        </p>
                        <p>
                          <Icon
                            name="LocationTick"
                            variant="Bulk"
                            className="mr-1"
                          />{" "}
                          {data?.storeName} / {data?.location}
                        </p>
                      </div>
                    </div>
                  )}
                </Wrapper.Body>
              </Wrapper>
              <Wrapper className="lg:!col-span-2">
                <Wrapper.Body className="h-full flex flex-col gap-4 justify-between">
                  {!hasLoading && (
                    <Fragment>
                      {hasData && (
                        <PriceTemplate
                          inDetails
                          item={data}
                          className="space-y-2"
                        />
                      )}
                      {updatePermission && (
                        <>
                          {data?.isClosed ? (
                            <Button
                              className="w-full cursor-default text-gray"
                              variant={"white"}
                            >
                              <Text>sellOffProducts.closed</Text>
                            </Button>
                          ) : data?.isSold ? (
                            <Button
                              className="w-full"
                              variant={"warning"}
                              // onClick={() => setWithdraw()}
                            >
                              <Text>sellOffProducts.withdraw</Text>
                            </Button>
                          ) : (
                            <Button
                              className="w-full"
                              disabled={[
                                !data?.currentPhaseDay,
                                data?.isClosed,
                                data?.isSold,
                              ].some(Boolean)}
                              // onClick={() => setSoldProduct()}
                            >
                              <Text>sellOffProducts.sold</Text>
                            </Button>
                          )}
                        </>
                      )}
                    </Fragment>
                  )}
                  {hasLoading && (
                    <div className="space-y-4 lg:flex-1">
                      <Fragment>
                        {/* <Skeleton.Input />
                    <Skeleton.Input /> */}
                      </Fragment>
                    </div>
                  )}
                </Wrapper.Body>
              </Wrapper>
              <Wrapper className="col-span-full">
                <Tabs.ButtonGroup>
                  {tabs.map((e) => (
                    <Tabs.Button key={e.label} eventKey={e.label}>
                      <Text>{e.label}</Text>
                    </Tabs.Button>
                  ))}
                </Tabs.ButtonGroup>
              </Wrapper>
            </Fragment>
            <SellOffProductDetailsContext.Provider
              value={{
                data,
                editImageToggle,
                getData: getData,
                setChangePhase,
                setStartPrePhase,
                setClosePhase,
              }}
            >
              {hasLoading
                ? [1, 2, 3, 4].map(() => <Loading.List />)
                : tabs.map((e) => (
                    <Tabs.Item key={e.label} eventKey={e.label}>
                      {createElement(e.component)}
                    </Tabs.Item>
                  ))}
            </SellOffProductDetailsContext.Provider>
          </Layout.Body>
        </Layout>
      </Tabs>
      {/* <EditImageDrawer
        isOpen={editImages}
        toggle={editImageToggle}
        data={data}
        onComplete={getInfoData}
      /> */}
      {/* <EditProductDrawer
        isOpen={editProductOpen}
        data={data}
        toggle={editProductToggle}
        getData={getInfoData}
      /> */}
      {/* <StartPreparationPhaseModal
        isOpen={startPrePhase}
        toggle={setStartPrePhase}
        getData={getInfoData}
        productId={data?.sellOffProductId}
      /> */}
      {/* <ChangePhaseModal
        isOpen={changePhase}
        toggle={setChangePhase}
        getData={getInfoData}
        productId={data?.sellOffProductId}
      /> */}
      {/* <ClosePhaseModal
        isOpen={closePhase}
        toggle={setClosePhase}
        getData={getInfoData}
        id={data?.sellOffProductId}
      /> */}
      {/* <SoldModal
        isOpen={soldProduct}
        toggle={setSoldProduct}
        getData={getInfoData}
        id={data?.sellOffProductId}
        data={data}
      /> */}
      {/* <WithdrawModal
        isOpen={withdraw}
        toggle={setWithdraw}
        getData={getInfoData}
        id={data?.sellOffProductId}
      /> */}
      {/* {hasData && (
        <DeleteModal
          isOpen={confirmDelet}
          loading={deleteLoading}
          toggle={setConfirmDelete}
          id={data?.sellOffProductId}
          selectedName={data?.title}
          onConfirm={onDelete}
        />
      )} */}
    </>
  );
};

export default SellOffProductDetails;
