import { Dispatch } from "react";
import { Button, Text } from "../../../components";
import { types } from "../../../constants";
import { PimProductType } from "../../../enums";

type Props = {
  value: PimProductType | null;
  setValue: Dispatch<PimProductType | null>;
  disabled?: boolean;
};

export default function PimProductTypeFilter({
  value,
  setValue,
  disabled,
}: Props) {
  const items = [
    { name: "products.list.allButton", id: null },
    ...types.pimProduct,
  ];
  return (
    <section className="col-span-full flex flex-wrap items-center gap-2 py-3">
      {items.map((e) => {
        const active = e.id === value;
        return (
          <Button
            key={e.name}
            type="button"
            variant={active ? "primary" : "white"}
            onClick={() => setValue(e.id)}
            disabled={disabled}
          >
            <Text>{e.name}</Text>
          </Button>
        );
      })}
    </section>
  );
}
