import { cloneDeep } from "lodash";
import { Fragment, useContext, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AddBundleCatalogueContext } from ".";
import { OfferDetailsContext } from "../..";
import {
  Button,
  Counter,
  Drawer,
  Form,
  InputGroup,
  Text,
} from "../../../../../components";
import { rules } from "../../../../../constants";
import { useAxios } from "../../../../../hooks";
import { LineItem, Optional } from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";
import { noteSuggestion } from "../notes";

export default function ManualForm() {
  const { axios, loading } = useAxios();
  const formRef = useRef<HTMLFormElement>(null);
  const { supplier, program, toggle, onSubmitted } = useContext(
    AddBundleCatalogueContext
  );
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const isOffer = !!offer;
  const isOrder = !!order;
  const hasLoading = [
    loading.post,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  const initData: Optional<LineItem.CreateBundleCatalogue> = {
    quantity: 1,
  };
  const [data, setData] = useState(initData);

  const handleSetValue = (key: keyof LineItem.CreateBundleCatalogue) => {
    return (value: unknown) => {
      setData((p) => ({ ...p, [key]: value }));
    };
  };
  const handleSubmit = () => {
    formRef.current?.requestSubmit();
  };
  const submit = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      "manual-bundle-product",
    ]
      .filter(Boolean)
      .join("/");
    const body = cloneDeep(data);
    body.offerId = offer?.id;
    body.orderId = order?.id;
    body.supplierId = supplier?.supplierId;
    body.supplierProgramId = program?.id;
    body.description ||= null;
    body.configurationText ||= null;
    axios.post(url, body).then(() => {
      (updateOffer ?? updateOrder)?.().then(() => {
        toast.success("toast.success.addLineItem");
        onSubmitted();
        toggle();
      });
    });
  };
  return (
    <Fragment>
      <Drawer.Body>
        <Form
          ref={formRef}
          onSubmit={submit}
          className="grid grid-cols-4 gap-4"
        >
          <InputGroup
            label="formControls.lineItemBundleCatalogueTitle"
            value={data?.title}
            setValue={handleSetValue("title")}
            rules={rules.required}
            className="col-span-full"
          />
          <Counter
            label="formControls.lineItemBundleCatalogueQuantity"
            value={data?.quantity}
            setValue={handleSetValue("quantity")}
            className="col-span-2"
            type="decimal"
            min={0}
          />
          <InputGroup
            as="textarea"
            label="formControls.lineItemBundleCatalogueDescription"
            value={data?.description}
            setValue={handleSetValue("description")}
            className="col-span-full"
            suggestionData={noteSuggestion}
            snippet
          />
          <InputGroup
            as="textarea"
            label="formControls.lineItemConfigurationText"
            value={data?.configurationText}
            setValue={handleSetValue("configurationText")}
            className="col-span-full"
            suggestionData={noteSuggestion}
            snippet
          />
          <button type="submit" hidden disabled={hasLoading} />
        </Form>
      </Drawer.Body>
      <Drawer.Footer className="flex items-center justify-end gap-4">
        <Button type="button" variant="danger" onClick={toggle}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          loading={hasLoading}
          onClick={handleSubmit}
        >
          <Text>button.addBundleCatalogueLineItem</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
