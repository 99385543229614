import { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Modal, Text } from "../components";
import { useUploadFile } from "../hooks";
import { togglePropsType } from "../types";
type signatureModalProps = togglePropsType & {
  onSubmit?: (url: string) => void;
  loading?: boolean;
};
export default function SignatureModal({
  isOpen,
  toggle,
  onSubmit = () => {},
  loading = false,
}: // url = null,
signatureModalProps) {
  const signRef = useRef<SignatureCanvas>(null);
  const [uploadFile, uploading] = useUploadFile();
  const clear = () => {
    signRef.current?.clear();
  };
  const submit = () => {
    signRef.current?.getTrimmedCanvas().toBlob((blob) => {
      if (!blob) return;
      uploadFile(blob).then(({ url }) => {
        onSubmit(url);
      });
    });
  };
  // const hasSign = Boolean(url);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="flex items-center justify-between">
        <h6>
          <Text>modalTitles.signHere</Text>
        </h6>
        <button
          // hidden={hasSign}
          type="button"
          className="text-danger"
          onClick={clear}
        >
          <Text>button.clear</Text>
        </button>
      </Modal.Header>
      <Modal.Body>
        {/* {hasSign ? (
          <img
            width="450"
            height="200"
            className="block rounded mx-auto"
            src={url ?? ""}
            alt="sign url"
          />
        ) : ( */}
        <SignatureCanvas
          ref={signRef}
          penColor="#000"
          canvasProps={{
            width: "450",
            height: "200",
            className: "bg-gray-50 rounded mx-auto",
          }}
        />
        {/* )} */}
      </Modal.Body>
      <Modal.Footer className="flex flex-center">
        <Button onClick={submit} loading={loading || uploading}>
          <Text>button.submit</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
