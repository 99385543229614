import { ComponentProps, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
type wrapperProps = {
  className?: string;
  children: ReactNode;
};
function Wrapper({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div
      className={twMerge("w-full bg-white rounded shadow px-4", className)}
      {...props}
    >
      {children}
    </div>
  );
}
function WrapperHeader({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div
      className={twMerge("w-full border-b border-gray py-4", className)}
      {...props}
    >
      {children}
    </div>
  );
}
function WrapperBody({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div className={twMerge("w-full py-4", className)} {...props}>
      {children}
    </div>
  );
}
function WrapperFooter({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div
      className={twMerge("w-full border-t border-gray py-4", className)}
      {...props}
    >
      {children}
    </div>
  );
}
Wrapper.Header = WrapperHeader;
Wrapper.Body = WrapperBody;
Wrapper.Footer = WrapperFooter;
export default Wrapper;
