import { cloneDeep } from "lodash";
import { Fragment, useContext } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router";
import { CompanyContext } from "../";
import {
  Button,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
  Toggler,
  Wrapper,
} from "../../../../components";
import { countryCodes, rules } from "../../../../constants";
import { SearchAddress } from "../../../../containers";
import { CountryCode } from "../../../../enums";
import {
  useAxios,
  useCountries,
  useDataState,
  useHasAddress,
  useValidateAddress,
} from "../../../../hooks";
import { Address, Company } from "../../../../types";

export default function Overview() {
  const { axios, loading } = useAxios();
  const [countries, countriesLoading] = useCountries();
  const [validateAddress, validateLoading] = useValidateAddress();
  const { companyId } = useParams();
  const { companyData, setCompanyData } = useContext(CompanyContext);
  const [data, setData, setBaseData, isChanged] = useDataState(companyData);
  const hasAddress = useHasAddress(data.address);
  const hasPhoneNumber = !!data.phoneNumber?.number;
  const taxIdIsRequired = !!data.eligibleForTaxDeduction;

  const handleSetValue = (
    key: keyof Company.Details | "mobileNumberCode" | "phoneNumberCode"
  ) => {
    const isMobileNumber = key === "mobileNumber";
    const isPhoneNumber = key === "phoneNumber";
    const isMobileNumberCode = key === "mobileNumberCode";
    const isPhoneNumberCode = key === "phoneNumberCode";
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        if (isMobileNumber) {
          const number = value;
          const countryCode = p.mobileNumber?.countryCode ?? CountryCode.De;
          data.mobileNumber = { countryCode, number };
          return data;
        }
        if (isPhoneNumber) {
          const number = value;
          const countryCode = p.phoneNumber?.countryCode ?? NaN;
          data.phoneNumber = { countryCode, number };
          return data;
        }
        if (isMobileNumberCode) {
          const number = p.mobileNumber?.number ?? NaN;
          const countryCode = value;
          data.mobileNumber = { countryCode, number };
          return data;
        }
        if (isPhoneNumberCode) {
          const number = p.phoneNumber?.number ?? NaN;
          const countryCode = value;
          data.phoneNumber = { countryCode, number };
          return data;
        }
        // @ts-ignore: Unreachable code error
        data[key] = value;
        return data;
      });
  };
  const handleSetAddress = (key: keyof Address) => {
    return (value: any) =>
      setData((p) => {
        const isCountryCode = key === "countryCode";
        const data = cloneDeep(p);
        data.address ??= {} as Address;
        data.address[key] = value;
        if (isCountryCode) {
          const country = countries.find(
            (e) => e.id === data.address?.countryCode
          );
          data.address.country = country?.name ?? null;
          data.address.countryCodeISO3 = country?.countryIso3 ?? null;
        }
        return data;
      });
  };
  const submitChange = async () => {
    if (!hasAddress) return submitRequest();
    validateAddress(data.address).then(({ isValid, suggested }) => {
      if (isValid) return submitRequest();
      if (!suggested) return toast.error("errorCodes.inValidAddress");
      toast("toast.warning.suggestedAddress");
      setData((p) => ({ ...p, address: suggested }));
    });
  };

  const submitRequest = () => {
    const url = `/accountservice/api/customers/${companyId}/as-company`;
    const body = {
      ...data,
      ...(!hasAddress && { address: null }),
      ...(!hasPhoneNumber && { phoneNumber: null }),
    };
    axios.put(url, body).then(() => {
      const message = "company.details.overview.editMessage";
      toast.success(message);
      setCompanyData(body);
      setBaseData(body);
    });
  };

  return (
    <Fragment>
      <Form onSubmit={submitChange} className="space-y-4">
        <div className="flex items-center justify-between">
          <h6>
            <Text>company.details.overview.submitText</Text>
          </h6>
          <Button
            type="submit"
            disabled={!isChanged}
            loading={loading.update || validateLoading}
          >
            <Text>company.details.overview.submitBtn</Text>
          </Button>
        </div>
        <section className="grid grid-cols-2 gap-4 items-start">
          <div className="space-y-4">
            <Wrapper>
              <Wrapper.Body>
                <InputGroup
                  label="company.details.overview.companyName"
                  value={data.companyName}
                  setValue={handleSetValue("companyName")}
                  rules={rules.required}
                />
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <SearchAddress
                  className="col-span-full"
                  label="formControls.searchAddress"
                  setValue={(address) => setData((p) => ({ ...p, address }))}
                  countries={countries}
                  loading={countriesLoading}
                />
                <span className="block col-span-full text-secondary text-center">
                  <Text>global.orEnterManual</Text>
                </span>
                <InputGroup
                  label="formControls.name"
                  value={data.address?.name}
                  setValue={handleSetAddress("name")}
                  className="col-span-full"
                />
                <InputGroup
                  label="formControls.street"
                  value={data.address?.street}
                  setValue={handleSetAddress("street")}
                />
                <InputGroup
                  label="formControls.number"
                  value={data.address?.number}
                  setValue={handleSetAddress("number")}
                />
                <InputGroup
                  label="formControls.postalCode"
                  value={data.address?.postalCode}
                  setValue={handleSetAddress("postalCode")}
                />
                <InputGroup
                  label="formControls.city"
                  value={data.address?.city}
                  setValue={handleSetAddress("city")}
                />
                <Select
                  label="formControls.country"
                  value={data.address?.countryCode}
                  setValue={handleSetAddress("countryCode")}
                  items={countries}
                />
                <InputGroup
                  label="formControls.state"
                  value={data.address?.state}
                  setValue={handleSetAddress("state")}
                />
              </Wrapper.Body>
            </Wrapper>
          </div>
          <div className="space-y-4">
            <Wrapper>
              <Wrapper.Body>
                <Toggler
                  label="company.details.overview.eligibleForTaxDeduction"
                  value={!!data.eligibleForTaxDeduction}
                  setValue={(eligibleForTaxDeduction) =>
                    setData((p) => ({ ...p, eligibleForTaxDeduction }))
                  }
                />
                <p className="text-secondary text-sm mb-2">
                  <Icon name="InfoCircle" /> Lorem ipsum dolor sit amet
                  consectetur.
                </p>
                <InputGroup
                  label="company.details.overview.taxId"
                  value={data.taxId}
                  setValue={handleSetValue("taxId")}
                  rules={taxIdIsRequired ? rules.required : []}
                />
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Body>
                <InputGroup
                  label="company.details.overview.companyRegistrationNumber"
                  value={data.companyRegistrationNumber}
                  setValue={handleSetValue("companyRegistrationNumber")}
                  rules={rules.required}
                />
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Body className="grid grid-cols-3 gap-4">
                <InputGroup
                  className="col-span-full"
                  label="company.details.overview.emailAddress"
                  value={data.emailAddress}
                  setValue={handleSetValue("emailAddress")}
                  rules={rules.email}
                  type="email"
                />
                <Select
                  className="col-span-1"
                  label="company.details.overview.countryCode"
                  value={data.mobileNumber?.countryCode}
                  setValue={handleSetValue("mobileNumberCode")}
                  items={countryCodes}
                  rules={rules.required}
                />
                <InputGroup
                  className="col-span-2"
                  label="company.details.overview.mobileNumber"
                  value={data.mobileNumber?.number}
                  setValue={handleSetValue("mobileNumber")}
                  rules={rules.required}
                  type="integer"
                />
                <Select
                  className="col-span-1"
                  label="company.details.overview.countryCode"
                  value={data.phoneNumber?.countryCode}
                  setValue={handleSetValue("phoneNumberCode")}
                  items={countryCodes}
                  rules={hasPhoneNumber ? rules.required : undefined}
                />
                <InputGroup
                  className="col-span-2"
                  label="company.details.overview.phoneNumber"
                  value={data.phoneNumber?.number}
                  setValue={handleSetValue("phoneNumber")}
                  type="integer"
                  rules={hasPhoneNumber ? rules.phoneNumber : undefined}
                />
              </Wrapper.Body>
            </Wrapper>
          </div>
        </section>
      </Form>
    </Fragment>
  );
}
