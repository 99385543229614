import { MouseEvent } from "react";
import { DocumentIcon, Image, Loading } from "../../../../components";
import { useGetFile } from "../../../../hooks";
import { downloadFile } from "../../../../methods";
import { SellOffProductOld } from "../../../../types";

type MediaItemProps = {
  media: SellOffProductOld.Image;
  onClick: () => void;
};
export default function MediaItem({ media, onClick }: MediaItemProps) {
  const isImage = media.type?.toLowerCase().startsWith("image");
  const [getFile, loading] = useGetFile();
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (isImage) return onClick?.();
    media.url &&
      getFile(media.url).then((url) => {
        downloadFile(url);
      });
  };
  return (
    <button
      type="button"
      onClick={handleClick}
      className="relative w-full aspect-image flex-center flex-col gap-2 rounded overflow-hidden bg-white shadow"
    >
      {isImage ? (
        <Image
          src={media.thumbnailUrl}
          alt={media.type}
          className="size-full object-cover hover:scale-110 transition-transform"
          isDynamic
        />
      ) : (
        <DocumentIcon type={media.type} className="block size-16 mb" />
      )}
      {loading && (
        <div className="absolute flex-center inset-0 size-full bg-dark/10 backdrop-blur-sm">
          <Loading.Inline />
        </div>
      )}
    </button>
  );
}
