import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { OfferDetailsContext } from "..";
import {
  AddressViewer,
  Button,
  Icon,
  Text,
  Wrapper,
} from "../../../../components";
import { useAxios, useToggle } from "../../../../hooks";
import { LineItem } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";
import AddressDrawer from "../AddressDrawer";
import LineItems from "./LineItems";
import Offer from "./Offer";

type ContextType = {
  lineItems: LineItem.Item[];
  lineItemsLoading: boolean;
  getLineItems: () => void;
  setLineItems: Dispatch<SetStateAction<LineItem.Item[]>>;
};

export const LogisticContext = createContext<ContextType>({
  lineItems: [],
  lineItemsLoading: false,
  getLineItems: () => {},
  setLineItems: () => {},
});

export default function Logistic() {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isOffer = !!offer;
  const isOrder = !!order;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;

  const { axios, loading } = useAxios();
  const [showAddressDrawer, toggleAddressDrawer] = useToggle(false);
  const [lineItems, setLineItems] = useState<LineItem.Item[]>([]);
  const lineItemsLoading = loading.get;
  const address = offer?.shippingAddress || order?.shippingAddress;
  const hasAddress = !!address;

  const getLineItems = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
    ]
      .filter(Boolean)
      .join("/");
    const config = {
      params: {
        offerId: offer?.id,
        orderId: order?.id,
        isCanceled: false,
      },
    };
    axios.get(url, config).then(({ data }) => {
      setLineItems(data);
    });
  };
  useEffect(getLineItems, [offer?.id, order?.id]);
  return (
    <LogisticContext.Provider
      value={{ lineItems, lineItemsLoading, getLineItems, setLineItems }}
    >
      <Wrapper className="lg:!col-span-2">
        <Wrapper.Header className="flex items-center">
          <h6 className="text-base text-dark">
            <Text>drawerTitles.shipTo</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="flex items-start gap-4">
          {hasAddress && (
            <AddressViewer
              className="text-sm text-secondary flex-1"
              address={address}
            />
          )}
          {isEnabled && (
            <Button
              type="button"
              variant="primary"
              light
              className={`p-[0.375rem] ${
                hasAddress ? "text-lg" : "text-base"
              } leading-none`}
              onClick={toggleAddressDrawer}
            >
              <Icon name="Edit2" />
              {!hasAddress && (
                <Fragment>
                  {" "}
                  <Text>button.addShippingAddress</Text>
                </Fragment>
              )}
            </Button>
          )}
        </Wrapper.Body>
      </Wrapper>
      <Offer />
      <LineItems />
      {isEnabled && (
        <AddressDrawer
          isOpen={showAddressDrawer}
          toggle={toggleAddressDrawer}
          type="shipping"
        />
      )}
    </LogisticContext.Provider>
  );
}
