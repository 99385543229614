import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import { Button, Drawer, Icon, Text } from "../../../../../components";
import { Program, Supplier, togglePropsType } from "../../../../../types";
import Iwofurn from "./Iwofurn";
import Programs from "./Programs";
import Suppliers from "./Suppliers";

type ConfigProductProps = togglePropsType & {
  parentId: string | null;
  onSubmitted: () => void;
};
type ConfigProductContextType = {
  parentId: string | null;
  supplier: Supplier.Item | null;
  setSupplier: Dispatch<SetStateAction<Supplier.Item | null>>;
  program: Program.Item | null;
  setProgram: Dispatch<SetStateAction<Program.Item | null>>;
  toggle: () => void;
  onSubmitted: () => void;
};

export const ConfigProductContext = createContext(
  {} as ConfigProductContextType
);

export default function ConfigProduct({
  isOpen,
  toggle,
  onSubmitted,
  parentId,
}: ConfigProductProps) {
  const [supplier, setSupplier] = useState<Supplier.Item | null>(null);
  const [program, setProgram] = useState<Program.Item | null>(null);
  const hasSupplier = !!supplier;
  const hasProgram = !!program;
  const menuSize = hasSupplier && hasProgram ? "xl" : undefined;
  const ActiveStep = useMemo(() => {
    if (!supplier) return Suppliers;
    if (!program) return Programs;
    return Iwofurn;
  }, [supplier, program]);
  const activeTitle = useMemo(() => {
    if (!supplier) return "drawerTitles.chooseSupplier";
    if (!program) return "drawerTitles.chooseProgram";
    return "drawerTitles.addProduct";
  }, [supplier, program]);
  const clearSupplier = () => {
    setSupplier(null);
  };
  const clearProgram = () => {
    setProgram(null);
  };
  const handleClearData = () => {
    if (!!program) return clearProgram();
    return clearSupplier();
  };
  const handleToggle = () => {
    clearSupplier();
    clearProgram();
    toggle();
  };
  return (
    <Drawer
      isOpen={isOpen}
      toggle={!hasProgram || !hasProgram ? handleToggle : () => {}}
      size={menuSize}
    >
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="flex-1 text-start">
            <Text>{activeTitle}</Text>
          </h6>
          <Button type="button" variant="danger" light onClick={handleToggle}>
            <Icon name="CloseCircle" size="1em" /> <Text>button.close</Text>
          </Button>
        </Drawer.Header>
        {hasSupplier && (
          <Drawer.Header className="flex flex-center text-start border-b-0">
            {supplier && (
              <div className="flex-1 flex flex-center w-full gap-4">
                <Icon.Wrapper rounded>
                  <Icon name="Building" variant="Bold" />
                </Icon.Wrapper>
                <div className="flex-1">
                  <h6 className="truncate">{supplier?.name}</h6>
                  <span className="block truncate text-secondary">
                    #{supplier?.iln}
                  </span>
                </div>
              </div>
            )}
            {program && (
              <div className="flex-1 flex flex-center w-full gap-4">
                <Icon.Wrapper rounded>
                  <Icon name="DocumentText" variant="Bold" />
                </Icon.Wrapper>
                <div className="flex-1">
                  <h6 className="truncate">{program?.title}</h6>
                  <span className="block truncate text-secondary">
                    #{program?.code}
                  </span>
                </div>
              </div>
            )}
            <button
              type="button"
              className="text-warning text-sm"
              onClick={handleClearData}
            >
              <Icon
                name="ArrowForwardSquare"
                variant="Bold"
                size={25}
                className="text-warning"
              />{" "}
              <Text>button.change</Text>
            </button>
          </Drawer.Header>
        )}
        <ConfigProductContext.Provider
          value={{
            parentId,
            supplier,
            setSupplier,
            program,
            setProgram,
            toggle: handleToggle,
            onSubmitted,
          }}
        >
          {isOpen && <ActiveStep />}
        </ConfigProductContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
