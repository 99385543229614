import { Fragment, useContext } from "react";
import { ProductDetailsContext } from "..";
import {
  Accordion,
  Icon,
  Text,
  Translate,
  Wrapper,
} from "../../../../components";
import { useFindAttribute } from "../../../../hooks";
import { iconNameType } from "../../../../types";

export default function General() {
  const { product } = useContext(ProductDetailsContext);
  const findAttribute = useFindAttribute();
  const moreInfo = [
    {
      icon: "Hashtag" as iconNameType,
      label: "products.details.articleNumber",
      value: `#${product.articleNumber}`,
    },
    {
      icon: "Building" as iconNameType,
      label: "products.details.supplier",
      value: product.supplier?.name ?? "--",
    },
    {
      icon: "Building" as iconNameType,
      label: "products.details.supplierArticleNumber",
      value: product.supplierArticleNumber ?? "--",
    },
    {
      icon: "Element3" as iconNameType,
      label: "products.details.productFamily",
      value: (
        <Translate
          value={product.productFamily?.translates}
          defaultValue="--"
        />
      ),
    },
    {
      icon: "Document" as iconNameType,
      label: "products.details.program",
      value: product.supplier?.program?.name ?? "--",
    },
  ];
  return (
    <Fragment>
      <Accordion defaultActiveKey="about-product">
        <Wrapper>
          <Accordion.Item eventKey="about-product">
            <Accordion.Toggle>
              <div className="space-y-2 flex-1 text-start">
                <h2 className="text-base text-dark">
                  <Text>products.details.aboutProductTitle</Text>
                </h2>
                <p className="text-secondary text-base font-medium">
                  {/* <Text>products.details.aboutProductText</Text> */}
                  <Translate
                    value={findAttribute(
                      product.attributeGroups,
                      "shortDescription"
                    )}
                    defaultValue="--"
                  />
                </p>
              </div>
            </Accordion.Toggle>
            <Accordion.Body>
              <p className="text-secondary text-base font-medium">
                <Translate
                  value={findAttribute(product.attributeGroups, "descriptions")}
                  defaultValue="--"
                />
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Wrapper>
      </Accordion>
      <Accordion>
        <Wrapper>
          <Accordion.Item>
            <Accordion.Toggle>
              <h2 className="text-base text-dark">
                <Text>products.details.moreInfo</Text>
              </h2>
            </Accordion.Toggle>
            <Accordion.Body>
              <table className="w-full">
                <tbody className="divide-y">
                  {moreInfo.map((e) => (
                    <tr key={e.label} className="*:py-3">
                      <td className="text-secondary max-w-96">
                        <div className="w-fit">
                          <Icon
                            name={e.icon}
                            variant="Bold"
                            className="size-4 me-2"
                          />{" "}
                          <Text>{e.label}</Text>
                        </div>
                      </td>
                      <td className="text-gray-900 font-medium px-3">
                        {e.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
        </Wrapper>
      </Accordion>
    </Fragment>
  );
}
