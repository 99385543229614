import { useId } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "./_Icon";
import Text from "./_Text";

type checkBoxProps = {
  label?: string;
  value: boolean;
  setValue?: (val: boolean) => void;
  className?: string;
  disabled?: boolean;
};
export default function CheckBox({
  value,
  setValue = () => {},
  label,
  className,
  disabled,
}: checkBoxProps) {
  const id = useId();
  const hasLabel = !!label;
  return (
    <label
      data-active={value}
      className={twMerge(
        "group flex items-center gap-4 select-none",
        hasLabel && "w-full",
        disabled && "cursor-not-allowed",
        className
      )}
    >
      <input
        id={id}
        type="checkbox"
        checked={value}
        onChange={() => setValue(!value)}
        hidden
        disabled={disabled}
      />
      <span
        className={twMerge(
          "size-6 rounded-md bg-light",
          disabled && "opacity-50"
        )}
      >
        <Icon
          name="TickSquare"
          variant="Bold"
          className={twMerge(
            "size-full text-primary opacity-0 transition-opacity",
            value ? "opacity-100" : "opacity-0"
          )}
        />
      </span>
      {label && (
        <span className="text-start flex-1">
          <Text>{label}</Text>
        </span>
      )}
    </label>
  );
}
