import { configureStore } from "@reduxjs/toolkit";
import permissions from "./permissions";
import profile from "./profile";
import selectSaleChannelActions from "./selectSaleChannelActions";
const store = configureStore({
  reducer: {
    profile,
    permissions,
    selectSaleChannelActions,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
