import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { ImportBasketContext } from ".";
import {
  Button,
  Drawer,
  Icon,
  List,
  SearchBox,
  Text,
} from "../../../../../components";
import { useAxios, useConverters } from "../../../../../hooks";
import { Basket } from "../../../../../types";

export default function BasketList() {
  const { axios, loading } = useAxios();
  const { convertDate, convertAmount } = useConverters();
  const { setActiveBasket } = useContext(ImportBasketContext);
  const [data, setData] = useState<Basket.Item[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);
  const baskets = useMemo(() => {
    return data.filter((e) =>
      [e.iln, e.internalTag, e.senderName, e.tag, e.price]
        .map(String)
        .map((e) => e.toLowerCase())
        .some((e) => e.includes(keyword?.toLowerCase() ?? ""))
    );
  }, [data, keyword]);
  const getData = () => {
    const url = "/salesservice/api/iwofurnconfigurations/iwofurn-baskets";
    axios.post(url).then(({ data }) => {
      setData(data);
    });
  };

  useEffect(getData, []);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0">
        <SearchBox
          value={keyword}
          onSubmit={setKeyword}
          variant="gray"
          placeholder="placeholders.searchBaskets"
        />
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.post}>
          {baskets.map((e) => {
            const key = [e.tag, e.internalTag, e.stamp, e.price].join("-");
            return (
              <List.Item
                key={key}
                onClick={() => setActiveBasket(e)}
                className="flex items-start w-full text-start gap-4 py-2 border-b border-dashed border-gray-100 last:border-none"
              >
                <Icon.Wrapper>
                  <Icon name="ShoppingCart" variant="Bold" />
                </Icon.Wrapper>
                <section className="flex-1">
                  <div className="flex items-center gap-4">
                    <div className="flex-1">
                      <h6 className="block truncate text-gray-900">#{e.tag}</h6>
                      {/* <p className="text-sm truncate text-secondary">
                      {convertAmount(e.price)}
                    </p> */}
                    </div>
                    <Button as="span" light>
                      <Text>button.choose</Text>
                    </Button>
                  </div>
                  <div className="flex items-center text-sm text-gray-900 mt-4">
                    <p className="truncate flex-1">#{e.internalTag}</p>
                    <p>
                      <Icon name="Calendar" variant="Bold" size={16} />{" "}
                      <Text>global.createdAt</Text>: {convertDate(e.stamp)}
                    </p>
                  </div>
                </section>
              </List.Item>
            );
          })}
        </List>
      </Drawer.Body>
    </Fragment>
  );
}
