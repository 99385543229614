import { Dispatch, SetStateAction, createContext, useMemo } from "react";
import { Drawer, Icon, Text } from "../../../../../components";
import {
  LineItem,
  Program,
  Supplier,
  togglePropsType,
} from "../../../../../types";
import ManualForm from "./ManualForm";

type CreateBundleSubLineItemProps = togglePropsType & {
  parentId: string | null;
  parent: LineItem.Item | null;
  onSubmitted: () => void;
};
type CreateBundleSubLineItemContextType = {
  parentId: string | null;
  parent: LineItem.Item | null;
  supplier: LineItem.Item["supplier"];
  setSupplier: Dispatch<SetStateAction<Supplier.Item | null>>;
  program: LineItem.Item["supplierProgram"];
  setProgram: Dispatch<SetStateAction<Program.Item | null>>;
  toggle: () => void;
  onSubmitted: () => void;
};

export const CreateBundleSubLineItemContext = createContext(
  {} as CreateBundleSubLineItemContextType
);

export default function CreateBundleSubLineItem({
  isOpen,
  toggle,
  onSubmitted,
  parentId,
  parent,
}: CreateBundleSubLineItemProps) {
  // const [supplier, setSupplier] = useState<Supplier.Item | null>(null);
  const supplier = parent?.supplier ?? null;
  const setSupplier: Dispatch<SetStateAction<Supplier.Item | null>> = (
    supplier
  ) => {};
  // const [program, setProgram] = useState<Program.Item | null>(null);
  const program = parent?.supplierProgram ?? null;
  const setProgram: Dispatch<SetStateAction<Program.Item | null>> = (
    program
  ) => {};
  const hasSupplier = !!supplier;
  const hasProgram = !!program;
  const ActiveStep = useMemo(() => {
    return ManualForm;
  }, []);
  const activeTitle = useMemo(() => {
    if (!supplier) return "drawerTitles.chooseSupplier";
    if (!program) return "drawerTitles.chooseProgram";
    return "drawerTitles.createProductFromCatalogue";
  }, [supplier, program]);
  const clearSupplier = () => {
    setSupplier(null);
  };
  const clearProgram = () => {
    setProgram(null);
  };
  const handleToggle = () => {
    clearSupplier();
    clearProgram();
    toggle();
  };
  return (
    <Drawer isOpen={isOpen} toggle={handleToggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark text-center">
            <Text>{activeTitle}</Text>
          </h6>
        </Drawer.Header>
        {hasSupplier && (
          <Drawer.Header className="flex flex-center text-start border-b-0">
            {hasSupplier && (
              <div className="flex-1 flex flex-center w-full gap-4">
                <Icon.Wrapper rounded>
                  <Icon name="Building" variant="Bold" />
                </Icon.Wrapper>
                <div className="flex-1">
                  <h6 className="truncate">{supplier?.name}</h6>
                  <span className="block truncate text-secondary">
                    #{supplier?.iln}
                  </span>
                </div>
              </div>
            )}
            {hasProgram && (
              <div className="flex-1 flex flex-center w-full gap-4">
                <Icon.Wrapper rounded>
                  <Icon name="DocumentText" variant="Bold" />
                </Icon.Wrapper>
                <div className="flex-1">
                  <h6 className="truncate">{program?.name}</h6>
                  <span className="block truncate text-secondary">
                    #{program?.code}
                  </span>
                </div>
              </div>
            )}
            {/* <button
              type="button"
              className="text-warning text-sm"
              onClick={handleClearData}
            >
              <Icon
                name="ArrowForwardSquare"
                variant="Bold"
                size={25}
                className="text-warning"
              />{" "}
              <Text>button.change</Text>
            </button> */}
          </Drawer.Header>
        )}
        <CreateBundleSubLineItemContext.Provider
          value={{
            parentId,
            parent,
            supplier,
            setSupplier,
            program,
            setProgram,
            toggle: handleToggle,
            onSubmitted,
          }}
        >
          {isOpen && <ActiveStep />}
        </CreateBundleSubLineItemContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
