import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
} from "../../../components";
import { Product, togglePropsType } from "../../../types";

type FilterProps = togglePropsType & {
  result: Product.WithAzureSearch;
  params: Product.Params;
  handleSetParams: (key: keyof Product.Params) => (value: any) => void;
  loading: boolean;
  onReset: () => void;
};

export default function Filter({
  isOpen,
  toggle,
  result,
  params,
  handleSetParams,
  loading,
  onReset,
}: FilterProps) {
  const disabled = loading;
  const handleReset = () => {
    onReset();
    // toggle();
  };
  return (
    <Drawer as={Form} isOpen={isOpen} toggle={toggle} onSubmit={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="text-dark text-base text-start flex-1">
            <Text>products.filter.filterTitle</Text>
          </h6>
          <button
            type="button"
            className="text-warning text-sm self-center"
            onClick={handleReset}
          >
            <Icon
              name="ArrowForwardSquare"
              variant="Bold"
              size={25}
              className="text-warning"
            />{" "}
            <Text>products.filter.resetFilterBtn</Text>
          </button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Select
            label="products.filter.productFamily"
            value={params.pimProductFamilyId ?? null}
            setValue={handleSetParams("pimProductFamilyId")}
            items={result.pimProductFamily?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            disabled={disabled}
            searchable
            all
          />
          <Select
            label="products.filter.brand"
            value={params.brandCode ?? null}
            setValue={handleSetParams("brandCode")}
            items={result.brand?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            disabled={disabled}
            searchable
            all
          />
          <Select
            label="products.filter.supplier"
            value={params.supplierId ?? null}
            setValue={handleSetParams("supplierId")}
            items={result.supplier?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            disabled={disabled}
            searchable
            all
          />
          <Select
            label="products.filter.program"
            value={params.programId ?? null}
            setValue={handleSetParams("programId")}
            items={result.program?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            disabled={disabled}
            searchable
            all
          />
          <div className="grid grid-cols-2 items-center gap-2">
            <label className="text-base block w-full mb-2 truncate col-span-full">
              <Text>products.filter.priceRange</Text>
            </label>
            <InputGroup
              value={params.priceFrom}
              setValue={handleSetParams("priceFrom")}
              placeholder="products.filter.priceFrom"
              type="price"
              disabled={disabled}
              //   rules={isEmptyValue(params.priceFrom)?[]:[rules.]}
            />
            <InputGroup
              value={params.priceTo}
              setValue={handleSetParams("priceTo")}
              placeholder="products.filter.priceTo"
              type="price"
              disabled={disabled}
              //   rules={isEmptyValue(params.priceTo)?[]:[rules.]}
            />
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger" onClick={toggle}>
            <Text>products.filter.cancelBtn</Text>
          </Button>
          <Button type="submit" variant="primary">
            <Text>products.filter.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
