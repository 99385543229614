import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Drawer,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../components";
import { config } from "../constants";
import { useAxios, useSelector } from "../hooks";
import { getRandomColor } from "../methods";
import { Employee, WithPaging, togglePropsType } from "../types";

export type SelectedEmployee = { name: string; id: string };

type ChooseEmployeeProps = togglePropsType & {
  onSelect: (employee: SelectedEmployee) => void;
};

export default function ChooseEmployee({
  isOpen,
  toggle,
  onSelect,
}: ChooseEmployeeProps) {
  const profile = useSelector((s) => s.profile);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Employee.Item>>();
  const [params, setParams] = useState<Employee.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const items = data?.items?.filter((e) => e.employeeId !== profile.employeeId);
  const handleSelect = (e: Employee.Item) => {
    return () => {
      onSelect({ id: e.employeeId, name: [e.firstName, e.lastName].join(" ") });
      toggle();
    };
  };
  const handleSetParams = (key: string) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    if (!isOpen) return;
    const url = "/accountservice/api/employees";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen, params, axios]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header className="border-b-0">
          <h6 className="text-base text-dark">
            <Text>incident.chooseEmployee.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            placeholder="incident.chooseEmployee.search"
            variant="gray"
            onSubmit={handleSetParams("keyword")}
          />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {items?.map((e, i) => (
              <List.Item key={e.employeeId} onClick={handleSelect(e)}>
                <div className="flex items-center gap-3 w-fit">
                  <Avatar color={getRandomColor(i)} />
                  <div>
                    <h6 className="text-base text-dark mb-1">
                      {e.firstName || "-"} {e.lastName || "-"}
                    </h6>
                    {/* <p className="block text-secondary">{e.cimNumber || "-"}</p> */}
                  </div>
                </div>
                <p className="flex-1 truncate">{e.emailAddress}</p>
                <Button as="span" light>
                  <Text>incident.chooseEmployee.chooseBtn</Text>
                </Button>
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
