import { Icon, Status } from "../../../components";
import { SellOffProductOldStatus } from "../../../enums";
import { SellOffProductOld } from "../../../types";
type Props = { item: SellOffProductOld.Item };
const PreparationStatusTemplate = ({ item }: Props) => {
  return (
    <div className="space-y-2">
      <h6 className="text-heading-6">
        <Status.SellOff id={SellOffProductOldStatus.Preparation} />
      </h6>
      {item?.clonedPreparationPhaseCheckListItems?.map(
        (p: SellOffProductOld.ClonedPreparationPhaseCheckListItem) => (
          <p
            key={p.id}
            className="text-gray-600 text-body-2 font-normal text-left"
          >
            <Icon
              name={p.isChecked ? "TickCircle" : "Record"}
              className="data-active:text-success mr-2 inline-block align-middle w-4 h-4"
              data-active={p.isChecked}
            />
            {p.label}
          </p>
        )
      )}
    </div>
  );
};

export default PreparationStatusTemplate;
