import { Fragment } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useConverters } from "../hooks";
import { Product } from "../types";
import Image from "./_Image";
import Text from "./_Text";
import Types from "./_Types";

type ProductCardProps = {
  product: Product.Item;
  isGrid: boolean;
};

export default function ProductCard({ product, isGrid }: ProductCardProps) {
  const { convertAmount } = useConverters();
  const hasBrandLogo = !!product.brandLogo;
  const hasBrandName = !!product.brandName;
  const hasPrice = [product.vk1Price?.amount, product.vk2Price?.amount].some(
    Boolean
  );
  const hasPrice2 =
    !!product.vk2Price?.amount &&
    product.vk1Price?.amount !== product.vk2Price?.amount;

  return (
    <Link
      to={product.id}
      className={twMerge(
        "group w-full flex bg-white shadow rounded gap-2 p-2 hover:shadow-xl transition-shadow",
        isGrid ? "flex-col" : "flex-row"
      )}
    >
      <div
        className={twMerge(
          "aspect-image rounded overflow-hidden",
          isGrid ? "w-full" : "h-28"
        )}
      >
        <Image
          src={product.avatarThumbnailUrl}
          alt={product.originalName}
          className="size-full object-cover group-hover:scale-110 transition-transform"
          isDynamic
        />
      </div>
      <div className="flex-1 flex flex-col gap-1">
        <h6 className="w-full text-dark text-base font-medium break-words">
          {product.originalName}
        </h6>
        <p className="text-sm text-secondary font-medium w-full">
          #{product.articleNumber}
        </p>
        {!isGrid && (
          <Fragment>
            <p className="text-sm text-secondary font-medium w-full">
              <Text>products.list.productGroup</Text>:{" "}
              {product.pimProductGroupName}
            </p>
            <p className="text-sm text-secondary font-medium w-full mt-auto space-x-2">
              {hasBrandLogo && (
                <Image
                  src={product.brandLogo}
                  alt={product.brandName}
                  isDynamic
                  className="inline-block h-7"
                />
              )}
              {hasBrandName && <span>{product.brandName}</span>}
              <Types.PimProduct id={product.pimProductType} />
            </p>
          </Fragment>
        )}
      </div>
      {hasPrice && (
        <div
          className={twMerge(
            "flex items-center gap-3 text-sm text-[#A1A4AC] font-semibold pt-1",
            !isGrid && "flex-col self-center gap-1 px-4"
          )}
        >
          <p className="text-primary">
            {convertAmount(product.vk1Price?.amount) ?? "--"}
          </p>
          {hasPrice2 && (
            <p className="line-through">
              {convertAmount(product.vk2Price?.amount) ?? "--"}
            </p>
          )}
        </div>
      )}
    </Link>
  );
}
