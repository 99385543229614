import { Fragment, useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { SearchProductContext } from ".";
import { OfferDetailsContext } from "../..";
import {
  Button,
  Counter,
  Drawer,
  Form,
  Icon,
  Image,
  InputGroup,
  Loading,
  Text,
  Types,
} from "../../../../../components";
import { pimProductAttributeKey, rules } from "../../../../../constants";
import { LineItemType, PimProductType } from "../../../../../enums";
import {
  useAxios,
  useConverters,
  useFindAttribute,
  useLanguage,
} from "../../../../../hooks";
import { cleanText } from "../../../../../methods";
import { LineItem, PimProduct, iconNameType } from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";
import { noteSuggestion } from "../notes";

type LineItemInfoItem = {
  label: string;
  icon: iconNameType;
  value: string | number | null | undefined;
};
export default function ManualForm() {
  const [lng] = useLanguage();
  const findAttribute = useFindAttribute();
  const formRef = useRef<HTMLFormElement>(null);
  const { convertAmount } = useConverters();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const {
    parentId,
    productId,
    clearProduct,
    toggle,
    onSubmitted,
    pimProductType,
  } = useContext(SearchProductContext);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<LineItem.Create | null>(null);
  const [pimProduct, setPimProduct] = useState<PimProduct.Details | null>(null);

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;

  const isOffer = !!offer;
  const isOrder = !!order;

  const pimProductGroup = pimProduct?.pimProductGroup;
  const hasProductGroup = !!pimProductGroup;
  const lineItemType =
    pimProductType === PimProductType.Bundle
      ? LineItemType.PimBundle
      : LineItemType.PimProduct;

  const hasLoading = [
    loading.post,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  const lineItemInfo: LineItemInfoItem[] = [
    {
      label: "global.lineItemProductTitle",
      icon: "Hashtag",
      value: data?.product?.title ?? "--",
    },
    {
      label: "global.articleNumber",
      icon: "Hashtag",
      value: "#" + pimProduct?.articleNumber,
    },
    {
      label: "global.supplierName",
      icon: "Building",
      value: pimProduct?.supplier?.name,
    },
    {
      label: "global.supplierArticleNumber",
      icon: "Building",
      value: pimProduct?.supplierArticleNumber ?? "--",
    },
    {
      label: "global.programName",
      icon: "Document",
      value: pimProduct?.supplier?.program?.name,
    },
    {
      label: "global.lineItemNormalPrice",
      icon: "DollarSquare",
      value: convertAmount(pimProduct?.vk2Price?.amount),
    },
    {
      label: "global.lineItemSalesPrice",
      icon: "DollarSquare",
      value: convertAmount(pimProduct?.vk1Price?.amount),
    },
    {
      label: "global.lineItemProductGroupTitle",
      icon: "TableDocument",
      value: hasProductGroup
        ? `${pimProductGroup.name} (#${pimProductGroup.code})`
        : "--",
    },
  ];
  const getPimProduct = async (id: string) => {
    const url = `/salesservice/api/salestoolspimproduct/${id}`;
    return await axios
      .get(url)
      .then((res) => res.data as PimProduct.Details)
      .catch(() => null);
  };
  const findTranslate = (
    e: PimProduct.Details | null,
    code: keyof typeof pimProductAttributeKey
  ) => {
    const translates = findAttribute(e?.attributeGroups, code);
    const translate = translates?.find(
      (e) => e.language === lng || !e.language
    );
    // return cleanText(translate?.labelTitle || translates?.[0]?.labelTitle) || ""
    return cleanText(translate?.labelTitle) || "";
  };
  const getInitData = () => {
    getPimProduct(productId).then((pimProduct) => {
      if (!pimProduct) return;
      const supplier = pimProduct.supplier ?? null;
      const hasSupplier = !!supplier;
      const program = pimProduct.supplier?.program ?? null;
      const hasProgram = !!program;
      const productGroup = pimProduct.pimProductGroup ?? null;
      const hasProductGroup = !!productGroup;
      const initData: LineItem.Create = {
        parentId: parentId,
        offerId: offer?.id,
        orderId: order?.id,
        title: findTranslate(pimProduct, "title") || pimProduct.originalName,
        description: findTranslate(pimProduct, "descriptions"),
        product: {
          title: findTranslate(pimProduct, "title") || pimProduct.originalName,
          originalName: pimProduct.originalName,
          articleNumber: pimProduct.articleNumber,
          shortDescription: findTranslate(pimProduct, "shortDescription"),
          pimProductId: pimProduct.id,
          pimProductSalesPersonCommission: pimProduct.salesPersonCommission,
        },
        productGroup: hasProductGroup
          ? {
              code: productGroup?.code ?? null,
              title: productGroup?.name ?? null,
              pimProductGroupId: productGroup?.pimProductGroupId ?? null,
            }
          : null,
        supplier: hasSupplier
          ? {
              code: supplier?.code ?? null,
              iln: supplier?.iln ?? null,
              name: supplier?.name ?? null,
              supplierArticleNumber: pimProduct.supplierArticleNumber ?? null,
              supplierId: supplier?.supplierId ?? null,
            }
          : null,
        supplierProgram: hasProgram
          ? {
              code: program?.programCode ?? null,
              iwofurnCode: program?.iwofurnCode ?? null,
              name: program?.name ?? null,
              supplierProgramId: program?.supplierProgramId ?? null,
              zrNumber: program?.zrNumber ?? null,
            }
          : null,
        lineItemNote: null,
        supplierNote: null,
        customerNote: null,
        ean: null,
        quantity: 1,
        shippingMethodCode: null,
        lineItemType: lineItemType,
      };
      setPimProduct(pimProduct);
      setData(initData);
    });
  };
  const handleSetValue = (key: keyof LineItem.Create) => {
    return (value: unknown) => {
      setData((p) => ({ ...p!, [key]: value }));
    };
  };
  const handleSubmit = () => {
    formRef.current?.requestSubmit();
  };
  const submit = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      pimProductType === PimProductType.Standard && "pim-product",
      pimProductType === PimProductType.Bundle && "pim-bundle-product",
    ]
      .filter(Boolean)
      .join("/");

    const body = { ...data };
    body.description ||= null;
    body.lineItemNote ||= null;
    body.customerNote ||= null;
    body.supplierNote ||= null;
    axios.post(url, body).then(() => {
      (updateOffer ?? updateOrder)?.().then(() => {
        toast.success("toast.success.addLineItem");
        onSubmitted();
        toggle();
      });
    });
  };
  useEffect(getInitData, []);
  return (
    <Fragment>
      <Drawer.Header className="flex items-center text-start gap-4">
        <h6 className="flex-1 text-dark text-base">
          <Text>drawerTitles.addProduct</Text>
        </h6>
        <button type="button" className="text-warning" onClick={clearProduct}>
          <Icon name="ArrowForward" variant="Bold" /> <Text>button.change</Text>
        </button>
      </Drawer.Header>
      <Drawer.Body>
        {loading.get ? (
          <Loading.PimProductDetails />
        ) : (
          <Form ref={formRef} onSubmit={submit} className="space-y-5">
            <button hidden type="submit" />
            <section className="flex items-center gap-4 border-b border-dashed pb-4">
              <Image
                src={pimProduct?.avatar?.thumbnailUrl}
                alt={pimProduct?.originalName}
                className="w-32 h-20 object-cover rounded shadow"
                isDynamic
              />
              <div className="flex-1 space-y-1">
                <Types.LineItem id={LineItemType.PimProduct} />
              </div>
            </section>
            <table className="w-full text-sm font-medium [&_td]:py-3">
              <tbody>
                {lineItemInfo.map((e) => (
                  <tr key={e.label} className="border-b border-dashed">
                    <td className="text-secondary text-start">
                      <Icon name={e.icon} variant="Bold" />{" "}
                      <Text>{e.label}</Text>
                    </td>
                    <td className="text-gray-900 text-end">{e.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <InputGroup
              label="formControls.lineItemTitle"
              value={data?.title}
              setValue={handleSetValue("title")}
              rules={rules.required}
              className="w-auto flex-1"
            />
            <div className="grid grid-cols-3">
              <Counter
                label="formControls.quantity"
                value={data?.quantity}
                setValue={handleSetValue("quantity")}
                className="w-auto basis-1/3"
                type="decimal"
                min={0}
              />
            </div>
            <InputGroup
              as="textarea"
              label="formControls.description"
              value={data?.description}
              setValue={handleSetValue("description")}
              suggestionData={noteSuggestion}
              snippet
            />
            <InputGroup
              as="textarea"
              label="formControls.lineItemNote"
              value={data?.lineItemNote}
              setValue={handleSetValue("lineItemNote")}
              suggestionData={noteSuggestion}
              snippet
            />
            <InputGroup
              as="textarea"
              label="formControls.lineItemCustomerNote"
              value={data?.customerNote}
              setValue={handleSetValue("customerNote")}
              suggestionData={noteSuggestion}
              snippet
            />
            <InputGroup
              as="textarea"
              label="formControls.lineItemSupplierNote"
              value={data?.supplierNote}
              setValue={handleSetValue("supplierNote")}
              suggestionData={noteSuggestion}
              snippet
            />
            <button type="submit" hidden disabled={hasLoading} />
          </Form>
        )}
      </Drawer.Body>
      <Drawer.Footer className="flex items-center justify-end gap-4">
        <Button type="button" variant="danger" onClick={clearProduct}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          disabled={loading.get}
          loading={hasLoading}
          onClick={handleSubmit}
        >
          <Text>button.addProduct</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
