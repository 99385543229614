import { ComponentProps, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import imageLoadingGif from "../assets/icons/image Loading.gif";
import thumbnail from "../assets/icons/image.jpeg";
import { useGetFile } from "../hooks";
type imageProps = {
  src?: string | null;
  alt?: string | null;
  // thumbnail?: string;
  isDynamic?: boolean;
};
type imgProps = Omit<ComponentProps<"img">, keyof imageProps>;
export default function Image({
  src,
  alt,
  // thumbnail = imageIcon,
  isDynamic = false,
  className,
  ...props
}: imageProps & imgProps) {
  const [getFile] = useGetFile();
  const imageRef = useRef<HTMLImageElement>(null);
  const hasSrc = !!src;
  const imageSrcHandler = () => {
    const element = imageRef.current;
    if (!hasSrc || !element) return;
    const isLazy = isDynamic && src.includes("/get-file");
    if (isLazy) {
      getFile(src).then((src) => {
        element.src = src ?? thumbnail;
      });
      return;
    }
    const image = new window.Image();
    image.src = src;
    image.onload = () => (element.src = src);
    image.onerror = () => (element.src = thumbnail);
  };
  useEffect(imageSrcHandler, [isDynamic, src, hasSrc]);
  return (
    <img
      ref={imageRef}
      src={hasSrc ? imageLoadingGif : thumbnail}
      alt={alt ?? undefined}
      loading={props.loading ?? "lazy"}
      data-src={src}
      className={twMerge(className, !hasSrc && "bg-[#eaedf2]")}
      {...props}
    />
  );
}
