import { dateFormats } from "../constants";
import { isEmptyValue } from "../methods";
import useLanguage from "./useLanguage";

export default function useConverters() {
  const [locale] = useLanguage();
  const convertAmount = (amount: number | null | undefined) => {
    if (isEmptyValue(amount)) return null;
    return (
      amount?.toLocaleString(locale, {
        style: "currency",
        currency: "EUR",
      }) ?? null
    );
  };
  const convertNumber = (amount: number | null | undefined) => {
    if (isEmptyValue(amount)) return null;
    return (
      amount?.toLocaleString(locale, {
        style: "decimal",
        useGrouping: false,
      }) ?? null
    );
  };
  const convertDate = (
    date: string | number | Date | null | undefined,
    format: Intl.DateTimeFormatOptions = dateFormats.onlyDate
  ) => {
    if (!date) return null;
    const value = new Date(date);
    return value.toLocaleString(locale, format);
  };
  return { convertAmount, convertDate, convertNumber };
}
