import { useContext } from "react";
import { Wrapper, Text } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { saleProccessContext } from ".";

const PriceTemplate = () => {
  const { convertAmount } = useConverters();
  const { data } = useContext(saleProccessContext);
  return (
    <Wrapper>
      <Wrapper.Header>
        <h2 className="text-base">
          <Text>sellOffProducts.price</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body>
        <div className="space-y-4 text-heading-5 font-semibold">
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>
                sellOffProducts.supplierPrice
              </Text>{" "}
              :
            </p>
            <p className="text-heading-4 text-gray-600">
              {data?.supplierPrice < 0 ? "--" : convertAmount(data?.supplierPrice)}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>
                sellOffProducts.regularPrice
              </Text>
              :
            </p>
            <p className="text-heading-5 text-gray-600">
              {data?.originalPrice < 0 ? "--" : convertAmount(data?.originalPrice)}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>sellOffProducts.discount</Text>:
            </p>
            <p className="text-danger text-heading-5">
              {data?.currentDiscount}%
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>sellOffProducts.phasePrice</Text>
              :
            </p>
            <p className="text-primary-active text-heading-2">
              {data?.currentPrice < 0 ? "--" : convertAmount(data?.currentPrice)}
            </p>
          </div>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
};

export default PriceTemplate;
