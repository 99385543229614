import { Fragment, useContext } from "react";
import { ProductDetailsContext } from "..";
import { Accordion, NoItems, Translate, Wrapper } from "../../../../components";

export default function Attributes() {
  const { product } = useContext(ProductDetailsContext);
  const attributes = product.attributeGroups;
  const defaultActiveKey = attributes?.[0]?.code ?? null;
  if (!attributes?.length) return <NoItems />;
  return (
    <Fragment>
      {attributes.map((e) => (
        <Wrapper key={e.code}>
          <Accordion defaultActiveKey={defaultActiveKey}>
            <Accordion.Item eventKey={e.code}>
              <Accordion.Toggle>
                <h2 className="flex-1 text-base text-start text-dark">
                  <Translate value={e.translates} defaultValue="--" />
                </h2>
              </Accordion.Toggle>
              <Accordion.Body>
                <table className="w-full">
                  <tbody className="divide-y">
                    {!!e.attributes?.length ? (
                      e.attributes?.map((e) => {
                        const label = e.translates;
                        const value =
                          e.pimProductAttribute?.pimProductAttributeValue?.map(
                            (e) => ({
                              language: e.language,
                              labelTitle: e.value?.toString() ?? "",
                              description: e.value?.toString() ?? "",
                            })
                          );
                        return (
                          <tr key={e.code} className="*:py-3">
                            <td className="text-secondary">
                              <Translate value={label} defaultValue="--" />
                            </td>
                            <td className="text-gray-900 font-medium px-3 text-end">
                              <Translate value={value} defaultValue="--" />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={2}>--</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Wrapper>
      ))}
    </Fragment>
  );
}
