import { createContext, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Button } from "../../../components";
import Image from "../../../components/_Image";
import Text from "../../../components/_Text";
import { useToggle } from "../../../hooks";
import { SellOffProductOld } from "../../../types";
import ActiveStatusTemplate from "./ActiveStatusTemplate";
import PreparationStatusTemplate from "./PreparationStatusTemplate";
import PriceTemplate from "./PriceTemplate";
import SoldStatusTemplate from "./SoldStatusTemplate";

type ProductCardProps = {
  product: SellOffProductOld.Item;
  isGrid: boolean;
};
export const SellOffProductsContext = createContext({ id: "" });
export default function SellOffProductCard({
  product,
  isGrid,
}: ProductCardProps) {
  const [selectedID, setSelectedID] = useState("");
  const [changePhase, setChangePhase] = useToggle(false);
  const [closePhase, setClosePhase] = useToggle(false);
  const [startPrePhase, setStartPrePhase] = useToggle(false);

  const createPhaseSection = (item: SellOffProductOld.Item) => {
    const isSold = !!item.isSold;
    const isActive = !!item.currentPhaseDay;
    if (isSold) return <SoldStatusTemplate item={item} />;
    if (isActive) return <ActiveStatusTemplate item={item} />;
    return <PreparationStatusTemplate item={item} />;
  };

  const changePhaseCheckList = (item: any) => {
    setSelectedID(item.sellOffProductId);
    setChangePhase();
  };

  const closePhaseHandler = (item: any) => {
    setSelectedID(item.sellOffProductId);
    setClosePhase();
  };

  const preparationCheckList = (item: SellOffProductOld.Item) => {
    setSelectedID(item.sellOffProductId);
    setStartPrePhase();
  };

  return (
    <Link
      to={product.sellOffProductId}
      className={twMerge(
        "group w-full flex bg-white shadow rounded gap-2 p-2 hover:shadow-xl transition-shadow",
        isGrid ? "flex-col" : "flex-row items-center"
      )}
    >
      <div
        className={twMerge(
          "aspect-image rounded overflow-hidden",
          isGrid ? "w-full" : "h-28"
        )}
      >
        <Image
          src={product.assets?.[0]?.thumbnailUrl}
          alt={product.originalName}
          className="size-full object-cover group-hover:scale-110 transition-transform"
          isDynamic
        />
      </div>
      <div className="flex-1 flex flex-col gap-1">
        <h6 className="w-full text-dark text-base font-medium break-words">
          {product.title}
        </h6>
        <p className="text-sm text-secondary font-medium w-full">
          #{product.articleNumber}
        </p>
        {!isGrid && (
          <Fragment>
            <p className="text-sm text-secondary font-medium w-full">
              <Text>sellOffProducts.productFamily</Text>:{" "}
              {product.productFamilyName}
            </p>
            <p className="text-sm text-secondary font-medium w-full mt-auto">
              <Text>sellOffProducts.supplier</Text>: {product.supplierName}
            </p>
            <p className="text-sm text-secondary font-medium w-full mt-auto">
              <Text>sellOffProducts.program</Text>: {/* todo */}
              {/* {product.programName} */}
              program name!
            </p>
          </Fragment>
        )}
      </div>
      <div className="flex-1 flex flex-col gap-1">
        <h6 className="w-fit text-left text-heading-6">
          {createPhaseSection(product)}
        </h6>
      </div>
      <div className="text-heading-5">
        <PriceTemplate item={product} className="space-y-2" />
      </div>
      <div>
        {!product.currentPhaseDay ? (
          <Button
            onClick={() => preparationCheckList(product)}
            className="text-sm"
            type="button"
          >
            <Text>sellOffProducts.startProccess</Text>
          </Button>
        ) : !!product.changePhaseRequired ? (
          <Button
            variant={"warning"}
            onClick={() => changePhaseCheckList(product)}
            className="text-sm"
            type="button"
          >
            <Text>sellOffProducts.changePhase</Text>
          </Button>
        ) : !!product.closePhaseRequired ? (
          <Button
            variant={"danger"}
            onClick={() => closePhaseHandler(product)}
            className="text-sm"
            type="button"
          >
            <Text>sellOffProducts.closeProccess</Text>
          </Button>
        ) : null}
      </div>
      <SellOffProductsContext.Provider
        value={{ id: selectedID }}
      ></SellOffProductsContext.Provider>
    </Link>
  );
}
