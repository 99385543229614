import { Fragment, useContext, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { OfferDetailsContext } from "..";
import { Badge, Button, Icon, Text, Types } from "../../../../components";
import { LineItemType } from "../../../../enums";
import { useConverters, useToggle } from "../../../../hooks";
import { LineItem } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";
import CancelLineItem from "./CancelLineItem";
import EditLineItem from "./EditLineItem";
import LineItemInfo from "./LineItemInfo";
import RemoveLineItem from "./RemoveLineItem";
type LineItemRowProps = {
  lineItem: LineItem.Item;
  getLineItems: () => void;
  onSetParentId: (id: string) => void;
  hasSubLineItem?: boolean;
};
export default function LineItemRow({
  lineItem,
  getLineItems,
  onSetParentId,
  hasSubLineItem,
}: // hasSubLineItem,
LineItemRowProps) {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const isOffer = !!offerContext;
  const isOrder = !!orderContext;
  const isCanceled = lineItem.isCanceled;
  const isEnabled =
    offerContext?.isEnabled ?? (orderContext?.isEnabled && !isCanceled);
  const isOrderPublished = !!orderContext?.isPublished;

  const { convertAmount, convertNumber } = useConverters();
  const isSubLineItem = !!lineItem.parentId;
  const canCancel = isOrder && !isSubLineItem;
  const canRemove = useMemo(() => {
    if (isOffer) return !!isEnabled;
    if (isSubLineItem) return !isOrderPublished;
    return false;
  }, [isOffer, isEnabled, isSubLineItem, isOrderPublished]);
  const isBundle = lineItem.lineItemType === LineItemType.ManualBundle;
  const showArrowBottom = !isSubLineItem && hasSubLineItem;
  const hasLineNumber = !!lineItem.lineNumber;
  const [showInfo, toggleInfo] = useToggle(false);
  const [showCancel, toggleCancel] = useToggle(false);
  const [showRemove, toggleRemove] = useToggle(false);

  const [edit, setEdit] = useState<keyof LineItem.Item | null>(null);
  const showEdit = edit !== null;
  const withOutBorder = isSubLineItem;
  const toggleEdit = () => {
    setEdit(null);
  };
  const hasDiscount = useMemo(() => {
    return lineItem.originalPrice > lineItem.unitPriceWithDiscount;
  }, [lineItem]);
  // !!lineItem.discounts?.length &&
  //   lineItem.unitPriceWithDiscount !== lineItem.originalPrice;
  const hasSupplier = !!lineItem.supplier?.name;
  const shippingMethod = lineItem.shippingMethodCode;
  const hasShippingMethod = !!shippingMethod;
  const articleNumber = lineItem.product?.articleNumber;
  const hasArticleNumber = !!articleNumber;
  const handleSelectAddSubLineItem = () => {
    onSetParentId(lineItem.id);
  };
  return (
    <Fragment>
      <tr
        key={lineItem.id}
        className={twMerge(
          withOutBorder ? "!border-t-0" : "!border-t-[#A1A4AC]",
          !isSubLineItem && "[&>td]:!py-6",
          isCanceled && "opacity-50"
        )}
      >
        <td>
          <div className="flex items-center gap-4">
            <Icon
              name="ArrowDown2"
              variant="Bold"
              size={22}
              data-active={showArrowBottom}
              className="text-primary opacity-0 pointer-events-none data-active:opacity-100 data-active:pointer-events-auto"
            />
            {/* {isSubLineItem && <span className="w-10 h-10" />} */}
            <button
              type="button"
              className="relative flex-1 flex items-center text-start gap-4"
              onClick={toggleInfo}
            >
              {hasLineNumber && (
                <div className="absolute -top-2 left-0 w-11 flex-center">
                  <span className="block w-fit whitespace-nowrap min-w-9 text-center text-2xs font-medium bg-[#EDEADE] text-[#7F7E76] px-2 py-0.5 rounded-md">
                    {lineItem.lineNumber}
                  </span>
                </div>
              )}
              <div className="flex-center size-11">
                <Icon.Wrapper
                  variant="primary"
                  className={twMerge(
                    isBundle && "bg-opacity-100 text-white",
                    isSubLineItem && "bg-opacity-100 p-1"
                  )}
                >
                  {isBundle ? (
                    <Icon
                      name="Dropbox"
                      variant="Bold"
                      size={isSubLineItem ? 24 : 28}
                    />
                  ) : (
                    <Icon
                      name={isBundle ? "Dropbox" : "Box1"}
                      variant={isSubLineItem ? "Bold" : "Linear"}
                      size={isSubLineItem ? 0 : 28}
                      className={isSubLineItem ? "opacity-0" : ""}
                    />
                  )}
                </Icon.Wrapper>
              </div>
              <div className="flex-1 space-y-1">
                <h6 className="w-full flex items-start gap-1 text-base font-normal">
                  <span className="text-balance max-w-72 whitespace-normal line-clamp-2">
                    {lineItem.title}
                  </span>
                  <span className="min-w-6 size-6 flex-center">
                    <Icon
                      name="InfoCircle"
                      size={18}
                      className={
                        hasShippingMethod ? "text-primary" : "text-warning"
                      }
                    />
                  </span>
                </h6>
                {hasArticleNumber && (
                  <p className="block text-secondary">#{articleNumber}</p>
                )}
                <p className="space-x-1 !mt-2">
                  <Types.LineItem id={lineItem.lineItemType} />
                  <Badge
                    variant={hasShippingMethod ? "dark" : "warning"}
                    className="p-1 text-xs leading-none"
                  >
                    <Icon name="TruckFast" variant="Bulk" />
                    {hasShippingMethod ? (
                      shippingMethod
                    ) : (
                      <Icon name="Danger" variant="Bold" />
                    )}
                  </Badge>
                </p>
              </div>
            </button>
          </div>
        </td>
        <td className="text-sm">
          {hasSupplier ? (
            <Fragment>
              <p className="text-gray-900">
                <Icon name="Building" variant="Bulk" />{" "}
                {lineItem.supplier?.name ?? "--"}
              </p>
              <p className="text-secondary ps-5">
                #{lineItem.supplier?.iln ?? "--"}
              </p>
            </Fragment>
          ) : (
            "--"
          )}
        </td>
        <td className="text-sm">
          <p className="text-dark">
            {convertAmount(lineItem.unitPriceWithDiscount)}
          </p>
          {hasDiscount && (
            <p className="text-secondary line-through">
              {convertAmount(lineItem.originalPrice)}
            </p>
          )}
        </td>
        <td className="text-dark text-sm">
          x{convertNumber(lineItem.quantity)}
        </td>
        <td className="text-dark text-sm">
          {convertAmount(lineItem.totalSalePrice)}
        </td>
        {isEnabled && (
          <td data-sticky>
            <Button light onClick={toggleInfo} className="btn-sm">
              <Text>button.lineItemDetails</Text>
            </Button>
          </td>
        )}
      </tr>
      <LineItemInfo
        isOpen={showInfo}
        toggle={toggleInfo}
        lineItem={lineItem}
        canCancel={canCancel}
        canRemove={canRemove}
        onEdit={setEdit}
        onAddSubLineItem={handleSelectAddSubLineItem}
        onRemove={toggleRemove}
        onCancel={toggleCancel}
      />
      {isEnabled && (
        <Fragment>
          <EditLineItem
            isOpen={showEdit}
            toggle={toggleEdit}
            lineItem={lineItem}
            isSubLineItem={isSubLineItem}
            onSubmitted={getLineItems}
            isTitle={edit === "title"}
            isPrice={edit === "originalPrice"}
            isDescription={edit === "description"}
            isLineItemNote={edit === "lineItemNote"}
            isCustomerNote={edit === "customerNote"}
            isSupplierNote={edit === "supplierNote"}
            isConfigurationText={edit === "configurationText"}
            isQuantity={edit === "quantity"}
          />
          {canCancel && (
            <CancelLineItem
              isOpen={showCancel}
              toggle={toggleCancel}
              lineItem={lineItem}
              isSubLineItem={isSubLineItem}
            />
          )}
          {canRemove && (
            <RemoveLineItem
              isOpen={showRemove}
              toggle={toggleRemove}
              lineItem={lineItem}
              isSubLineItem={isSubLineItem}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
