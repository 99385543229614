import { Fragment, ReactNode, useCallback } from "react";
import {
  Accordion,
  Button,
  Icon,
  Text,
  Translate,
  Wrapper,
} from "../../../components";
import { randomUUID } from "../../../methods";
import { Product } from "../../../types";

type RenderProductFamiliesProps = {
  data: Product.ProductFamily[];
  onSelect: (id: string | null) => void;
};

export default function RenderProductFamilies({
  data,
  onSelect,
}: RenderProductFamiliesProps) {
  const allCount = data.reduce((a, b) => a + b.count, 0);
  const productFamilies = useCallback((families: Product.ProductFamily[]) => {
    const items: ReactNode[] = [];
    families.forEach((e) => {
      const id = randomUUID();
      const subProductFamilies = data.filter(
        ({ parentId }) => parentId === e.pimProductFamilyId
      );
      const hasSubFamilies = !!subProductFamilies?.length;
      const element = (
        <Accordion key={e.pimProductFamilyId}>
          <Accordion.Item className="border-none">
            <Wrapper>
              <Wrapper.Body className="flex items-center gap-4">
                <div className="flex-1">
                  <h6 className="text-base text-dark font-medium">
                    <Translate value={e.translates} />
                  </h6>
                  <p className="text-sm text-secondary font-medium">{e.code}</p>
                  <p className="text-sm text-gray-900 font-medium">
                    (<Text count={e.count}>products.filter.productCount</Text>)
                  </p>
                </div>
                <Button
                  type="button"
                  variant="primary"
                  light
                  onClick={() => onSelect(e.pimProductFamilyId)}
                >
                  <Text>products.filter.chooseProductFamily</Text>
                </Button>
                {hasSubFamilies && (
                  <Fragment>
                    <Accordion.Toggle
                      id={id}
                      className="hidden [&[data-active='true']+*>svg]:rotate-180"
                    />
                    <Button as="label" htmlFor={id} variant="primary" light>
                      <Icon
                        name="ArrowDown2"
                        className="transition-transform"
                      />
                    </Button>
                  </Fragment>
                )}
              </Wrapper.Body>
            </Wrapper>
            {hasSubFamilies && (
              <Accordion.Body className="*:space-y-5 px-4">
                {productFamilies(subProductFamilies)}
              </Accordion.Body>
            )}
          </Accordion.Item>
        </Accordion>
      );
      items.push(element);
    });
    return items;
  }, []);
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="flex items-center gap-4">
          <div className="flex-1">
            <h6 className="text-base text-dark font-medium">
              <Text>products.filter.allProductFamilies</Text>
            </h6>
            <p className="text-sm text-secondary font-medium opacity-0">--</p>
            <p className="text-sm text-gray-900 font-medium">
              (<Text count={allCount}>products.filter.productCount</Text>)
            </p>
          </div>
          <Button
            type="button"
            variant="primary"
            light
            onClick={() => onSelect(null)}
          >
            <Text>products.filter.chooseProductFamily</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
      {productFamilies(data.filter((e) => !e.parentId))}
    </Fragment>
  );
}
