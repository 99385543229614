import {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { rules } from "../../../../constants";
import { showFileName } from "../../../../methods";
import { Document, togglePropsType } from "../../../../types";
import { useUploadFile } from "../../../../hooks";
import { Drawer, Form, Text, InputGroup, Button } from "../../../../components";

type Data = Document.Create;

type PersonalDocumentProps = togglePropsType & {
  onSubmit: () => void;
  loading: boolean;
};

export default function AddMediaDrawer({
  isOpen,
  toggle,
  onSubmit,
  loading
}: PersonalDocumentProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadFile, fileLoading] = useUploadFile();
  const [data, setData] = useState<Data>({});
  const acceptableTypes = "image/*, .doc, .docx, .pdf, .xls, .xlsx";
  const clearData = () => {
    setData({});
  };
  const handleSetValue = (key: keyof Data) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    uploadFile(file).then((e) => {
      const documentFileInfo = {
        fileSize: e.metaData.find((e) => e.key === "ActualSize")?.value ?? "0",
        fileUrl: e.url,
        mimeType: e.type,
      };
      setData((p) => ({ ...p, documentFileInfo }));
    });
  };
  const clickInput = () => {
    inputRef.current?.click();
  };
  const clearInputValue = () => {
    const input = inputRef.current;
    input?.value && (input.value = "");
  };
  const handleToggleMenu = () => {
    toggle();
  };
  const resetData = () => {
    if (!isOpen) return;
    setData({});
    const element = inputRef.current;
    if (!element) return;
    element.value = "";
  };
  useEffect(resetData, [isOpen]);
  return (
    <Drawer
      as={Form}
      onSubmit={onSubmit}
      isOpen={isOpen}
      toggle={handleToggleMenu}
      className="z-40"
    >
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.lineItemDocument</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Fragment>
            <InputGroup
              label="formControls.documentTitle"
              value={data.title}
              setValue={handleSetValue("title")}
              rules={rules.required}
              placeholder="placeholders.documentTitle"
            />
            <InputGroup
              as="textarea"
              label="formControls.note"
              value={data.description}
              setValue={handleSetValue("description")}
              placeholder="placeholders.documentNote"
            />
          </Fragment>
          <div className="border border-gray-200 rounded flex flex-center gap-4 p-2">
            <span>
              <Text>formControls.file</Text>
            </span>
            <span className="flex-1 truncate">
              {showFileName(data.documentFileInfo?.fileUrl)}
            </span>
            <input
              ref={inputRef}
              hidden
              type="file"
              onChange={handleUploadFile}
              accept={acceptableTypes}
            />
            <Button
              onClick={clickInput}
              light
              className="btn-sm"
              loading={fileLoading}
            >
              <Text>button.chooseFile</Text>
            </Button>
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle} disabled={loading}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" disabled={fileLoading} loading={loading}>
            <Text>button.createDocument</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
