import { useAccount } from "@azure/msal-react";
import Axios, {
  AxiosProgressEvent,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { cloneDeep } from "lodash";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { authInstance, config, loginRequest } from "../constants";
import { getToken, hash } from "../methods";
import { axiosLoadingType } from "../types";
import useDefaultSaleChannel from "./useDefaultSaleChannel";

declare module "axios" {
  export interface AxiosRequestConfig {
    cache?: boolean;
    error?: boolean;
    subscriptionKey?: boolean;
  }
}

type Data = { [key: string]: any };
const data: Data = {};

export default function useAxios() {
  const retryCountRef = useRef(0);
  const navigate = useNavigate();
  const saleChannel = useDefaultSaleChannel();
  const account = useAccount();
  const idTokenRef = useRef<string | null>(null);
  const { i18n } = useTranslation();
  const axios = useMemo(() => Axios.create({ baseURL: config.baseUrl }), []);
  const abortController = useMemo(() => new AbortController(), []);
  const [getLoading, setGetLoading] = useState<string[]>([]);
  const [postLoading, setPostLoading] = useState<string[]>([]);
  const [updateLoading, setUpdateLoading] = useState<string[]>([]);
  const [deleteLoading, setDeleteLoading] = useState<string[]>([]);
  const [progress, setProgress] = useState(0);
  const loading: axiosLoadingType = {
    get: !!getLoading.length,
    post: !!postLoading.length,
    update: !!updateLoading.length,
    delete: !!deleteLoading.length,
  };
  const updateRetryValue = () => {
    retryCountRef.current += 1;
  };
  const resetRetryValue = () => {
    retryCountRef.current = 0;
  };
  const canRetry = () => {
    const max = 5;
    return retryCountRef.current < max;
  };
  const hasAccount = () => !!account;
  const hasAccountIdToken = () => !!account?.idToken;
  const hasIdToken = () => !!idTokenRef.current;
  const getRefreshToken = async () => {
    const idToken = (await getToken()) || null;
    idTokenRef.current = idToken;
    return idToken;
  };
  const generateDataKey = (req: InternalAxiosRequestConfig) => {
    if (!req.baseURL || !req.url) return "";
    const url = new URL(req.baseURL);
    url.pathname = req.url;
    Object.keys(req.params || {}).forEach((key) => {
      url.searchParams.set(key, req.params?.[key]);
    });
    const string = url.toString().replace(url.origin, "");
    return hash(string);
  };
  const loadingHandler = (method: string | undefined, value: boolean) => {
    const isGet = method === "get";
    const isPost = method === "post";
    const isUpdate = method === "put" || method === "patch";
    const isDelete = method === "delete";
    isGet &&
      setGetLoading((p) => {
        const loading = cloneDeep(p);
        value ? loading.push("") : loading.pop();
        return loading;
      });
    isPost &&
      setPostLoading((p) => {
        const loading = cloneDeep(p);
        value ? loading.push("") : loading.pop();
        return loading;
      });
    isUpdate &&
      setUpdateLoading((p) => {
        const loading = cloneDeep(p);
        value ? loading.push("") : loading.pop();
        return loading;
      });
    isDelete &&
      setDeleteLoading((p) => {
        const loading = cloneDeep(p);
        value ? loading.push("") : loading.pop();
        return loading;
      });
  };
  const progressHandler = (progressEvent: AxiosProgressEvent) => {
    const total = progressEvent.total ?? 1;
    const loaded = progressEvent.loaded;
    const percent = (loaded * 100) / total;
    const progress = Math.min(Math.round(percent), 100);
    setProgress(progress);
  };
  const handleErrors = (err: any) => {
    const detail = err?.response?.data?.detail;
    const title = err?.response?.data?.title;
    const message = err?.response?.data?.message;
    const error = detail || title || message;
    const status = err?.response?.status;
    const isInvalid = status === 400;
    const isNotFount = status === 404;
    const isConflict = status === 409;
    const isTeapot = status === 418;
    // const isServerError = status === 500;
    if (isTeapot) return navigate("/notfound");
    if (isConflict || isInvalid || isNotFount) {
      const errorMessage = `errorCodes.${error}`;
      const hasErrorMessage = i18n.exists(errorMessage);
      return toast.error(hasErrorMessage ? errorMessage : `Error: ${error}`);
    }
    toast.error("errorCodes.default");
  };
  const reqHandler = async (req: InternalAxiosRequestConfig<any>) => {
    loadingHandler(req.method, true);

    const isSalesService = req.url?.includes("salesservice");
    if (isSalesService) {
      const channelCode = req.params?.channelCode;
      const hasChannelCode = channelCode !== undefined;
      req.params ||= {};
      req.params.channelCode = hasChannelCode ? channelCode : saleChannel?.code;
    }

    const isGet = req.method === "get";
    const isPost = req.method === "post";
    const isPut = req.method === "put";
    const isPatch = req.method === "patch";
    const cache = !!req.cache;
    const subscriptionKey = req.subscriptionKey ?? true;
    const dataKey = generateDataKey(req);
    const hasData = data[dataKey];
    const needCache = [cache, hasData].every(Boolean);

    const adapterData = {
      config: req,
      request: req,
      headers: req.headers,
      data: data[dataKey],
      status: 200,
      statusText: "OK",
    };

    req.signal = abortController.signal;

    if (!!req.data && isSalesService) {
      const jsonData = JSON.stringify(req.data)
        .replace(`"optionalDiscountIds":null`, `"optionalDiscountIds":[]`)
        .replaceAll(":null", `:""`);
      req.data = JSON.parse(jsonData);
    }
    if (isGet) {
      req.onDownloadProgress = progressHandler;
    }
    if ([isPost, isPut, isPatch].some(Boolean)) {
      req.onUploadProgress = progressHandler;
    }
    if (needCache) {
      req.adapter = () => Promise.resolve(adapterData);
      return req;
    }
    if (!hasAccount() && !hasIdToken()) {
      authInstance.loginRedirect(loginRequest);
      return req;
    }
    if (hasAccountIdToken()) {
      idTokenRef.current ||= account?.idToken ?? null;
    }
    if (!hasIdToken()) {
      await getRefreshToken();
    }
    if (hasIdToken()) {
      req.headers["Authorization"] = `Bearer ${idTokenRef.current}`;
    }
    if (subscriptionKey) {
      req.headers["Ocp-Apim-Subscription-Key"] = config.subscriptionKey;
    }
    return req;
  };
  const resHandler = (res: AxiosResponse<any>) => {
    loadingHandler(res.config.method, false);
    setProgress(0);
    resetRetryValue();
    const isNoContent = res.status === 204;
    const cache = !!res.config?.cache;
    if (isNoContent) return Promise.reject({ response: res });
    if (cache) {
      const key = generateDataKey(res.config);
      const value = res.data;
      data[key] = value;
    }
    return res;
  };
  const errHandler = async (err: any) => {
    loadingHandler(err?.config?.method, false);
    setProgress(0);
    const isCanceled = err?.code === "ERR_CANCELED";
    const isUnauthorized = err?.response?.status === 401;
    const error = err?.config?.error ?? true;
    // const isNetworkError = err.code === "ERR_NETWORK";
    // if (isNetworkError) return axios.request(err.config);
    if (isUnauthorized && canRetry()) {
      loadingHandler(err.config?.method, true);
      await getRefreshToken();
      loadingHandler(err.config?.method, false);
      updateRetryValue();
      return axios.request(err.config);
    }
    if (!isCanceled && error) {
      handleErrors(err);
    }
    return Promise.reject(err);
  };
  useLayoutEffect(() => {
    const req = axios.interceptors.request.use(reqHandler);
    const res = axios.interceptors.response.use(resHandler, errHandler);
    return () => {
      axios.interceptors.request.eject(req);
      axios.interceptors.response.eject(res);
    };
  }, [reqHandler, resHandler, errHandler]);
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);
  return { axios, loading, progress };
}
