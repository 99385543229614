import { Status, Text } from "../../../components";
import { SellOffProductOldStatus } from "../../../enums";
import { useConverters } from "../../../hooks";
import { SellOffProductOld } from "../../../types";

type Props = {
  item: SellOffProductOld.Item;
};
export default function SoldStatusTemplate({ item }: Props) {
  const { convertDate } = useConverters();
  return (
    <div className="space-y-2 font-normal">
      <h6 className="text-heading-6">
        <Status.SellOff id={SellOffProductOldStatus.Sold} />
      </h6>
      <p>{item.currentPhaseName}</p>
      <p className="text-body-2">
        <Text>sellOffProducts.soldBy</Text> {item?.sellerName}
      </p>
      <p className="text-body-2">{convertDate(item.soldDateTime)}</p>
    </div>
  );
}
