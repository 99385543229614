import { useEffect, useState } from "react";
import { Drawer, Loading, NoItems, SearchBox, Text } from "../../../components";
import { useAxios } from "../../../hooks";
import { Product, togglePropsType } from "../../../types";
import RenderProductFamilies from "./RenderProductFamilies";

type ProductFamiliesProps = togglePropsType & {
  onSelect: (id: string | null) => void;
};

export default function ProductFamilies({
  isOpen,
  toggle,
  onSelect,
}: ProductFamiliesProps) {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<Product.ProductFamily[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);
  const noData = !data.length;
  const handleSelect = (id: string | null) => {
    onSelect(id);
    toggle();
  };
  const getData = () => {
    if (!isOpen) return;
    const url = "/productservice/api/pimproductfamilies/families-with-count";
    const config = { params: { keyword } };
    axios.get(url, config).then(({ data }) => {
      const hasKeyword = !!config.params.keyword;
      const result = data.map((e: Product.ProductFamily) => ({
        ...e,
        parentId: hasKeyword ? null : e.parentId,
      }));
      setData(result);
    });
  };
  useEffect(getData, [isOpen, keyword]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle} size="xl">
      <Drawer.Menu className="bg-[#F5F7F9] px-0">
        <Drawer.Header className="flex items-center bg-white border-b-0 gap-4 px-6">
          <h6 className="w-fit text-dark text-base text-start">
            <Text>products.filter.selectProductFamily</Text>
          </h6>
          <SearchBox
            onSubmit={setKeyword}
            variant="gray"
            className="flex-1 w-auto max-w-[29.375rem] ms-auto"
          />
        </Drawer.Header>
        <Drawer.Body className="space-y-5 px-6">
          {loading.get ? (
            <div>
              <Loading.List />
            </div>
          ) : noData ? (
            <NoItems />
          ) : (
            <RenderProductFamilies data={data} onSelect={handleSelect} />
          )}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
