const sellOffProducts = {
    searchPlaceholder: "Produkte finden...",
    allProductText: "Alle Produkte({{ items }} Products)",
    productFamily: "Produktfamilie",
    supplier: "Lieferant",
    program: "Programm",
    soldBy: "Verkauft von",
    days: "Tage",
    lastDay: "Letzter Tag",
    startProccess: "Prozess starten",
    changePhase: "Phasenwechsel",
    closeProccess: "Prozess beenden",
    deleteProduct: "Produkt löschen",
    closed: "Geschlossen",
    withdraw: "Zurückziehen",
    sold: "Verkauft",
    saleProccess: "Verkaufsprozess",
    salesTemplate: "Verkaufsvorlage",
    currentPhase: "Aktuelle Phase",
    phasePrice: "Phasenpreis",
    discount: "Rabatt",
    regularPrice: "Regulärer Preis",
    supplierPrice: "Lieferantenpreis",
    price: "Preis",
    salesStatus: "Verkaufsstatus",
    soldPrice: "Verkaufspreis",
    soldDate: "Verkaufsdatum",
    seller: "Verkäufer",
    sellerCommision: "Verkäuferprovision",
    fastSaleCommission: "Schnellverkauf Provision",
    salesTimeline: "Verkaufstimeline",
    noAction: "Keine Aktion",
    createProduct: "Produkt erstellen",
    startPhase: "Phase starten",
  filter: {
   
  },
};
export default sellOffProducts;
