const localStorageLang = localStorage.getItem("language");

export const config = {
  baseUrl: process.env.REACT_APP_BASE_URL as string,
  tenant: process.env.REACT_APP_TENANT as string,
  b2cTenant: process.env.REACT_APP_B2C_TENANT as string,
  clientId: process.env.REACT_APP_CLIENT_ID as string,
  b2cSigningPolicy: process.env.REACT_APP_B2C_SIGNIN_POLICY as string,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL as string,
  scope: process.env.REACT_APP_SCOPE as string,
  subscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY as string,
  lang: localStorageLang ?? (process.env.REACT_APP_DEFAULT_LANG as string),
  isDevelopment:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  pageSize: 10,
};
