import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { OfferDetailsContext } from "..";
import {
  Button,
  Drawer,
  Icon,
  List,
  SearchBox,
  Text,
} from "../../../../components";
import { config } from "../../../../constants";
import { DocumentTemplateType, SalesDocumentType } from "../../../../enums";
import { useAxios } from "../../../../hooks";
import { Document, togglePropsType } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";

type paramsType = {
  pageSize: number;
  pageNumber: number | string;
  keyword?: string;
  documentTemplateType: number;
};
export default function ChooseDocumentMenu({
  isOpen,
  toggle,
}: togglePropsType) {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const isOffer = !!offer;
  const isOrder = !!order;

  const [data, setData] = useState<Document.Template[]>([]);
  const [params, setParams] = useState<paramsType>({
    pageSize: config.pageSize,
    pageNumber: 1,
    documentTemplateType: isOffer
      ? DocumentTemplateType.Offer
      : DocumentTemplateType.Order,
  });
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) => {
      const isPageNumber = key === "pageNumber";
      setParams((p) => ({
        ...p,
        [key]: value,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getTemplates = () => {
    if (!isOpen) return;
    const url = `/salesservice/api/documenttemplates`;
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  const submitTemplate = (template: Document.Template) => {
    const url = [
      "/salesservice",
      "api",
      isOffer ? "offerdocuments" : "orderdocuments",
    ].join("/");
    const body: Document.Create = {
      offerId: offer?.id,
      orderId: order?.id,
      title: template.name,
      templateCode: template.code,
      needSendToErp: template.needSendToErp,
      signMandatory: template.signMandatory,
      description: "",
      createdFromTemplate: true,
      documentFileInfo: null,
      isSigned: false,
      signUrl: null,
      [isOffer ? "offerLineItemId" : "orderLineItemId"]: null,
      salesDocumentType: SalesDocumentType.Main,
      tags: null,
    };
    axios.post(url, body).then(({ data: documentId }) => {
      isOffer && navigate(`/offers/${offer?.id}/documents/${documentId}`);
      isOrder && navigate(`/orders/${order?.id}/documents/${documentId}`);
    });
  };
  useEffect(getTemplates, [isOpen, params]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="space-y-4">
          <h6 className="block">
            <Text>drawerTitles.documentTemplate</Text>
          </h6>
          <SearchBox variant="gray" onSubmit={handleSetParams("keyword")} />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {data
              .filter((e) => !!e.uploadedFile?.url)
              .map((e) => (
                <List.Item key={e.id}>
                  <Icon.Wrapper rounded>
                    <Icon name="DocumentText" variant="Bold" />
                  </Icon.Wrapper>
                  <div className="flex-1">
                    <h6 className="text-base text-dark">{e.name}</h6>
                    <p className="w-full truncate text-secondary">#{e.code}</p>
                  </div>
                  <Button
                    light
                    onClick={() => submitTemplate(e)}
                    loading={loading.post}
                  >
                    <Text>button.choose</Text>
                  </Button>
                </List.Item>
              ))}
          </List>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
