const sellOffProducts = {
    searchPlaceholder: "Found products...",
    allProductText: "All ({{ items }} Products)",
    productFamily: "Product family",
    supplier: "Supplier",
    program: "Program",
    soldBy: "Sold By",
    days: "Days",
    lastDay: "Last Day",
    startProccess: "Start Proccess",
    changePhase: "Change Phase",
    closeProccess: "Close Proccess",
    deleteProduct: "Delete Product",
    closed: "Closed",
    withdraw: "Withdraw",
    sold: "Sold",
    saleProccess: "Sale Proccess",
    salesTemplate: "Sales Template",
    currentPhase: "Current Phase",
    phasePrice: "Phase Price",
    discount: "Discount",
    regularPrice: "Regular Price",
    supplierPrice: "Supplier Price",
    price: "Price",
    salesStatus: "Sales Status",
    soldPrice: "Sold Price",
    soldDate: "Sold Date",
    seller: "Seller",
    sellerCommision: "Seller Commision",
    fastSaleCommission: "Fast Sale Commission",
    salesTimeline: "Sales Timeline",
    noAction: "No Action",
    createProduct: "Create Product",
    startPhase: "Start Phase",
  filter: {
    
  },
};
export default sellOffProducts;
