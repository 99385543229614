import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import { Button, Drawer, Icon, Text } from "../../../../../components";
import { Basket, togglePropsType } from "../../../../../types";
import BasketDetails from "./BasketDetails";
import BasketList from "./BasketList";

type ActiveBasket = Basket.Item | null;
type ImportBasketProps = togglePropsType & {
  parentId: string | null;
  onSubmitted: () => void;
};
type ContextType = {
  parentId: string | null;
  activeBasket: ActiveBasket;
  setActiveBasket: Dispatch<SetStateAction<ActiveBasket>>;
  clearActiveBasketTag: () => void;
  toggle: () => void;
  onSubmitted: () => void;
};
export const ImportBasketContext = createContext({} as ContextType);
export default function ImportBasket({
  isOpen,
  toggle,
  onSubmitted,
  parentId,
}: ImportBasketProps) {
  const [activeBasket, setActiveBasket] = useState<ActiveBasket>(null);
  const hasBasket = !!activeBasket;
  const clearActiveBasketTag = () => {
    setActiveBasket(null);
  };
  const handleToggle = () => {
    clearActiveBasketTag();
    toggle();
  };
  const ActiveTab = useMemo(() => {
    if (hasBasket) return BasketDetails;
    return BasketList;
  }, [hasBasket]);

  return (
    <Drawer isOpen={isOpen} toggle={handleToggle} size="lg">
      <Drawer.Menu className="relative overflow-hidden">
        <Drawer.Header className="flex items-center text-end">
          <div className="flex-1 flex">
            {hasBasket && (
              <Button
                variant="primary"
                light
                className="btn-sm"
                onClick={clearActiveBasketTag}
              >
                <Icon name="ArrowLeft" variant="Linear" size={18} />{" "}
                <Text>button.backToBasketList</Text>
              </Button>
            )}
          </div>
          <h6 className="flex-1 text-center">
            <Text>drawerTitles.importBasket</Text>
          </h6>
          <div className="flex-1 flex justify-end"></div>
        </Drawer.Header>
        <ImportBasketContext.Provider
          value={{
            parentId,
            activeBasket,
            setActiveBasket,
            clearActiveBasketTag,
            toggle: handleToggle,
            onSubmitted,
          }}
        >
          {isOpen && <ActiveTab />}
        </ImportBasketContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
