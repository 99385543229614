import { cloneDeep } from "lodash";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setProfile } from "../redux/profile";
import { Profile, SalesChannel } from "../types";
import useAxios from "./useAxios";

export default function useGetProfile() {
  const dispatch = useDispatch();
  const { axios, loading } = useAxios();
  const getSalesChannels = useCallback(async () => {
    const url = "/salesservice/api/salechannels/all";
    const config = { params: { channelCode: null } };
    return await axios
      .get<SalesChannel.Item[]>(url, config)
      .then(({ data }) => data)
      .catch(() => null);
  }, []);
  const getProfile = useCallback(async () => {
    const url = "/accountservice/api/users/me";
    return await axios
      .get<Profile.Me>(url)
      .then(({ data }) => data)
      .catch(() => null);
  }, []);
  const getData = useCallback(async () => {
    const [profile, salesChannels] = await Promise.all([
      getProfile(),
      getSalesChannels(),
    ]);
    if (!profile || !salesChannels) return null;
    const key = "default-sale-channel-id";
    const defaultSaleChannel = sessionStorage.getItem(key);
    const salesChannelIds = profile.availableSaleChannels.map(
      (e) => e.saleChannelId
    );
    const hasDefaultSaleChannel =
      !!defaultSaleChannel && salesChannelIds.includes(defaultSaleChannel);
    const result = cloneDeep(profile);
    const availableSaleChannels =
      salesChannelIds
        .map((saleChannelId) =>
          salesChannels?.find((e) => e.saleChannelId === saleChannelId)
        )
        .filter(Boolean) ?? [];
    result.availableSaleChannels = availableSaleChannels as SalesChannel.Item[];
    if (hasDefaultSaleChannel) result.defaultSaleChannel = defaultSaleChannel;
    dispatch(setProfile(result));
    return profile;
  }, [getProfile, getSalesChannels]);

  return [getData, loading.get] as const;
}
