import {
  createContext,
  createElement,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  BackButton,
  Button,
  Icon,
  Layout,
  Loading,
  NoItems,
  Tabs,
  Text,
  WithPermission,
  Wrapper,
} from "../../../components";
import { PimProductType } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, usePermission, useToggle } from "../../../hooks";
import { randomUUID } from "../../../methods";
import { Product } from "../../../types";
import Attributes from "./Attributes";
import AvailabilityStatus from "./AvailabilityStatus";
import General from "./General";
import Inventory from "./Inventory";
import Items from "./Items";
import Media from "./Media";
import PriceSection from "./PriceSection";
import PrintHistory from "./PrintHistory";
import PrintTemplateDrawer from "./PrintTemplateDrawer";
import ProductInfo from "./ProductInfo";
import ProductPriceProvider from "./ProductPriceProvider";
import SalesPersonCommission from "./SalesPersonCommission";

type ContextType = {
  product: Product.Details;
  setProduct: Dispatch<SetStateAction<Product.Details | null>>;
  getProduct: () => void;
  updateProductLoading: boolean;
  updateProduct: () => Promise<void>;
  isStandard: boolean;
  isBundle: boolean;
  canEditPrice: boolean;
  hasPrice2: boolean;
  hasPrice1: boolean;
  isOverwritten: boolean;
};

export const ProductDetailsContext = createContext({} as ContextType);

function ProductDetails() {
  const { axios, loading } = useAxios();
  const { axios: updateProductAxios, loading: updateProductLoading } =
    useAxios();
  const { productId } = useParams();
  const hasEditPermission = usePermission("SC_OverwritePimProductPrice");
  const [data, setData] = useState<Product.Details | null>(null);
  const [printRequestsKey, setPrintRequestsKey] = useState(randomUUID());
  const hasData = !!data;
  const [shoePrintTemplate, togglePrintTemplate, hidePrintTemplate] =
    useToggle(false);
  const hasLoading = loading.get;
  const isStandard = data?.pimProductType === PimProductType.Standard;
  const isBundle = data?.pimProductType === PimProductType.Bundle;
  const canEditPrice = isStandard && hasEditPermission;
  const hasPrice2 =
    !!data?.vk2Price &&
    data?.originalVk1Price?.amount !== data?.vk2Price?.amount;
  const hasPrice1 = !!data?.originalVk1Price;
  const isOverwritten = useMemo(() => {
    const parentHasOverwrittenPrice = !!data?.hasOverwrittenPrice;
    const itemHasOverwrittenPrice = !!data?.bundleItems?.some(
      (e) => e.hasOverwrittenPrice
    );
    const isEqual = data?.vk1Price?.amount === data?.originalVk1Price?.amount;
    const hasOverwritten = parentHasOverwrittenPrice || itemHasOverwrittenPrice;
    if (isStandard) return parentHasOverwrittenPrice && !isEqual;
    return hasOverwritten && !isEqual;
  }, [data, isStandard]);

  const reloadPrintRequests = () => {
    setPrintRequestsKey(randomUUID());
  };
  const initTabs = useMemo(
    () => [
      {
        label: "products.details.generalTab",
        id: "general",
        component: General,
        active: true,
      },
      {
        label: "products.details.itemsTab",
        id: "items",
        component: Items,
        active: isBundle,
      },
      {
        label: "products.details.attributesTab",
        id: "attributes",
        component: Attributes,
        active: true,
      },
      {
        label: "products.details.mediaTab",
        id: "media",
        component: Media,
        active: true,
      },
      {
        label: "products.details.inventoryTab",
        id: "inventory",
        component: Inventory,
        active: true,
      },
      {
        label: "products.details.availabilityStatusTab",
        id: "availability-status",
        component: AvailabilityStatus,
        active: true,
      },
      {
        label: "products.details.printHistoryTab",
        id: "print-history",
        component: () => <PrintHistory key={printRequestsKey} />,
        active: true,
      },
      {
        label: "products.details.salesPersonCommissionTab",
        id: "commission",
        component: SalesPersonCommission,
        active: !isBundle,
      },
    ],
    [isBundle, printRequestsKey]
  );
  const tabs = initTabs.filter((e) => e.active);
  const getData = () => {
    setData(null);
    const url = `/salesservice/api/salestoolspimproduct/${productId}`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  const updateData = async () => {
    const url = `/salesservice/api/salestoolspimproduct/${productId}`;
    return await updateProductAxios.get(url).then(({ data }) => {
      setData((p) => ({
        ...p!,
        availabilityStatus: data.availabilityStatus,
        availabilityStatuses: data.availabilityStatuses,
      }));
    });
  };
  useEffect(getData, [productId]);
  return (
    <Tabs activeKey={tabs[0]?.id}>
      <Layout>
        <Layout.Header>
          <BackButton to="/products" />
          {!hasLoading && (
            <Button
              type="button"
              variant="primary"
              light
              className="ms-auto"
              onClick={togglePrintTemplate}
            >
              <Icon name="Printer" variant="Bold" className="size-5" />{" "}
              <Text>products.details.printButton</Text>
            </Button>
          )}
        </Layout.Header>
        <Layout.Body className="space-y-4">
          {hasLoading ? (
            <Loading.Header />
          ) : !hasData ? (
            <NoItems />
          ) : (
            <ProductDetailsContext.Provider
              value={{
                product: data,
                setProduct: setData,
                getProduct: getData,
                updateProduct: updateData,
                updateProductLoading: updateProductLoading.get,
                isStandard,
                isBundle,
                canEditPrice,
                hasPrice2,
                hasPrice1,
                isOverwritten,
              }}
            >
              <ProductPriceProvider>
                <Wrapper>
                  <Wrapper.Body className="space-y-4">
                    <ProductInfo />
                    <WithPermission
                      permissions={["PE_GetSalePriceCalculation"]}
                    >
                      <PriceSection />
                    </WithPermission>
                  </Wrapper.Body>
                  <Wrapper.Footer className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Footer>
                </Wrapper>
                {tabs.map((e) => (
                  <Tabs.Item key={e.id} eventKey={e.id}>
                    {createElement(e.component)}
                  </Tabs.Item>
                ))}
                <PrintTemplateDrawer
                  isOpen={shoePrintTemplate}
                  toggle={hidePrintTemplate}
                  onSubmitted={reloadPrintRequests}
                />
              </ProductPriceProvider>
            </ProductDetailsContext.Provider>
          )}
        </Layout.Body>
      </Layout>
    </Tabs>
  );
}
export default withPermission(ProductDetails, [
  "PS_PimProductFullAccess",
  "PS_GetPimProduct",
]);
