
import { Loading, TimeLine } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { checkPriceAvailability } from "../../../../methods";

const SaleProccessTimeline = ({ timeLine }: any) => {
  const { convertDate } = useConverters();

  const checkActionType = (type: number) => {
    switch (type) {
      case 0:
        return "sellOffProducts.noAction";
      case 1:
        return "sellOffProducts.createProduct";
      case 2:
        return "sellOffProducts.startPhase";
      case 3:
        return "sellOffProducts.changePhase";
      case 4:
        return "sellOffProducts.sold";
      case 5:
        return "sellOffProducts.closePhase";
      case 6:
        return "sellOffProducts.withdraw";
    }
  };

  const timelineBackground = (type: number) => {
    switch (type) {
      case 0:
        return "gray-500";
      case 1:
        return "primary";
      case 2:
        return "success";
      case 3:
        return "warning";
      case 4:
        return "info";
      case 5:
        return "danger";
      case 6:
        return "warning";
    }
  };

  const formattedValue = (t: any) => {
    switch (t.key) {
      case "Duration":
        return t.value + " Days";
      case "Discount":
        return t.value + "%";
      case "OriginalPrice":
      case "SellerCommission":
      case "PriceWithDiscount":
      case "SoldPrice":
      case "OriginalPrice":
      case "RealSoldPrice":
        return checkPriceAvailability(t.value);
      case "ExpectedStartDate":
        return convertDate(t.value);

      default:
        return t.value;
    }
  };

  const createItemsDetails = (item: any, date: any) => {
    return (
      <div className="space-y-2">
        <p className="text-gray-600">
          {date.performedBy
            ? date.performedBy + " at " + convertDate(date.performedAt)
            : convertDate(date.performedAt)}{" "}
        </p>
        {item?.map((t: any) => {
          return (
            <div className="space-x-2">
              <span>{t.key.match(/[A-Z][a-z]+/g).join(" ")}:</span>
              <span>{formattedValue(t)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const createTimelineItems = (timeline: any) => {
    const items = timeline?.map((item: any) => {
      return {
        title: checkActionType(item.actionType),
        description: item.title,
        label: item.createdItemAt
          ? convertDate(item?.createdItemAt)?.split(",")[0]
          : "",
        color: timelineBackground(item.actionType),
        details: createItemsDetails(item.dynamicFields, {
          performedAt: item.performedAt,
          performedBy: item.performedBy,
        }),
      };
    });
    return items;
  };

  return (
    <>
      {!timeLine.length ? (
        <div className="grid grid-cols-4 gap-4">
          kkkk
          {[1, 2, 3, 4].map((s) => (
            <Loading.Cards key={s} />
          ))}
        </div>
      ) : (
        <TimeLine
          items={createTimelineItems(timeLine).reverse()}
          className="parent"
        />
      )}
    </>
  );
};

export default SaleProccessTimeline;
