import { useContext } from "react";
import { AddCompanyContext } from ".";
import { Button, CheckBox, Modal, Text } from "../../../components";
import { togglePropsType } from "../../../types";

type TermsConditionsProps = togglePropsType & {
  loading: boolean;
  onSubmit: () => void;
};
export default function TermsConditions({
  isOpen,
  toggle,
  loading,
  onSubmit,
}: TermsConditionsProps) {
  const { data, setData } = useContext(AddCompanyContext);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <Text>company.create.termsConditions.title</Text>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <div className="w-full max-h-96 bg-gray-50 text-dark text-base rounded-none overflow-auto px-4 py-5">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum
          adipisci eos numquam repellat quos, perspiciatis neque necessitatibus
          dolor! Nemo porro non suscipit ut fugit officiis quasi, labore aliquam
          quo voluptatibus! Lorem ipsum, dolor sit amet consectetur adipisicing
          elit. Rerum adipisci eos numquam repellat quos, perspiciatis neque
          necessitatibus dolor! Nemo porro non suscipit ut fugit officiis quasi,
          labore aliquam quo voluptatibus! Lorem ipsum, dolor sit amet
          consectetur adipisicing elit. Rerum adipisci eos numquam repellat
          quos, perspiciatis neque necessitatibus dolor! Nemo porro non suscipit
          ut fugit officiis quasi, labore aliquam quo voluptatibus! Lorem ipsum,
          dolor sit amet consectetur adipisicing elit. Rerum adipisci eos
          numquam repellat quos, perspiciatis neque necessitatibus dolor! Nemo
          porro non suscipit ut fugit officiis quasi, labore aliquam quo
          voluptatibus! Lorem ipsum, dolor sit amet consectetur adipisicing
          elit. Rerum adipisci eos numquam repellat quos, perspiciatis neque
          necessitatibus dolor! Nemo porro non suscipit ut fugit officiis quasi,
          labore aliquam quo voluptatibus! Lorem ipsum, dolor sit amet
          consectetur adipisicing elit. Rerum adipisci eos numquam repellat
          quos, perspiciatis neque necessitatibus dolor! Nemo porro non suscipit
          ut fugit officiis quasi, labore aliquam quo voluptatibus! Lorem ipsum,
          dolor sit amet consectetur adipisicing elit. Rerum adipisci eos
          numquam repellat quos, perspiciatis neque necessitatibus dolor! Nemo
          porro non suscipit ut fugit officiis quasi, labore aliquam quo
          voluptatibus!
        </div>
        <CheckBox
          label="company.create.termsConditions.gdprAccepted"
          value={!!data.gdprAccepted}
          setValue={(gdprAccepted) => setData((p) => ({ ...p, gdprAccepted }))}
        />
        <CheckBox
          label="company.create.termsConditions.companyRegistrationNumberApproved"
          value={!!data.companyRegistrationNumberApproved}
          setValue={(companyRegistrationNumberApproved) =>
            setData((p) => ({ ...p, companyRegistrationNumberApproved }))
          }
        />
        <CheckBox
          label="company.create.termsConditions.personPowerOfAttorneyApproved"
          value={!!data.personPowerOfAttorneyApproved}
          setValue={(personPowerOfAttorneyApproved) =>
            setData((p) => ({ ...p, personPowerOfAttorneyApproved }))
          }
        />
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button variant="danger" onClick={toggle}>
          <Text>company.create.termsConditions.cancelBtn</Text>
        </Button>
        <Button
          disabled={!data.gdprAccepted}
          variant="success"
          onClick={onSubmit}
          loading={loading}
        >
          <Text>company.create.termsConditions.submitBtn</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
