import { cloneDeep } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  Button,
  Icon,
  Layout,
  Loading,
  NoItems,
  Pagination,
  ProductCard,
  SearchBox,
  Text,
} from "../../../components";
import { withPermission } from "../../../hoc";
import { useAxios, useDefaultSaleChannel, useToggle } from "../../../hooks";
import { Product } from "../../../types";
import Filter from "./Filter";
import PimProductTypeFilter from "./PimProductTypeFilter";
import ProductFamilies from "./ProductFamilies";

function ProductsList() {
  const pageSize = 15;
  const { axios, loading } = useAxios();
  const initParams: Product.Params = {
    pageNumber: 1,
    pageSize: pageSize,
  };
  const saleChannel = useDefaultSaleChannel();
  const [products, setProducts] = useState<Product.WithAzureSearch>({});
  const [params, setParams] = useState(initParams);
  const [isGrid, toggleIsGrid] = useToggle(false);
  const [showFilter, toggleFilter] = useToggle(false);
  const [showProductFamilies, toggleProductFamilies] = useToggle(false);
  const hasProducts = !!products.totalItems;
  const resetFilter = () => {
    setParams(initParams);
  };
  const handleSetParams = (key: keyof Product.Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: any) => {
      setParams((p) => ({
        ...p,
        [key]: value,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getData = () => {
    const url = "/salesservice/api/salestoolspimproduct/search";
    const body = cloneDeep(params);
    body.channelCode = saleChannel?.code;
    axios.post(url, body).then(({ data }) => {
      setProducts(data);
    });
  };

  useEffect(getData, [params, saleChannel?.code]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <SearchBox
            onSubmit={handleSetParams("searchFields")}
            className="w-auto flex-1"
            placeholder="products.list.searchPlaceholder"
          />
          <Button type="button" variant="primary" light onClick={toggleIsGrid}>
            <Icon name={isGrid ? "RowVertical" : "Element3"} variant="Bold" />
          </Button>
          <Button type="button" variant="primary" light onClick={toggleFilter}>
            <Icon name="Filter" variant="Bold" />
          </Button>
          {/* <Button type="button" variant="primary" light>
          <Icon name="Scan" variant="Bold" />
        </Button>
        <Button type="button" variant="primary" light>
        <Icon name="Sort" />
        </Button>
       */}
        </Layout.Header>
        <Layout.Body
          className={twMerge(
            "grid grid-cols-1 gap-2",
            isGrid && "md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
          )}
        >
          <section className="col-span-full flex items-center gap-4 py-3">
            <div className="flex-1">
              <p
                className={twMerge(
                  "w-fit text-base text-dark font-medium truncate",
                  loading.post && "loading"
                )}
              >
                <Text items={products.totalItems ?? "0"}>
                  products.list.allProductText
                </Text>
              </p>
            </div>
            <Button
              type="button"
              variant="primary"
              onClick={toggleProductFamilies}
            >
              <Text>products.list.selectProductFamily</Text>
            </Button>
          </section>
          <PimProductTypeFilter
            value={params.pimProductType ?? null}
            setValue={handleSetParams("pimProductType")}
            disabled={loading.post}
          />
          {loading.post ? (
            <Loading.ProductCard isGrid={isGrid} />
          ) : !hasProducts ? (
            <NoItems className="col-span-full" />
          ) : (
            products.searchResult?.map((e) => (
              <ProductCard key={e.id} product={e} isGrid={isGrid} />
            ))
          )}
          <div className="col-span-full">
            <Pagination
              pageNumber={params.pageNumber}
              pageSize={pageSize}
              setActivePage={handleSetParams("pageNumber")}
              totalItems={products.totalItems}
              totalPages={products.totalPages}
            />
          </div>
        </Layout.Body>
      </Layout>
      <Filter
        isOpen={showFilter}
        toggle={toggleFilter}
        result={products}
        params={params}
        handleSetParams={handleSetParams}
        loading={loading.post}
        onReset={resetFilter}
      />
      <ProductFamilies
        isOpen={showProductFamilies}
        toggle={toggleProductFamilies}
        onSelect={handleSetParams("pimProductFamilyId")}
      />
    </Fragment>
  );
}
export default withPermission(ProductsList, [
  "PS_PimProductFullAccess",
  "PS_GetPimProduct",
]);
