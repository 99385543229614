import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { SearchSellOffProductContext } from ".";
import { OfferDetailsContext } from "../..";
import {
  Button,
  Drawer,
  Form,
  Icon,
  Image,
  InputGroup,
  Loading,
  Text,
  Types,
} from "../../../../../components";
import { pimProductAttributeKey, rules } from "../../../../../constants";
import { LineItemType } from "../../../../../enums";
import {
  useAxios,
  useConverters,
  useFindAttribute,
  useLanguage,
} from "../../../../../hooks";
import { cleanText } from "../../../../../methods";
import { LineItem, SellOffProduct, iconNameType } from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";
import { noteSuggestion } from "../notes";

type LineItemInfoItem = {
  label: string;
  icon: iconNameType;
  value: string | number | null | undefined;
};
export default function ManualForm() {
  const [lng] = useLanguage();
  const findAttribute = useFindAttribute();
  const formRef = useRef<HTMLFormElement>(null);
  const { convertAmount } = useConverters();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const { productId, clearProduct, toggle, onSubmitted } = useContext(
    SearchSellOffProductContext
  );
  const { axios, loading } = useAxios();
  const [data, setData] = useState<LineItem.CreateBySellOff | null>(null);
  const [sellOffProduct, setSellOffProduct] =
    useState<SellOffProduct.BasicInfo | null>(null);

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;

  const isOffer = !!offer;
  const isOrder = !!order;

  const sellOffProductGroup = sellOffProduct?.pimProduct?.pimProductGroup;
  const hasProductGroup = !!sellOffProductGroup;

  const hasLoading = [
    loading.post,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  const lineItemInfo: LineItemInfoItem[] = [
    {
      label: "global.lineItemProductTitle",
      icon: "Hashtag",
      value: sellOffProduct?.pimProduct?.originalName ?? "--",
    },
    {
      label: "global.articleNumber",
      icon: "Hashtag",
      value: "#" + sellOffProduct?.pimProduct?.articleNumber,
    },
    {
      label: "global.supplierName",
      icon: "Building",
      value: sellOffProduct?.pimProduct?.supplier?.name,
    },
    {
      label: "global.supplierArticleNumber",
      icon: "Building",
      value: sellOffProduct?.pimProduct?.supplierArticleNumber ?? "--",
    },
    {
      label: "global.programName",
      icon: "Document",
      value: sellOffProduct?.pimProduct?.supplier?.program?.name,
    },
    // {
    //   label: "global.lineItemNormalPrice",
    //   icon: "DollarSquare",
    //   value: convertAmount(sellOffProduct?.vk2Price?.amount),
    // },
    {
      label: "global.lineItemSalesPrice",
      icon: "DollarSquare",
      value: convertAmount(sellOffProduct?.currentPhasePrice),
    },
    {
      label: "global.lineItemProductGroupTitle",
      icon: "TableDocument",
      value: hasProductGroup
        ? `${sellOffProductGroup.name} (#${sellOffProductGroup.code})`
        : "--",
    },
  ];
  const getSellOffProduct = async (id: string) => {
    const url = `/salesservice/api/selloffproducts/${id}/basic-info`;
    return await axios
      .get(url)
      .then((res) => res.data as SellOffProduct.BasicInfo)
      .catch(() => null);
  };
  const findTranslate = (
    e: SellOffProduct.BasicInfo | null,
    code: keyof typeof pimProductAttributeKey
  ) => {
    const translates = findAttribute(e?.pimProduct?.attributeGroups, code);
    const translate = translates?.find(
      (e) => e.language === lng || !e.language
    );
    // return cleanText(translate?.labelTitle || translates?.[0]?.labelTitle) || ""
    return cleanText(translate?.labelTitle) || "";
  };
  const getInitData = () => {
    getSellOffProduct(productId).then((sellOffProduct) => {
      if (!sellOffProduct) return;
      const initData: LineItem.CreateBySellOff = {
        sellOffProductId: productId,
        offerId: offer?.id,
        orderId: order?.id,
        title:
          findTranslate(sellOffProduct, "title") ||
          sellOffProduct.pimProduct?.originalName ||
          "",
        lineItemNote: null,
        supplierNote: null,
        customerNote: null,
        ean: null,
        description: null,
        shippingMethodCode: null,
      };
      setSellOffProduct(sellOffProduct);
      setData(initData);
    });
  };
  const handleSetValue = (key: keyof LineItem.CreateBySellOff) => {
    return (value: unknown) => {
      setData((p) => ({ ...p!, [key]: value }));
    };
  };
  const handleSubmit = () => {
    formRef.current?.requestSubmit();
  };
  const submit = () => {
    if (!data) return;
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      "sell-off",
    ]
      .filter(Boolean)
      .join("/");

    const body = cloneDeep(data);
    if (isOffer) delete body.orderId;
    if (isOrder) delete body.offerId;
    body.lineItemNote ||= null;
    body.supplierNote ||= null;
    body.customerNote ||= null;
    body.ean ||= null;
    body.description ||= null;
    body.shippingMethodCode ||= null;
    axios.post(url, body).then(() => {
      (updateOffer ?? updateOrder)?.().then(() => {
        toast.success("toast.success.addLineItem");
        onSubmitted();
        toggle();
      });
    });
  };
  useEffect(getInitData, []);
  return (
    <Fragment>
      <Drawer.Header className="flex items-center text-start gap-4">
        <h6 className="flex-1 text-dark text-base">
          <Text>drawerTitles.addProduct</Text>
        </h6>
        <button type="button" className="text-warning" onClick={clearProduct}>
          <Icon name="ArrowForward" variant="Bold" /> <Text>button.change</Text>
        </button>
      </Drawer.Header>
      <Drawer.Body>
        {loading.get ? (
          <Loading.PimProductDetails />
        ) : (
          <Form ref={formRef} onSubmit={submit} className="space-y-5">
            <button hidden type="submit" />
            <section className="flex items-center gap-4 border-b border-dashed pb-4">
              <Image
                src={sellOffProduct?.avatar?.thumbnailUrl}
                alt={sellOffProduct?.pimProduct?.originalName}
                className="w-32 h-20 object-cover rounded shadow"
                isDynamic
              />
              <div className="flex-1 space-y-1">
                <Types.LineItem id={LineItemType.SellOffProduct} />
              </div>
            </section>
            <table className="w-full text-sm font-medium [&_td]:py-3">
              <tbody>
                {lineItemInfo.map((e) => (
                  <tr key={e.label} className="border-b border-dashed">
                    <td className="text-secondary text-start">
                      <Icon name={e.icon} variant="Bold" />{" "}
                      <Text>{e.label}</Text>
                    </td>
                    <td className="text-gray-900 text-end">{e.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <InputGroup
              label="formControls.lineItemTitle"
              value={data?.title}
              setValue={handleSetValue("title")}
              rules={rules.required}
            />
            {/* <InputGroup
              label="formControls.lineItemEan"
              value={data?.ean}
              setValue={handleSetValue("ean")}
            /> */}
            <InputGroup
              as="textarea"
              label="formControls.description"
              value={data?.description}
              setValue={handleSetValue("description")}
              suggestionData={noteSuggestion}
              snippet
            />
            <InputGroup
              as="textarea"
              label="formControls.lineItemNote"
              value={data?.lineItemNote}
              setValue={handleSetValue("lineItemNote")}
              suggestionData={noteSuggestion}
              snippet
            />
            <InputGroup
              as="textarea"
              label="formControls.lineItemCustomerNote"
              value={data?.customerNote}
              setValue={handleSetValue("customerNote")}
              suggestionData={noteSuggestion}
              snippet
            />
            <InputGroup
              as="textarea"
              label="formControls.lineItemSupplierNote"
              value={data?.supplierNote}
              setValue={handleSetValue("supplierNote")}
              suggestionData={noteSuggestion}
              snippet
            />
            <button type="submit" hidden disabled={hasLoading} />
          </Form>
        )}
      </Drawer.Body>
      <Drawer.Footer className="flex items-center justify-end gap-4">
        <Button type="button" variant="danger" onClick={clearProduct}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          disabled={loading.get}
          loading={hasLoading}
          onClick={handleSubmit}
        >
          <Text>button.addProduct</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
