import { cloneDeep } from "lodash";
import {
  ChangeEvent,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { OfferDetailsContext } from ".";
import { Button, Drawer, Form, InputGroup, Text } from "../../../components";
import { rules } from "../../../constants";
import { SalesDocumentType } from "../../../enums";
import { useAxios, useUploadFile } from "../../../hooks";
import { showFileName } from "../../../methods";
import { Document, togglePropsType } from "../../../types";
import { OrderDetailsContext } from "../../Orders/Details";

type Data = Document.Create;

type PersonalDocumentProps = togglePropsType & {
  onSubmitted: () => void;
  lineItemId?: string;
};

export default function AddDocumentDrawer({
  isOpen,
  toggle,
  onSubmitted,
  lineItemId,
}: PersonalDocumentProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const isOffer = !!offer;
  const isOrder = !!order;

  const { axios, loading } = useAxios();
  const [uploadFile, fileLoading] = useUploadFile();
  const [data, setData] = useState<Data>({});
  const acceptableTypes = "image/*, .doc, .docx, .pdf, .xls, .xlsx";
  const clearData = () => {
    setData({});
  };
  const handleSetValue = (key: keyof Data) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    uploadFile(file).then((e) => {
      const documentFileInfo = {
        fileSize: e.metaData.find((e) => e.key === "ActualSize")?.value ?? "0",
        fileUrl: e.url,
        mimeType: e.type,
      };
      setData((p) => ({ ...p, documentFileInfo }));
    });
  };
  const clickInput = () => {
    inputRef.current?.click();
  };
  const clearInputValue = () => {
    const input = inputRef.current;
    input?.value && (input.value = "");
  };
  const handleToggleMenu = () => {
    if (loading.post) return;
    toggle();
  };
  const resetData = () => {
    if (!isOpen) return;
    setData({});
    const element = inputRef.current;
    if (!element) return;
    element.value = "";
  };
  const submit = () => {
    if (!data.documentFileInfo) return toast.error("errorCodes.file");
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerdocuments",
      isOrder && "orderdocuments",
    ]
      .filter(Boolean)
      .join("/");
    const body = cloneDeep(data);
    body.offerId = offer?.id;
    body.orderId = order?.id;
    body.templateCode = null;
    body.isSigned = false;
    body.signUrl = null;
    body.createdFromTemplate = false;
    body.signMandatory = false;
    body.needSendToErp = false;
    body[isOffer ? "offerLineItemId" : "orderLineItemId"] = lineItemId || null;
    body.salesDocumentType = lineItemId
      ? SalesDocumentType.LineItem
      : SalesDocumentType.Main;
    axios.post(url, body).then(() => {
      clearInputValue();
      toast.success("toast.success.addDocument");
      clearData();
      onSubmitted();
      toggle();
    });
  };
  useEffect(resetData, [isOpen]);
  return (
    <Drawer
      as={Form}
      onSubmit={submit}
      isOpen={isOpen}
      toggle={handleToggleMenu}
      className="z-40"
    >
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            {lineItemId ? (
              <Text>drawerTitles.lineItemDocument</Text>
            ) : (
              <Text>drawerTitles.personalDocument</Text>
            )}
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          {!lineItemId && (
            <Fragment>
              <InputGroup
                label="formControls.documentTitle"
                value={data.title}
                setValue={handleSetValue("title")}
                rules={rules.required}
                placeholder="placeholders.documentTitle"
              />
              <InputGroup
                as="textarea"
                label="formControls.note"
                value={data.description}
                setValue={handleSetValue("description")}
                placeholder="placeholders.documentNote"
              />
            </Fragment>
          )}
          <div className="border border-gray-200 rounded flex flex-center gap-4 p-2">
            <span>
              <Text>formControls.file</Text>
            </span>
            <span className="flex-1 truncate">
              {showFileName(data.documentFileInfo?.fileUrl)}
            </span>
            <input
              ref={inputRef}
              hidden
              type="file"
              onChange={handleUploadFile}
              accept={acceptableTypes}
            />
            <Button
              onClick={clickInput}
              light
              className="btn-sm"
              loading={fileLoading}
            >
              <Text>button.chooseFile</Text>
            </Button>
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle} disabled={loading.post}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" disabled={fileLoading} loading={loading.post}>
            <Text>button.createDocument</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
