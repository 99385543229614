import { twMerge } from "tailwind-merge";
import { Status, Text } from "../../../components";
import { SellOffProductOldStatus } from "../../../enums";
import { SellOffProductOld } from "../../../types";

type Props = { item: SellOffProductOld.Item };

const ActiveStatusTemplate = ({ item }: Props) => {
  const phaseDayClassName = twMerge(
    "text-body-2 ",
    item.currentPhaseDay === item.currentPhaseTotalDays &&
      !item.isClosed &&
      "text-warning",
    item.currentPhaseDay !== item.currentPhaseTotalDays &&
      !item.isClosed &&
      "text-success",
    item.isClosed && "text-gray"
  );
  const percentClassName = twMerge(
    "h-full block rounded transition-[width] max-w-full",
    item.currentPhaseDay === item.currentPhaseTotalDays &&
      !item.closePhaseRequired &&
      !item.isClosed &&
      "bg-warning",
    item.currentPhaseDay !== item.currentPhaseTotalDays &&
      !item.isClosed &&
      "bg-success",
    item.closePhaseRequired && !item.isClosed && "bg-danger",
    item.isClosed && "bg-gray-500"
  );

  const percent = (item.currentPhaseDay / item.currentPhaseTotalDays) * 100;

  return (
    <div className="space-y-2 font-normal">
      {item.isClosed ? (
        <h6 className="text-heading-6">
          <Status.SellOff id={SellOffProductOldStatus.Closed} />
        </h6>
      ) : (
        <h6 className="text-heading-6">
          <Status.SellOff id={SellOffProductOldStatus.Active} />{" "}
          {item.changePhaseRequired && (
            <Status.SellOff id={SellOffProductOldStatus.NeedPhaseChange} />
          )}
          {item.closePhaseRequired && (
            <Status.SellOff id={SellOffProductOldStatus.NeedClose} />
          )}
        </h6>
      )}

      <p>{item.currentPhaseName}</p>
      <div className="flex flex-wrap items-center gap-1 lg:gap-x-2">
        {item.closePhaseRequired ? (
          <p className="text-danger">
            <Text>sellOffProducts.lastDay</Text>
          </p>
        ) : (
          <p className={phaseDayClassName}>
            {"(" +
              item.currentPhaseDay +
              "/" +
              item.currentPhaseTotalDays +
              " "}
            <Text>sellOffProducts.days</Text> {" )"}
          </p>
        )}
        <div className="block w-32 h-2 rounded-full overflow-hidden bg-gray-200">
          <span style={{ width: `${percent}%` }} className={percentClassName} />
        </div>
      </div>
    </div>
  );
};

export default ActiveStatusTemplate;
