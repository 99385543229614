import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { saleProccessContext } from ".";
import { Button, Status, Text, Wrapper } from "../../../../components";
import { SellOffProductOldStatus } from "../../../../enums";

const CurrentPhase = () => {
  const { data, setChangePhase, setClosePhase } =
    useContext(saleProccessContext);

  const createPhaseSection = (item: any) => {
    const isSold = item.isSold;
    const active = item.currentPhaseDay;

    return (
      <>
        <h6 className="text-base">
          {item.isClosed ? (
            <Status.SellOff id={SellOffProductOldStatus.Closed} />
          ) : isSold ? (
            <h6 className="text-base">
              <Status.SellOff id={SellOffProductOldStatus.Sold} />
            </h6>
          ) : active ? (
            <h6 className="text-base">
              <Status.SellOff id={SellOffProductOldStatus.Active} />{" "}
              {item.changePhaseRequired && (
                <Status.SellOff id={SellOffProductOldStatus.NeedPhaseChange} />
              )}
              {item.closePhaseRequired && (
                <Status.SellOff id={SellOffProductOldStatus.NeedClose} />
              )}
            </h6>
          ) : (
            <h6 className="text-base">
              <Status.SellOff id={SellOffProductOldStatus.Preparation} />
            </h6>
          )}
        </h6>
      </>
    );
  };

  const percentClassName = twMerge(
    "h-full block rounded transition-[width] max-w-full",
    data?.currentPhaseDay === data?.currentPhaseTotalDays &&
      !data?.closePhaseRequired &&
      !data.isSold &&
      "bg-warning",
    data?.currentPhaseDay !== data?.currentPhaseTotalDays &&
      !data.isSold &&
      "bg-success",
    data?.closePhaseRequired && !data.isSold && "bg-danger",
    data?.isSold && "bg-gray-500"
  );

  const textClassName = twMerge(
    "text-heading-5 font-normal",
    data?.currentPhaseDay === data?.currentPhaseTotalDays &&
      !data?.closePhaseRequired &&
      !data.isSold &&
      "text-warning",
    data?.currentPhaseDay !== data?.currentPhaseTotalDays &&
      !data.isSold &&
      "text-success",
    data?.closePhaseRequired && !data.isSold && "text-danger",
    data?.isSold && "text-gray-500"
  );

  const percent = (data?.currentPhaseDay / data?.currentPhaseTotalDays) * 100;

  return (
    <Wrapper>
      <Wrapper.Header>
        <div className="flex justify-between items-center">
          <h2 className="text-base">
            <Text>sellOffProducts.currentPhase</Text>
          </h2>
          {createPhaseSection(data)}
        </div>
      </Wrapper.Header>
      <Wrapper.Body className="flex gap-6">
        <div className="mr-auto space-y-4">
          <p>{data.sellPhaseTemplateTitle}</p>
          {!data?.isClosed && (
            <p className={`flex-1 ${textClassName}`}>{data.currentPhaseName}</p>
          )}
        </div>
        {!data?.isClosed && (
          <div className="space-y-2">
            {data?.changePhaseRequired ? (
              <Button
                className="w-full text-sm"
                variant={"warning"}
                onClick={() => setChangePhase()}
              >
                <Text>sellOffProducts.changePhase</Text>
              </Button>
            ) : data?.closePhaseRequired ? (
              <Button
                className="w-full text-sm"
                variant={"danger"}
                onClick={() => setClosePhase()}
              >
                <Text>sellOffProducts.closeProccess</Text>
              </Button>
            ) : null}
            {data?.currentPhaseDay &&
              !data?.changePhaseRequired &&
              !data?.closePhaseRequired && (
                <div className="block w-full h-2 rounded-full overflow-hidden bg-gray-200">
                  <span
                    style={{ width: `${percent}%` }}
                    className={percentClassName}
                  />
                </div>
              )}
            <div className="text-center">
              {data?.closePhaseRequired ? (
                <p className="text-danger">
                  <Text>sellOffProducts.lastDay</Text>
                </p>
              ) : (
                <p className="text-secondary text-xs">
                  {"(" +
                    data?.currentPhaseDay +
                    "/" +
                    data?.currentPhaseTotalDays +
                    " "}
                  <Text>sellOffProducts.days</Text> {" )"}
                </p>
              )}
            </div>
          </div>
        )}
      </Wrapper.Body>
    </Wrapper>
  );
};

export default CurrentPhase;
