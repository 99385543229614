import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { Alert, Icon, Text } from "../../../components";
import { useConverters } from "../../../hooks";
import { ProductPriceContext } from "./ProductPriceProvider";

export default function PriceSection() {
  const { productSalesPrice, salesPriceLoading } =
    useContext(ProductPriceContext);
  const { convertAmount } = useConverters();
  if (salesPriceLoading) return <div className="w-full h-28 loading"></div>;
  if (!productSalesPrice) return null;
  return (
    <section
      className={twMerge(
        "flex flex-col md:flex-row md:items-center p-2.5 ps-5 rounded-md gap-4",
        productSalesPrice.color?.bg
      )}
    >
      <div className="md:flex-1 space-y-1">
        <h6 className="text-xs text-gray-900 font-medium">
          <Text>products.details.priceNowSection.title</Text>:
        </h6>
        <ul className="w-full text-xs *:py-1 [&>li>*]:flex [&>li>*]:items-center [&>li>*]:gap-1">
          <li>
            <p className="text-secondary">
              <Icon name="DollarSquare" variant="Bold" size={22} />
              <span>
                <Text>products.details.priceNowSection.vk2</Text>
              </span>
              <span className="line-through ms-4">
                {convertAmount(productSalesPrice.vk2) ?? "--"}
              </span>
            </p>
          </li>
          <li>
            <p className="w-full text-start text-dark">
              <Icon name="DollarSquare" variant="Bold" size={22} />
              <span>
                <Text>products.details.priceNowSection.vk1</Text>
              </span>
              <span className={`${productSalesPrice.color?.text} ms-4`}>
                {convertAmount(productSalesPrice.vk1) ?? "--"}
              </span>
            </p>
          </li>
        </ul>
      </div>
      {productSalesPrice.isWarning && (
        <Alert
          variant="warning"
          className="md:max-w-80 rounded-xl"
          IconProps={{ variant: "Bulk" }}
        >
          <Alert.Text className="text-white text-xs font-medium">
            <Text>products.details.priceNowSection.warningMessage</Text>
          </Alert.Text>
        </Alert>
      )}
      {productSalesPrice.isError && (
        <Alert
          variant="danger"
          className="md:max-w-80 rounded-xl"
          IconProps={{ variant: "Bulk" }}
        >
          <Alert.Text className="text-white text-xs font-medium">
            <Text>products.details.priceNowSection.errorMessage</Text>
          </Alert.Text>
        </Alert>
      )}
    </section>
  );
}
