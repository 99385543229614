import { Fragment, useContext, useState } from "react";
import { OfferDetailsContext } from "..";
import {
  AddressViewer,
  Alert,
  Button,
  Icon,
  Table,
  Text,
  Wrapper,
} from "../../../../components";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import { OrderDetailsContext } from "../../../Orders/Details";
import AddressDrawer from "../AddressDrawer";
import DownPaymentModal from "./DownPaymentModal";
import IsFinance from "./IsFinance";
import QrCodeModal from "./QrCodeModal";

export default function Billing() {
  const { axios, loading } = useAxios();
  const { convertAmount, convertDate } = useConverters();

  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const [qrCode, setQrCode] = useState("");
  const [showAddressDrawer, toggleAddressDrawer] = useToggle(false);
  const [showDownPaymentModal, toggleDownPaymentModal] = useToggle(false);
  const [showQrCodeModal, toggleQrCodeModal] = useToggle(false);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;
  const isOffer = !!offerContext;
  const isOrder = !!orderContext;

  const billingAddress = offer?.billingAddress || order?.billingAddress;
  const hasAddress = !!billingAddress;
  const totalAmount = offer?.totalAmount ?? order?.totalAmount;
  const hasTotalAmount = !!totalAmount;
  const downPaymentAmount =
    offer?.downPaymentAmount ?? order?.downPaymentAmount;
  const downPaymentDueDate =
    offer?.downPaymentDueDate ?? order?.downPaymentDueDate;
  const downPaymentReason =
    offer?.downPaymentReason || order?.downPaymentReason;
  const hasDownPayment = [
    downPaymentAmount,
    downPaymentDueDate,
    downPaymentReason,
  ].some(Boolean);

  const getQrCodeImage = () => {
    const url = `/salesservice/api/orders/${order?.id}/down-payment/qrcode`;
    const config = { cache: true };
    axios.get(url, config).then(({ data }) => {
      setQrCode(data);
      toggleQrCodeModal();
    });
  };

  return (
    <Fragment>
      <Wrapper className="lg:!col-span-2">
        <Wrapper.Header className="flex items-center">
          <h6 className="text-base text-dark">
            <Text>drawerTitles.billTo</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="flex items-start gap-4">
          {hasAddress && (
            <AddressViewer
              className="text-sm text-secondary flex-1"
              address={billingAddress}
            />
          )}
          {isEnabled && (
            <Button
              type="button"
              variant="primary"
              light
              className={`p-[0.375rem] ${
                hasAddress ? "text-lg" : "text-base"
              } leading-none`}
              onClick={toggleAddressDrawer}
            >
              <Icon name="Edit2" />
              {!hasAddress && (
                <Fragment>
                  {" "}
                  <Text>button.addBillingAddress</Text>
                </Fragment>
              )}
            </Button>
          )}
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="lg:!col-span-3">
        <Wrapper.Header>
          <h6 className="text-base text-dark">
            <Text>offerPaymentDetails.text</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="p-0">
          <Table>
            <tbody>
              <tr>
                <th className="text-start">
                  <Text>offerPaymentDetails.charges</Text>
                </th>
                <th className="text-end">
                  <Text>offerPaymentDetails.total</Text>
                </th>
              </tr>
              <tr>
                <td className="text-start">
                  <Text>offerPaymentDetails.lineItems</Text>
                </td>
                {/* <td>{"offer.quantity"}</td> */}
                <td className="text-end">{convertAmount(totalAmount)}</td>
              </tr>
            </tbody>
          </Table>
        </Wrapper.Body>
      </Wrapper>
      <IsFinance />
      <Wrapper className="lg:!col-span-3">
        <Wrapper.Body className="space-y-3">
          <div className="flex items-center">
            <span className="mr-auto">
              <Text>offerPaymentDetails.downPayment</Text>{" "}
              {!hasDownPayment && (
                <span className="text-warning">
                  <Icon name="InfoCircle" size={18} />{" "}
                  <Text>global.offerDownPaymentIsNotSet</Text>
                </span>
              )}
            </span>
            {isEnabled && isOffer && (
              <Fragment>
                {hasDownPayment ? (
                  <button
                    type="button"
                    className="text-primary text-xl"
                    onClick={toggleDownPaymentModal}
                    disabled={!hasTotalAmount}
                  >
                    <Icon name="Edit2" />
                  </button>
                ) : (
                  <Button
                    type="button"
                    variant="primary"
                    light
                    onClick={toggleDownPaymentModal}
                    disabled={!hasTotalAmount}
                  >
                    <Text>button.setOfferDownPayment</Text>
                  </Button>
                )}
              </Fragment>
            )}
          </div>
          {hasDownPayment && (
            <Fragment>
              <div className="flex items-center text-base">
                <span className="mr-auto text-secondary">
                  <Icon name="DollarSquare" variant="Bold" />{" "}
                  <Text>global.downPaymentAmount</Text>
                </span>
                <div>{convertAmount(downPaymentAmount)}</div>
              </div>
              <hr className="w-full bg-[#E1E1E7]" />
              <div className="flex items-center text-base">
                <span className="mr-auto text-secondary">
                  <Icon name="Calendar" variant="Bold" />{" "}
                  <Text>global.downPaymentDueDate</Text>
                </span>
                <div>{convertDate(downPaymentDueDate)}</div>
              </div>
            </Fragment>
          )}
        </Wrapper.Body>
        {!hasTotalAmount && isOffer && (
          <Wrapper.Footer>
            <Alert
              variant="warning"
              light
              className="border-none bg-opacity-10 p-2"
              IconProps={{ variant: "Bulk", size: 20 }}
            >
              <Alert.Text className="text-warning">
                <Text>alertTitles.offerDownPaymentEmpty</Text>
              </Alert.Text>
            </Alert>
          </Wrapper.Footer>
        )}
        {isOrder && (
          <Wrapper.Footer className="flex flex-center">
            <Button light onClick={getQrCodeImage} loading={loading.get}>
              <Icon name="Scanner" variant="Bold" size={20} />{" "}
              <Text>button.orderDownPaymentQrCode</Text>
            </Button>
          </Wrapper.Footer>
        )}
      </Wrapper>
      {isEnabled && isOffer && (
        <DownPaymentModal
          isOpen={showDownPaymentModal}
          toggle={toggleDownPaymentModal}
        />
      )}
      {isEnabled && (
        <AddressDrawer
          isOpen={showAddressDrawer}
          toggle={toggleAddressDrawer}
          type="billing"
        />
      )}
      {isOrder && (
        <QrCodeModal
          isOpen={showQrCodeModal}
          toggle={toggleQrCodeModal}
          qrCode={qrCode}
        />
      )}
    </Fragment>
  );
}
