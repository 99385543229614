import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Wrapper, Select, Button, Text, Loading } from "../../../../components";
import { useAxios, useToggle } from "../../../../hooks";
import { SellOffProductDetailsContext } from "..";

const PreparationSalesTemplate = ({ data }: any) => {
  const { getData } = useContext(SellOffProductDetailsContext);
  const [loading, toggle] = useToggle();
  const [submitLoading, submitToggle] = useToggle();
  const [items, setItems] = useState([]);
  const [selectedPhaseId, setSelectedPhaseId] = useState("");
  const { axios } = useAxios();

  useEffect(() => {
    toggle();
    const url = "/productservice/api/sellphasetemplate?pageNumber=1&pageSize=100";
    axios.get(url)
      .then(({ data }) => {
        const items = data.items.map((item: any) => ({
          id: item.sellPhaseTemplateId,
          name: item.title,
        }));
        setItems(items);
        toggle();
      })
      .catch(() => toggle());
  }, []);

  const submitHandler = () => {
    submitToggle();
    const url = `/productservice/api/selloffproducts/${data.sellOffProductId}/sell-phase-template`;
    axios.patch(url, {
      sellPhaseTemplateId: selectedPhaseId || data.sellPhaseTemplateId,
    })
      .then(() => {
        toast.success(
          "toast.success.editSalesTemplate",
        );
        getData();
        submitToggle();
      })
      .catch(() => submitToggle());
  };

  return (
    <Wrapper className="col-span-3">
      <Wrapper.Header>
        <Text>sellOffProducts.salesTemplate</Text>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-2 space-y-4">
        {loading ? (
          <div className="flex-col space-y-4">
            <Loading.Inline />
            <Loading.Inline />
          </div>
        ) : (
          <div className="flex-col space-y-4">
            <Select
              value={selectedPhaseId || data?.sellPhaseTemplateId}
              items={items}
              setValue={(val) => setSelectedPhaseId(val as string)}
            />
            <Button onClick={submitHandler} loading={submitLoading} className="text-sm">
              <Text>button.submit</Text>
            </Button>
          </div>
        )}
      </Wrapper.Body>
    </Wrapper>
  );
};

export default PreparationSalesTemplate;
