import { useContext } from "react";
import { saleProccessContext } from ".";
import { Status, Text, Wrapper } from "../../../../components";
import { SellOffProductOldStatus } from "../../../../enums";
import { useConverters } from "../../../../hooks";
import { checkPriceAvailability } from "../../../../methods";

const SalesStatus = () => {
  const { data } = useContext(saleProccessContext);
  const { convertDate } = useConverters();

  return (
    <Wrapper>
      <Wrapper.Header className="flex justify-between items-center">
        <h2 className="text-base">
          <Text>sellOffProducts.salesStatus</Text>
        </h2>
        {!data?.isSold && (
          <h6 className="text-heading-6">
            <Status.SellOff id={SellOffProductOldStatus.NotSold} />
          </h6>
        )}
      </Wrapper.Header>
      <Wrapper.Body>
        <div className="space-y-4 text-heading-5 font-semibold">
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>sellOffProducts.soldPrice</Text>:
            </p>
            <p className="text-success text-heading-2">
              {data?.soldPrice
                ? checkPriceAvailability(data?.realSoldPrice)
                : "--"}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>sellOffProducts.soldDate</Text>:
            </p>
            <p className="text-gray-600">
              {data?.soldDateTime ? convertDate(data?.soldDateTime) : "--"}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>sellOffProducts.seller</Text>:
            </p>
            <p className="text-gray-600">{data?.sellerName || "--"}</p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>sellOffProducts.sellerCommision</Text>:
            </p>
            <p className="text-gray-600">
              {data?.currentSellerCommission || "--"}
            </p>
          </div>
          <div className="grid grid-cols-3">
            <p className="col-span-2">
              <Text>sellOffProducts.fastSaleCommission</Text>:
            </p>
            <p className="text-gray-600">
              {checkPriceAvailability(data?.fastSalesProvisionAmount) || "--"}
            </p>
          </div>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
};

export default SalesStatus;
