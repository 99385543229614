import { useContext, useState } from "react";
import { SellOffProductDetailsContext } from "..";
import { NoItems, Text } from "../../../../components";
import { useToggle } from "../../../../hooks";
import { isImage } from "../../../../methods";
import uploadIcon from "../../../../assets/icons/document upload.svg";
import MediaGallery from "./MediaGallery";
import MediaItem from "./MediaItem";
import AddMediaDrawer from "./AddMediaDrawer";

export default function Media() {
  const { data } = useContext(SellOffProductDetailsContext);
  const [showGallery, toggleGallery] = useToggle(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [showAddMediaDrawer, toggleAddMediaDrawer] = useToggle(false);
  const hasMedia = !!data?.assets?.length;
  const images = data?.assets?.filter((e) => isImage(e.type)) ?? [];
  const otherFiles = data?.assets?.filter((e) => !isImage(e.type)) ?? [];

  const handleClickMedia = (index: number) => {
    return () => {
      setActiveIndex(index);
      toggleGallery();
    };
  };
  if (!hasMedia) return <NoItems />;
  return (
    <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
      <button
        type="button"
        className="bg-primary/10 border border-dashed border-primary p-4 rounded text-center"
        onClick={toggleAddMediaDrawer}
      >
        <img
          className="block w-16 mx-auto mb-6"
          src={uploadIcon}
          alt="upload"
        />
        <h6 className="truncate block text-sm">
          <Text>button.addDocument</Text>
        </h6>
      </button>
      {[...images, ...otherFiles]?.map((e, i) => (
        <MediaItem
          key={e.thumbnailUrl}
          media={e}
          onClick={handleClickMedia(i)}
        />
      ))}
      <MediaGallery
        isOpen={showGallery}
        toggle={toggleGallery}
        activeIndex={activeIndex ?? 0}
        setActiveIndex={setActiveIndex}
      />
      <AddMediaDrawer
        isOpen={showAddMediaDrawer}
        toggle={toggleAddMediaDrawer}
        loading={false}
        onSubmit={() => { }}
      />
    </section>
  );
}
