export enum PulsFileType {
  PimProduct = 0,
  Customer = 1,
  OfferDocs = 2,
  SellOfProduct = 3,
  Company = 4,
  User = 5,
  Applications = 6,
}
export enum IncidentStatus {
  Active,
  Resolved,
  Cancel,
}
export enum IncidentStatusReason {
  NotDefined = 0,
  InProgress = 1,
  OnHold = 2,
  WaitingForDetails = 3,
  Researching = 4,
  ProblemSolved = 5,
  InformationProvided = 1000,
  Cancelled = 6,
  Merged = 2000,
}
export enum CustomerType {
  Person = 0,
  Company = 1,
}
export enum CompanyApprovalStatus {
  PendingApproval = 1,
  Approved = 2,
  Suspended = 3,
}
export enum GenderType {
  NotDefined = 0,
  Male = 1,
  Female = 2,
  Diverse = 3,
}
export enum CustomerStatus {
  Onboarding,
  Registered,
}
export enum OfferStatus {
  None,
  Active,
  Accepted,
  Rejected,
}
export enum OrderStatus {
  None,
  Active,
  Submitted,
  PendingPayment,
  Paid,
  Cancel,
}
export enum ProgramType {
  None,
  Manual,
  Configurable,
}
export enum ProductType {
  Pim = 1,
  ProgramManual = 2,
  ProgramConfigurable = 3,
  Iwofurn = 4,
}
export enum LineItemType {
  PimProduct = 0,
  IwofurnProduct = 1,
  ManualProduct = 2,
  ProductService = 3,
  ManualBundle = 4,
  PimBundle = 5,
  PimBundleItem = 6,
  SellOffProduct = 7,
  SellOffBundle = 8,
  SellOffBundleItem = 9,
}
export enum SalesDocumentType {
  Main = 0,
  LineItem = 1,
}
export enum DocumentTemplateType {
  Offer = 0,
  Order = 1,
  CustomerLegal = 2,
  TermsAndConditions = 3,
}
export enum SalesStatus {
  None,
  Backorder,
  Delivered,
  Invoiced,
  Canceled,
}
export enum PurchaseStatus {
  None,
  Backorder,
  Received,
  Invoiced,
  Canceled,
}
export enum DocumentStatus {
  None = 0,
  PurchaseOrder = 2,
  Confirmation = 3,
  PickingList = 4,
  PickingSlip = 5,
  Invoiced = 7,
}
export enum AddressType {
  Shipping = 0,
  Billing = 1,
}
export enum AssignedType {
  Employee,
  Department,
}
export enum IncidentPriority {
  NotDefined,
  High,
  Normal,
  Low,
}
export enum CRMActivityType {
  NotDefined = 0,
  Email = 1,
  PhoneCall = 2,
  Appointment = 3,
  Task = 4,
  Post = 5,
  Note = 6,
  Incidentresolution = 7,
}

export enum CRMTargetType {
  Incident = 0,
  Lead = 1,
  Account = 2,
  Contact = 3,
  Order = 4,
}

export enum CRMTaskPriority {
  Low = 0,
  Normal = 1,
  High = 2,
}

export enum CRMActivityStatus {
  Open = 0,
  Completed = 1,
  Canceled = 2,
}

export enum CRMTaskStatusReason {
  //Open
  NotStarted = 2,
  InProgress = 3,
  WaitingOnSomeoneElse = 4,
  Deferred = 7,
  //Completed
  Completed = 5,
  //Canceled
  Cancelled = 6,
}
export enum CountryCode {
  De = 49,
}
export enum CalculationOperationType {
  Increment = 0,
  Decrement = 1,
}
export enum CalculationValueType {
  Percent = 0,
  Amount = 1,
  Multiply = 2,
  PureProfit = 3,
}
export enum SupplierType {
  TradingGoods = 0,
  ConsumableGoods = 1,
}
export enum SalePriceTemplateItemStep {
  System1 = 1,
  System2 = 2,
  AutomaticDiscounts = 3,
  CatalogCalculation = 4,
  OptionalDiscounts = 5,
  IndividualDiscounts = 6,
}
export enum OrderInternalStatus {
  Active,
  Blocked,
}
export enum AvailabilityStatus {
  Active = 0,
  Inactive = 1,
  SalesOnly = 2,
  Blocked = 3,
}
export enum SupplierContactPersonDepartment {
  General = 0,
  Sales = 1,
  Logistics = 2,
  Marketing = 3,
  Support = 4,
  Other = 10,
}
export enum SupplierShippingDurationUnit {
  None = 0,
  WorkDay = 1,
  CalendarDay = 2,
}
export enum PimProductType {
  Standard = 0,
  Bundle = 1,
}
export enum SellOffProductOldStatus {
  Preparation,
  Active,
  Sold,
  NeedPhaseChange,
  NeedClose,
  Closed,
  NotSold,
}
export enum PrintRequestStatus {
  OnProcess = 0,
  Completed = 1,
  Failed = 2,
}
export enum PurchasePriceType {
  Gross = 0,
  Net = 1,
  NNet = 2,
}
export enum PickupStatus {
  None = 0,
  PickupRequested = 1,
  PickupRequestCanceled = 2,
  PickedUp = 3,
}
export enum AmountCalculationType {
  Percent,
  Amount,
}
export enum SellOffProductStatus {
  Preparation = 0,
  Active = 1,
  Closed = 2,
  Sold = 3,
  None = 99,
}
