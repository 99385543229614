import { Fragment } from "react";
import { useConverters } from "../../../hooks";
import { SellOffProductOld } from "../../../types";
type Props = {
  item: SellOffProductOld.Item | SellOffProductOld.Details;
  className?: string;
  inDetails?: boolean;
};
const PriceTemplate = ({ item, className, inDetails = false }: Props) => {
  const { convertAmount } = useConverters();
  const hasDiscount = !!item?.currentDiscount;
  const hasCurrentPrice = !!item?.currentPrice;
  return (
    <div className={`${className} text-left`}>
      {hasDiscount && (
        <Fragment>
          <div className="text-gray-500 font-semibold text-heading-4 line-through">
            {convertAmount(item?.originalPrice)}
          </div>
          <p className="text-danger">{item?.currentDiscount}%</p>
        </Fragment>
      )}
      {hasCurrentPrice && (
        <div>
          {inDetails ? (
            <h2 className={"text-primary-active font-semibold text-heading-2"}>
              {convertAmount(item?.currentPrice)}
            </h2>
          ) : (
            <h6 className={"text-primary-active font-semibold text-heading-2"}>
              {convertAmount(item?.currentPrice)}
            </h6>
          )}
        </div>
      )}
    </div>
  );
};

export default PriceTemplate;
